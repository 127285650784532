import React, { useEffect, useState, useCallback, useReducer } from "react";
import { Helmet } from 'react-helmet';
import { platformService } from '../shared/services/platform.service';
import { ADMINISTRATORS, CONTACT_ADMIN, DEFAULT_API_STATUS, DONE, ERROR, LICENSE_FEATURE, LOADING, SAVE, SETTINGS, SOMETHING_WENT_WRONG, UNAUTHORIZED_ACCESS } from "../shared/utils/constants";
import { getCachedFeature } from "../shared/cache/FeatureCache";
import { UpgradeNeeded } from "../shared/components/UpgradeNeeded";
import { Communication } from '@fluentui/react-teams';
import { Toolbar } from '../shared/components/Toolbar';
import { toTitleCase } from "../shared/utils/helper";
import { Breadcrumbs } from "../shared/components/BreadcrumbNavigation";
import { checkInTeams } from "../App";
import { getBodyHeight } from '../shared/common/CommonHelper';
import { LoadingScreen } from "../shared/components/LoadingScreen";
import { CheckLicense } from "../shared/components/License/CheckLicense";
import { useTranslation } from "react-i18next";
import { appState  } from '../AppState';
import {  Checkbox, Flex, Header, ProviderConsumer } from "@fluentui/react-northstar";
import { ResolutionNoteState } from "../shared/components/TicketResolutionNoteComponents/ResolutionHelper";
import './config.css'

export const TikitConfiguration = () => {
  const { t } = useTranslation();
  const api = new platformService();

  const [apiStatus, setApiStatus] = useState<APIStatus>(DEFAULT_API_STATUS as APIStatus);
  const [isResolutionNoteEnabled, setIsResolutionNoteEnabled] = useState(true);
  const [isSurveyEnabled, setIsSurveyEnabled] = useState(true);
  

 

  const editPageReducer = (state, action) => {
    return {
      ...state,
      ...action.data
    };
  }

  const [state, dispatch] = useReducer(editPageReducer, ResolutionNoteState);


  const appContextState = appState();

  useEffect(() => { 
    fetchSystemSetting(); 
  }, []);

  const fetchSystemSetting = useCallback(async () => {
    setApiStatus({ ...apiStatus, msg: t('resolution-note.fetching-system-setting') });
    try {
      const [resolutionNote,survey,resolutonNoteFeature,surveyFeature] = await Promise.all([
        api.getSystemSetting('ServiceDesk.Core.Configuration.ResolutionNoteConfiguration'),
        api.getSystemSetting('ServiceDesk.Core.Configuration.SurveyConfiguration'),
        getCachedFeature(LICENSE_FEATURE.ResolutionNote),
        getCachedFeature(LICENSE_FEATURE.Surveys)
      ]);

      const { data } = resolutionNote;
      setIsResolutionNoteEnabled(resolutonNoteFeature);
      setIsSurveyEnabled(surveyFeature);
      
      dispatch({ data: {
          IsEnabled: data["IsEnabled"],
          RequireCategory: data["RequireCategory"],
          RequireNote: data["RequireNote"],
          RequireInternalNote: data["RequireInternalNote"],
          ShowDraftKB: data["ShowDraftKB"],
          SurveysEnabled: survey.data["IsEnabled"]
        } });

    } catch (err: any) {
      setApiStatus({ ...apiStatus, status: ERROR, errCode: err.response.status });
    } finally {
      const isAdmin = appContextState.userRoles.roles.includes(ADMINISTRATORS);
      (!isAdmin) ? setApiStatus({ ...apiStatus, status: ERROR, errCode: 401 }) : setApiStatus({ ...apiStatus, status: DONE });
    }
  }, []);

  const change = (e,x, propertyType) => {
     state[propertyType]=x.checked;
     dispatch({ data: state  });
  }

  const saveData = async () => {
    setApiStatus({ status: LOADING, msg: t('resolution-note.saving-settings'), errCode: 0 });
    try {
       await api.setSystemSetting('ServiceDesk.Core.Configuration.ResolutionNoteConfiguration', {
        RequireCategory: state.RequireCategory,
        RequireNote: state.RequireNote,
        RequireInternalNote: state.RequireInternalNote,
        ShowDraftKB: state.ShowDraftKB,
        IsEnabled: state.IsEnabled
      });

       await api.setSystemSetting('ServiceDesk.Core.Configuration.SurveyConfiguration', {IsEnabled: state.SurveysEnabled});

       setApiStatus({ ...apiStatus, status: DONE });
    } catch (err: any) {
      console.log(err);
      setApiStatus({ ...apiStatus, status: ERROR, errCode: err.response.status });
    }
  };

  const navs: Breadcrumbs = {
    breadcrumbs: [
      {
        title: toTitleCase(SETTINGS),
        link: '/settings'
      },
      {
        title: toTitleCase(t('resolution-note.ticket-config')),
        link: ''
      }
    ]
  };

  const errConfig = {
    fields: { title: apiStatus.errCode === 401 ? UNAUTHORIZED_ACCESS : SOMETHING_WENT_WRONG, desc: CONTACT_ADMIN }
  };
  return (
    <>
      <Helmet>
        <title>{t('resolution-note.resolution-note-tikit')}</title>
      </Helmet>
      {apiStatus.status === LOADING ? (<LoadingScreen message={apiStatus.msg} />) :
       apiStatus.status === ERROR ? (<Communication {...errConfig} />) :
        <>
            <ProviderConsumer render={globalTheme => (
              <>
                <Toolbar globalTheme={globalTheme} title={t('resolution-note.bread-crumb')} breadcrumbs={navs} saveText={SAVE} saveCommand={saveData} />
                <Flex style={{ marginTop: '-1.25rem', height: getBodyHeight(checkInTeams()) }}>
                  <Flex column style={{ padding: '32px', maxWidth: '1400px' }}>
                  <Header as="h2" className="pb-2 defaultStyle" content={t('resolution-note.resolution-note-configuration')} />
                  {(!isResolutionNoteEnabled) && (<UpgradeNeeded breadcrumbs={navs}
                    headerText={t('resolution-note.you-do-not-have-access')} subheaderText={t('resolution-note.please-upgrade-your-plan')} />)}
                    
                      <CheckLicense disable>
                        {isResolutionNoteEnabled && (
                          <ul>
                            <li>
                              <div><Checkbox className="toggle-label" style={{marginBottom:'7px'}} label={t('resolution-note.require-resolution')} toggle onChange={(e,x)=>{change(e,x, "IsEnabled")}} checked={state.IsEnabled} /></div>
                              <div style={{marginLeft:'52px'}}>
                                <ul>
                                  <li>
                                    <Checkbox label={<div className="toggle-label">{t('resolution-note.resolution-category')}</div>} toggle onChange={(e,x)=>{change(e,x, "RequireCategory")}} disabled={!state.IsEnabled}  checked={state.RequireCategory} />
                                      <div className="resolution-note-copy">{t('resolution-note.resolution-category-copy')}</div>
                                  </li>
                                  <li>
                                    <Checkbox label={<div className="toggle-label">{t('resolution-note.resolution-note')}</div>} toggle onChange={(e,x)=>{change(e,x, "RequireNote")}} disabled={!state.IsEnabled} checked={state.RequireNote} />
                                      <div className="resolution-note-copy">{t('resolution-note.resolution-note-copy')}</div>    
                                  </li>
                                      
                                  <li>
                                    <Checkbox label={<div className="toggle-label">{t('resolution-note.resolution-note-internal')}</div>} toggle onChange={(e,x)=>{change(e,x, "RequireInternalNote")}} disabled={!state.IsEnabled} checked={state.RequireInternalNote} />
                                    <div className="resolution-note-copy">{t('resolution-note.resolution-note-internal-copy')}</div>
                                  </li>

                                  <li>
                                    <Checkbox label={<div className="toggle-label">{t('resolution-note.draft-kb')}</div>} toggle onChange={(e,x)=>{change(e,x, "ShowDraftKB")}} disabled={!state.IsEnabled} checked={state.ShowDraftKB} />
                                      <div className="resolution-note-copy">{t('resolution-note.draft-kb-copy')}</div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        )}
                      
                      
                        
                        
                      </CheckLicense>

                      <br />
                      <Header as="h2" className="pb-2 defaultStyle" content={t('surveys.survey-configuration')} />
                      {(!isSurveyEnabled) && (<UpgradeNeeded breadcrumbs={navs}
                        headerText={t('surveys.you-do-not-have-access')} subheaderText={t('surveys.please-upgrade-your-plan')} />)}
                      <CheckLicense disable>
                        {isSurveyEnabled && (
                            <div><Checkbox className="toggle-label" label={t('surveys.enable-surveys')} toggle onChange={(e,x)=>{change(e,x, "SurveysEnabled")}} checked={state.SurveysEnabled} /></div>
                        )}
                      </CheckLicense>
                      
                  </Flex>
                </Flex>/
              </>
            )} />
          
        </>
      }
    </>
  );
}


