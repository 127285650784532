import React, { useContext, useEffect, useState } from 'react';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { Ticket } from '../../interfaces/ticket.interface';
import {
  getCategory,
  getGroup,
  getPriority,
  getStatus,
  getTicketType,
  getUserData,
  getTeam,
  getResolutionCategories
} from '../../../tikit/ticketHelper';
import { appState, AppStateValue } from '../../../AppState';
import { Skeleton } from '@fluentui/react-northstar';
import FlashlightSVG from './../../../../svg/flashlight.svg';
import EmptySVG from './../../../../svg/empty.svg';
import { EmptyData } from '../EmptyData';
import ClassDetailList from './ClassDetailList';
import { NON_SORTABLE_COLUMNS } from '../../utils/constants';
import { showParentChild } from '../TicketDetailInfo/TicketDetailInfo';
import { ListContext } from '../../../tikit/List/ListContextProvider';
import { CustomViewContext, EnumSortOrderType } from '../../../tikit/toolbar/CustomViewContextProvider';
import { DEFAULT_SORT_ORDER_FOR_QUERYING } from '../../../tikit/List/ListFunctions';
import { getConfiguredColumnsKeyOrderFromStorage, getSortOrderFromStorage, setSortOrderInStorage } from '../../../tikit/toolbar/CustomViewPopup/heplers/customPopupHelper';
import { TicketProps } from '../../interfaces/ticketproperties.interface';
import { ListFeatureStates } from '../../../tikit/List/List';
import { useTranslation } from 'react-i18next';
export interface TicketProp extends Ticket {
  key: string;
  AssigneeName: string;
  RequesterName: string;
  TeamName: string;
  GroupName: string;
  Status: string;
  Category: string;
  Priority: string;
  TicketType: string;
  TicketCollaboratorCount: PlatormUserEntity[];
  AffectedUsersCount: any[];
  TicketLifecycleName: string;
  TicketLifecyclePhaseName: string;
  ResolutionCategory: string;
}

interface ListProps {
  appStateValue: AppStateValue;
  filterDropdownCount: number;
  onTicketsSelected: (tickets: Ticket[]) => void;
  isFilterApplied: boolean;
  listFeatureStates: ListFeatureStates;
}

type ScopeState = {
  tickets: TicketProp[];
  columns: IColumn[];
};

const defaultState = {
  items: [],
  isModalSelection: false,
  isCompactMode: false,
  useTicketType: false,
  selectedOrderedColumns: [] as columnsKeyOrderType,
  useTeam: false,
  useTicketLifecycle: false,
  useSLA: false
};

const defaultFilter = {
  listFilter: 0,
  filterDropdownCount: 0,
};

export const SkeletonCell = (props: any) => {
  return (
    <Skeleton animation='wave'>
      <Skeleton.Line
        height='20px'
        width={props.width || '200px'}
      ></Skeleton.Line>
    </Skeleton>
  );
};

export const TicketListBoard = (props: ListProps) => {
  const { appStateValue, onTicketsSelected, listFeatureStates } = props;

  const { listStates, setListStates } = useContext(ListContext);
  const { customViewStates, setCustomViewStates } = useContext(CustomViewContext);
  const currentstate = appState();

  const scope: ScopeState = { tickets: [], columns: [] };
  const [ticketState, setTicketState] = useState(Object.assign(defaultState, listFeatureStates));
  const [isLoaded, setIsLoaded] = useState(false);
  const [filterState, setFilterState] = useState(defaultFilter);
  const { t } = useTranslation();

  const setupTicketState = async (isTemp: boolean = false) => {
    let mapTickets = (listStates.tickets as Ticket[]).map((item, i): TicketProp => {

      const getRequesterName = () => {
        return item['Requester']
          ? item['Requester']['FullName']
          : getUserData(item.RequesterId, currentstate)?.FullName
      }
      const getTeamName = () => {
        return item['Team']
          ? item['Team']['FriendlyName']
          : getTeam(item.TeamId, currentstate)?.FriendlyName;
      }
      const getSupportGroupName = () => {
        return item['SupportGroup']
          ? item['SupportGroup']['Name']
          : getGroup(item.SupportGroupId, currentstate)?.Name;
      }

      return {
        ...item,
        key: `column${i + 1}`,
        AssigneeName:
          item.AssigneeId == null
            ? t('ticket.ticket-board.ticket-card.unassigned')
            : item['Assignee']
            ? item['Assignee']['FullName']
            : getUserData(item.AssigneeId, currentstate)?.FullName,
        RequesterName:
          item.RequesterId == null ? t('ticket.ticket-board.ticket-card.unassigned') : 
          getRequesterName(),
        TeamName: item['TeamId'] == null ? '' : getTeamName(),
        GroupName: item['SupportGroupId'] == null ? '' : getSupportGroupName(),
        Status:
          item.StatusId == null
            ? t('ticket.ticket-list.empty.status')
            : showParentChild(
                getStatus(item.StatusId, currentstate.ticketStatus),
                currentstate.ticketStatus
              ).join(' > '),
        Category:
          item.CategoryId == null
            ? t('ticket.ticket-list.empty.category')
            : showParentChild(
                getCategory(item.CategoryId, currentstate.categories),
                currentstate.categories
              ).join(' > '),
        ResolutionCategory:
          item?.Resolution?.ResolutionCategoryId == null
            ? ""
            : showParentChild(
              getResolutionCategories(item?.Resolution?.ResolutionCategoryId, currentstate.resolutionCategory),
                currentstate.resolutionCategory
              ).join(' > '),
        Priority:
          item.PriorityId == null
            ? t('ticket.ticket-list.empty.priority')
            : showParentChild(
                getPriority(item.PriorityId, currentstate.priority),
                currentstate.priority
              ).join(' > '),
        TicketType:
          item.TicketTypeId == null
            ? t('ticket.ticket-list.empty.type')
            : showParentChild(
                getTicketType(item.TicketTypeId, currentstate.ticketTypes),
                currentstate.ticketTypes
              ).join(' > '),
        TicketCollaboratorCount: item.TicketCollaborators,
        AffectedUsersCount: item.AffectedUsers,
        TicketLifecycleName:
          item['TicketLifecycle'] && item['TicketLifecycle']['Lifecycle']
            ? item['TicketLifecycle']['Lifecycle']["Title"]
            : 'No Lifecycle',
        TicketLifecyclePhaseName:
          item['TicketLifecycle'] && item['TicketLifecycle']['Phases'][0]
            ? item['TicketLifecycle']['Phases'][0].Name
            : ''
      };
    });

    scope.tickets = mapTickets;
    let newTickets = scope.tickets;
    if (isTemp) {
      newTickets = newTickets.filter((x) => x.Id >= 1 || x.Title !== 'temp');
      newTickets.unshift({ Title: 'temp_ticket' } as TicketProp);
    }

    setTicketState(prev => ({
      ...prev,
      items: newTickets,
      isModalSelection: false,
      isCompactMode: false
    }));
  };

  const clickColumnCallback = (column: IColumn) => {
    if(NON_SORTABLE_COLUMNS.some(x => Number(x) == Number(column.key)))
      return;
    const prevSortOrder = customViewStates.configureColumnsApplied.sortOrder;
    const sortOrder = {
      orderBy: Number(column.key) as ISortOrderBy,
      orderType: column.isSortedDescending ? EnumSortOrderType.asc : EnumSortOrderType.desc
    } as ISortOrder;

    
    setCustomViewStates(prev => ({
      ...prev,
      configureColumnsApplied: {
        prevSortOrder,
        sortOrder: sortOrder
      }
    }));
    setSortOrderInStorage(sortOrder);
  }

  useEffect(() => {
    if (
      isLoaded &&
      (appStateValue.listFilter !== filterState.listFilter ||
        appStateValue.filterDropdownCount !== filterState.filterDropdownCount)
    ) {
      setFilterState({
        listFilter: appStateValue.listFilter,
        filterDropdownCount: appStateValue.filterDropdownCount,
      });
      setupTicketState();
    }

    if ((listStates.tickets as Ticket[]).length > 0) {
      if (!isLoaded) {
        setIsLoaded(true);
      }
      if (listStates.tickets.some((t, i) => t.Title === 'temp')) {
        setupTicketState(true);
      } else {
        setupTicketState();
      }
    }

    if (customViewStates.selectedCustomView) {
      const { ColumnsSelectedValues } = customViewStates.selectedCustomView;
      if (ColumnsSelectedValues?.columnsKeyOrder) {
        setTicketState(prev => ({
          ...prev,
          selectedOrderedColumns: ColumnsSelectedValues.columnsKeyOrder,
        }));
      }
    }

    if (!customViewStates.selectedCustomView) {
      const orderedColumns = getConfiguredColumnsKeyOrderFromStorage() || customViewStates.configurableColumnsKeyOrder;
      setTicketState(prev => ({
        ...prev,
        selectedOrderedColumns: orderedColumns,
      }));
    }

  }, [props]);

  const sortOrderApplied =
    getSortOrderFromStorage() ||
    customViewStates.configureColumnsApplied.sortOrder ||
    DEFAULT_SORT_ORDER_FOR_QUERYING;
  return (
    <>
      {listStates.tickets.length > 0 ? (
        <ClassDetailList
          tickets={ticketState.items}
          onTicketsSelected={onTicketsSelected}
          currentState={currentstate}
          useTicketType={ticketState.useTicketType}
          useTeam={ticketState.useTeam}
          useTicketLifecycle={ticketState.useTicketLifecycle}
          useSLA={ticketState.useSLA}
          setListStates={setListStates}
          selectedOrderedColumns={[...ticketState.selectedOrderedColumns]}
          sortOrderApplied={sortOrderApplied}
          clickColumnCallback={clickColumnCallback}
        />
      ) : (
        <>
          {currentstate.filtersApplyCount > 0 ? (
            <EmptyData
              SVGIcon={<FlashlightSVG width={200} height={200} className={'pb-2'} /> }
              headerText="There aren't any matches."
              subheaderText={'Try changing or removing filters.'}
            />
          ) : (
            <EmptyData
              headerText="There are no Tickets, let's create one."
              subheaderText={'Get started by clicking the Add Ticket button in the toolbar above.'}
              SVGIcon={<EmptySVG height={200} width={200} />}
            />
          )}
        </>
      )}
    </>
  );
};

export const getSupportGroupName = (item: Ticket, currentstate: AppStateValue) => {
  return item['SupportGroup']
    ? item['SupportGroup']['Name']
    : getGroup(item.SupportGroupId, currentstate)?.Name;
}

export const getTeamName = (item: Ticket, currentstate: AppStateValue) => {
  return item['Team']
    ? item['Team']['FriendlyName']
    : getTeam(item.TeamId, currentstate)?.FriendlyName;
}

export const getRequesterDetail = (item: Ticket, currentstate: AppStateValue) => {
  return item['Requester']
    ? item['Requester']
    : getUserData(item.RequesterId, currentstate);
};
export const getAssigneeName = (item: Ticket, currentstate: AppStateValue) => {
  return item['Assignee']
    ? item['Assignee']['FullName']
    : getUserData(item.AssigneeId, currentstate)?.FullName;
};

export const hierarchialStatusName = ({
  statusId,
  ticketStatuses,
}: {
  statusId?: number;
  ticketStatuses: TicketProps[];
}) => {
  return statusId == null
    ? 'No Status'
    : showParentChild(getStatus(statusId, ticketStatuses), ticketStatuses).join(
        ' > '
      );
};

export const hierarchialCategoryName = ({
  categoryId,
  categories,
}: {
  categoryId?: number;
  categories: TicketProps[];
}) => {
  return categoryId == null
    ? 'No Category'
    : showParentChild(getCategory(categoryId, categories), categories).join(
        ' > '
      );
};

export const hierarchialPriorityName = ({
  priorityId,
  priorities,
}: {
  priorityId?: number;
  priorities: TicketProps[];
}) => {
  return priorityId == null
    ? 'No Priority'
    : showParentChild(getPriority(priorityId, priorities), priorities).join(
        ' > '
      );
};

export const hierarchialTicketType = ({
  ticketTypeId,
  ticketTypes,
}: {
  ticketTypeId?: number;
  ticketTypes: TicketProps[];
}) => {
  return ticketTypeId == null
    ? 'No Type'
    : showParentChild(
        getTicketType(ticketTypeId, ticketTypes),
        ticketTypes
      ).join(' > ');
};
