import React, { useEffect, useRef, useState } from 'react';
import { Alert, Loader, ThemePrepared, ProviderConsumer as FluentUIThemeConsumer, Flex } from '@fluentui/react-northstar';
import { Action, SubmitAction } from 'adaptivecards';
import { platformService } from '../services/platform.service';
import { Ticket } from '../interfaces/ticket.interface';
import { mergeStyleSets } from '@fluentui/react';
import { AdaptiveCardElement } from '../../templates/AdaptiveCardElement';
import { toast} from 'react-toastify';
import { toastDefault } from '../utils/constants';
import { themeNames } from '@fluentui/react-teams';
import { useTranslation } from 'react-i18next';
interface TicketTemplateAnswerProps {
  globalTheme: ThemePrepared<any>;
  ticketId: number;
  parentData?: Ticket;
  isSavingState?: boolean;
  width?: number;
  isDisabled: boolean;
  isEndUserForm?: boolean;
}

const classNames = mergeStyleSets({
  customForm: {
    background: 'var(--mgt-theme-background) !important',
    '.ac-textBlock': {
      padding: '5px 0 !important',
      color: 'var(--mgt-theme-foreground) !important'
    },
    '.ac-richTextBlock > span': {
      color: 'var(--mgt-theme-foreground) !important'
    },
    '.ac-pushButton': {
      background: 'var(--mgt-theme-brand-background) !important'
    }
  },
  disabledRoot: {
    '.ac-pushButton': {
      pointerEvents: 'none !important',
      cursor: 'default !important'
    },
    '.ac-input': {
      pointerEvents: 'none !important',
      cursor: 'default !important'
    },
    '.ac-richTextBlock': {
      pointerEvents: 'none !important',
      cursor: 'default !important'
    }
  },
  disabledDefault: {
    '.ac-pushButton,.ac-input': {
      background: 'rgb(240, 240, 240) !important',
    }
  },
  disabledDark: {
    '.ac-pushButton': {
      background: '#1f1f1f !important',
    },
    '.ac-input.ac-textInput, .ac-input.ac-multichoiceInput.ac-choiceSetInput-compact': {
      background: 'rgb(35, 35, 35) !important',
      color: 'rgb(198, 198, 198) !important'
    }
  }
});

export const TicketTemplateAnswer = (props: TicketTemplateAnswerProps) => {
  const {t} = useTranslation();
  let { ticketId, parentData, isSavingState, width, isDisabled, isEndUserForm } = props;
  const api = new platformService();
  const [isLoading, setIsLoading] = useState(false);
  const defaultAlert = { message: '', success: true };
  const [alert, setAlert] = useState(defaultAlert);
  const [message, setMessage] = useState(t('ticket-details.edit-ticket-form.custom-form-answer.loading-custom-form-answer'));
  const [finalCardPayload, setFinalCardPayload] = useState({});
  let container = useRef<HTMLDivElement>(null);
  const actionSet = {
    type: 'ActionSet',
    actions: [
      {
        type: 'Action.Submit',
        title: t('ticket-details.edit-ticket-form.custom-form-answer.button.update-answer'),
        data: {
          commandId: 'CreateTicketFromTemplate'
        }
      }
    ]
  };

  const loadData = async (data?: any) => {
    let ticket: Ticket = {};
    if (!data) {
      const result = await api.getTicket(props.ticketId);
      ticket = result.data;
      // ticket = dummyResponse;
    } else {
      ticket = data;
    }

    const cardPayload = JSON.parse(ticket.CardTemplateJson);
    const cardAnswer = ticket?.CardAnswerJson ?? "{}";
    parseCard(cardPayload.body, JSON.parse((cardAnswer.length > 0 ? cardAnswer : "{}")));
    if (!isDisabled)
      cardPayload.body.push(actionSet);
    
    setFinalCardPayload(cardPayload);

    setTimeout(() => setIsLoading(false), !data ? 0 : 500);
  };

  useEffect(() => {
    if (isSavingState) {
      setIsLoading(true);
      setAlert(defaultAlert);
      loadData(parentData);
    }
  }, [parentData.CardTemplateJson, parentData.CardAnswerJson]);

  useEffect(() => {
    setIsLoading(true);
    setAlert(defaultAlert);
    loadData(parentData);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (isDisabled) disableFields(true);
      else disableFields(false);
    }
  }, [isLoading, isSavingState]);

  const disableFields = (disabled: boolean) => {
    if (document.querySelector('.custom-form-answer')) {
      let parentDiv = document.querySelector('.custom-form-answer');
      const fieldSelectors = ['textarea', 'input', 'button', 'select'];
      fieldSelectors.forEach(selector => disableField(parentDiv, selector, disabled));
    }
  }

  const disableField = (parentDiv: Element, selector: string, disabled: boolean) => {
    if (parentDiv.querySelector(selector)) {
      let isTextarea = selector=="textarea";
      let disableType = isTextarea ? 'readonly' : 'disabled';
      let fields = parentDiv.querySelectorAll(selector);

      if (disabled) {
        fields.forEach(o => {
          o.setAttribute(disableType, 'true');
          if (isTextarea){
            o.setAttribute('style',`${o.getAttribute("style")} pointer-events: revert !important`);
          }
        });
      }
      else fields.forEach(o => o.getAttribute(disableType) && o.removeAttribute(disableType));
    }
  }

  const onExecuteAction = (action: Action) => {
    const submit = action as SubmitAction;
    if (submit && submit.data['commandId'] === 'CreateTicketFromTemplate') {
      delete submit.data['commandId'];
      (async () => {
        const updatedData = { CardAnswerJson: JSON.stringify(submit.data) };
        try {
          setMessage(t('ticket-details.edit-ticket-form.custom-form-answer.saving-data-message'));
          setIsLoading(true);
          await api.updateTicket(props.ticketId, updatedData);
          parentData = Object.assign(parentData, updatedData);
          toast.success(t('ticket-details.edit-ticket-form.custom-form-answer.success-response'), toastDefault);
        } catch (e) {
          toast.error(t('ticket-details.edit-ticket-form.custom-form-answer.update-error'), toastDefault);
        }
        loadData();
      })();
    }
  };

  return (
    <FluentUIThemeConsumer render={globalTheme => {
      return (
        <>
          {isLoading && (
            <Flex className={`pt-5 mt-5`} styles={{ justifyContent: 'center' }}>
              <Loader label={message} />
            </Flex>
          )}
          {!isLoading && (
            <>
              <Flex column className={`mt-5 custom-form-answer`}>
                <AdaptiveCardElement
                  jsonString={JSON.stringify(finalCardPayload)}
                  onExecuteAction={onExecuteAction}
                  cardProps={{ styles: { height: 'fit-content', width: !width ? '40%' : `${width}%` } }}
                  containerClassName={`${classNames.customForm} 
                  ${!isDisabled && classNames.customForm}
                  ${isDisabled && classNames.disabledRoot}
                  ${isDisabled && globalTheme.siteVariables.theme === themeNames.Dark && classNames.disabledDark}
                  ${isDisabled && globalTheme.siteVariables.theme === themeNames.Default && classNames.disabledDefault}
                  `}
                />
                <div>
                  {alert.message !== '' && alert.success && (<Alert content={alert.message} success dismissible />)}
                  {alert.message !== '' && !alert.success && (<Alert content={alert.message} danger dismissible />)}
                </div>
              </Flex>
            </>
          )}
        </>
      );
    }}
    />
  );
};

export const parseCard = (container: any[], data: any) => {
  container.forEach(item => {
    if (item.type === 'Container' || item.type === 'Column') {
      parseCard(item.items, data);
    } else if (item.type === 'ColumnSet') {
      if (item.columns)
        parseCard(item.columns, data);
    } else if (item.type === 'Input.Toggle') {
      item.value = (data[item.id] || item.value || '').toLowerCase();
    } else if (item.type === 'Input.Number') {
      if (typeof data[item.id] === 'number' && isFinite(data[item.id]))
        item.value = data[item.id];
      else if((data[item.id] || "").length > 0)
        item.value = parseInt(data[item.id], 10);
      else if((item?.value ?? "").length > 0)
        item.value = parseInt(item.value, 10);
      else
        item.value = "";
    } else if (item.type === 'Input.Date' && (data[item.id] || '').indexOf('T') > -1) {
      if((data[item.id] || "").length > 0)
        item.value = data[item.id].split('T')[0];
      else if((item?.value ?? "").length > 0)
        item.value = item.value.split('T')[0];
      else
        item.value = "";
    } else if (item.type.startsWith('Input.')) {
      item.value = data[item.id] || item.value || '';
    }
    if (item.value == `$\{${item.id}\}`) item.value = "";

    if(item.id)
      data[item.id] = item.value;
  });
};