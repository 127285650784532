import React, { useEffect } from 'react';
import Styles from '../styles/Lifecycle.module.css';
import { FormGroup } from '../LifecycleForm';
import { LIFECYCLE } from '../../shared/utils/constants';
import { TextInputField } from '../../shared/common/TextInputField';
import { LifecycleData } from '../utils/typings';
import { Flex, ProviderConsumer, TextArea } from '@fluentui/react-northstar';
import { LabelElement } from '../../shared/components/TicketForm';
import { FormCheckbox } from '@fluentui/react-northstar';
import { NestedDropdown } from '../../shared/components/NestedDropdown/NestedDropdown';
import { appState, } from '../../AppState';
import SlateEditor, { markdownToSlate, slateToMd } from '../../shared/components/Wysiwyg/SlateEditor';
import { TikitConfiguration } from '../../shared/components/TicketResolutionNoteComponents/ResolutionHelper';
import '../../../components/shared/components/TicketResolutionNoteComponents/style.css';
import { themeNames } from '@fluentui/react-teams';

interface Props {
  infoData: LifecycleData;
  updateInfoData: (infoData: LifecycleData) => void;
  resolutionNoteConfig: TikitConfiguration;
  isResolutionNoteFeatureAvailable: boolean;
}

export const Information = ({
  infoData,
  infoData: { Title, Description, SetStatusResolved, ResolutionNote, ResolutionNoteInternal, ResolutionCategory },
  updateInfoData,
  resolutionNoteConfig,
  isResolutionNoteFeatureAvailable
}: Props) => {
  
  const state = appState();

  return <ProviderConsumer 
    render={globalTheme => ( 
      <>
        <Flex space={'between'} vAlign={'center'}>
          <Flex.Item>
            <h2 className={Styles.SubHeading2}>{LIFECYCLE.INFORMATION}</h2>
          </Flex.Item>
        </Flex>
        <FormGroup marginClass='mb-3.5'>
          <TextInputField
            label= {LIFECYCLE.TITLE}
            value={Title}
            isRequired
            updateValue={(value: string) => {
              updateInfoData({
                ...infoData,
                Title: value
              });
            }}
          />
        </FormGroup>
        <FormGroup marginClass='mb-1'>
          <LabelElement label={LIFECYCLE.DESCRIPTION} required={false}>
            <TextArea
              styles={{ height: '60px', backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
              fluid
              placeholder={LIFECYCLE.ENTER_DESCRIPTION}
              maxLength={500}
              required
              value={Description}
              onChange={(_event, p) => {
                updateInfoData({
                  ...infoData,
                  Description: p.value
                });
              }}
            />
          </LabelElement>
        </FormGroup>
        <FormGroup marginClass='mb-5'>
          <FormCheckbox
            label={LIFECYCLE.AUTOMATIC_STATUS_CHANGE}
            checked={SetStatusResolved}
            onChange={(_, checked) => {
              updateInfoData({
                ...infoData,
                SetStatusResolved: checked.checked
              });
            }}
          />
        </FormGroup>
        {(isResolutionNoteFeatureAvailable && resolutionNoteConfig.IsEnabled && SetStatusResolved) &&
          <>
            <FormGroup marginClass='mb-5'>
              <LabelElement required={resolutionNoteConfig.RequireCategory} label={LIFECYCLE.RESOLUTION_CATEGORY}>
                  <div className={globalTheme.siteVariables.theme===themeNames.Dark ? Styles.nestedDropdownBackgroundDark : Styles.nestedDropdownBackgroundWhite}>
                    <NestedDropdown
                      tabIndex={0}
                      showClearBtn={true}
                      items={state.resolutionCategory?.map(item => ({
                          id: item.Id,
                          parentId: item.ParentId,
                          position: item.Position,
                          value: item.Value,
                          ...item
                      }))}
                      dataItem={{
                          id: ResolutionCategory?.Id,
                          parentId: ResolutionCategory?.ParentId,
                          position: ResolutionCategory?.Position,
                          value: ResolutionCategory?.Value,
                          ...ResolutionCategory
                      }}
                      defaultValue={ResolutionCategory?.Value}
                      onChange={value => {
                        let resCat = state.resolutionCategory.find(x=>x.Id==value?.id);
                        
                        updateInfoData({
                          ...infoData,
                          ResolutionCategory: resCat!=undefined ?  resCat : null,
                          ResolutionCategoryId: resCat!=undefined ? resCat.Id : 0
                        });
                      }}
                    />
                  </div>
                    
                  
              </LabelElement>
            </FormGroup>
            <FormGroup marginClass='mb-5'>
              <LabelElement label={LIFECYCLE.RESOLUTION_NOTE} required={resolutionNoteConfig.RequireNote}>
                
                <SlateEditor
                  onChange={(data)=>{
                      let mk = slateToMd(data);
                      if (infoData.ResolutionNote!=mk)
                      {
                        updateInfoData({
                          ...infoData,
                          ResolutionNote: mk
                        });
                      }
                  }}
                  slateValue={markdownToSlate(ResolutionNote)}
                  isFocus={true}
                  noImage={true}
                  minHeight='10rem'
                  className={globalTheme.siteVariables.theme===themeNames.Dark ? Styles.slateResolutionNoteDark : Styles.slateResolutionNoteWhite}
              />
                
                
              </LabelElement>
            </FormGroup>
            <FormGroup marginClass='mb-5'>
              <LabelElement label={LIFECYCLE.RESOLUTION_NOTE_INTERNAL} required={resolutionNoteConfig.RequireInternalNote}>
                  <SlateEditor
                      onChange={(data)=>{
                          let mk = slateToMd(data);
                          if (infoData.ResolutionNoteInternal!=mk){
                            updateInfoData({
                              ...infoData,
                              ResolutionNoteInternal: mk
                            });
                          }
                          
                      }}
                      slateValue={markdownToSlate(ResolutionNoteInternal)}
                      isFocus={true}
                      noImage={true}
                      minHeight='10rem'
                      className={globalTheme.siteVariables.theme===themeNames.Dark ? Styles.slateResolutionNoteDark : Styles.slateResolutionNoteWhite}
                  />
              </LabelElement>
            </FormGroup>
          </>
        }
        
      </>
    )}
  />
  };
