import React, { useEffect, useState } from 'react';
import { Button,CloseIcon,
    Dialog, Flex, ProviderConsumer as FluentUIThemeConsumer } from "@fluentui/react-northstar";
import { useTranslation } from 'react-i18next';
import { IResolution } from './ResolutionHelper';
import { ResolutionDetails } from './ResolutionDetails';

interface EditResolutionDetailsDialog{
    resolutionProp: IResolution;
    setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
    showDialog:boolean;
    disableDraftKB?:boolean;
    isBulkEdit?:boolean;
    setUpdatedResolution: React.Dispatch<React.SetStateAction<ResolutionDetail>>;
    confirmSave: () =>  Promise<void>;
}

export const ResolutionNoteDialog = (props: EditResolutionDetailsDialog) => {
    const { t } = useTranslation();

    const [isResetResolution, setIsResetResolution] = useState(false);
    const [enableSaveBtn, setEnableSaveBtn] = useState(false);
    

    useEffect(()=>{
        if (isResetResolution) setIsResetResolution(false);
    },[isResetResolution]);

    return (<FluentUIThemeConsumer
            render={globalTheme => (
                <Dialog
                content={
                    <ResolutionDetails
                        ticketData={props.resolutionProp.ticketData}
                        resolutionNoteConfig={props.resolutionProp.resolutionNoteConfig}
                        reset={isResetResolution}
                        setEnableSaveBtn={setEnableSaveBtn}
                        setUpdatedResolution={props.setUpdatedResolution}
                        disableDraftKB={props.disableDraftKB}
                        isBulkEdit={props.isBulkEdit}
                        isReadOnly={false}
                     />
                }
                header={t('resolution-note.details-tab')}
                open={props.showDialog}
                headerAction={{
                  icon: <CloseIcon />,
                  title: t('ticket-details.apply-template.dialog.button.close'),
                  onClick: ()=>props.setShowDialog(false)
                }}
                footer={
                  <Flex gap="gap.small">
                    <Flex.Item push>
                      <Button
                        content={t('ticket-details.apply-template.dialog.button.cancel')}
                        onClick={()=>{
                            props.setShowDialog(false);
                        }}
                    />
                  </Flex.Item>
                  <Flex.Item>
                    <Button
                      disabled={!enableSaveBtn}
                      content={t('common.buttons.confirm')}
                      primary
                      onClick={()=>{
                        props.setShowDialog(false);
                        props.confirmSave();
                      }}
                    />
                  </Flex.Item>
                </Flex>
              }
            />
            )} 
        />);
}