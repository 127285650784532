import React, { useCallback, useEffect, useState } from 'react';
import { platformService } from '../shared/services/platform.service';
import '../AppGlobals';
import {
  DEFAULT_API_STATUS,
  DONE,
  ERROR,
  LOADING,
  CATEGORY
} from '../shared/utils/constants';
import { Modal } from '../shared/components/Modal/Modal';
import { CheckLogin } from '../shared/components/CheckLogin';

interface Props {
  id: string;
  open: boolean;
  onCancel: () => void;
  onSuccessFullCall: () => void;
  updateData: (param:any) => Promise<void>;
  createData: (param:any) => Promise<any>;
  getList: (param?:string) => Promise<any>;
  title:string;
}

export const EnumEdit: React.FC<Props> = ({
  id,
  open,
  onCancel,
  onSuccessFullCall,
  updateData,
  createData,
  getList,
  title
}) => {
  const api = new platformService();
  const queryId = parseInt(id);

  const [categoryDetails, setCategoryDetails] = useState<StatusDetail>();
  const [categoryName, setCategoryName] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [apiStatus, setAPIStatus] = useState<APIStatus>(
    DEFAULT_API_STATUS as APIStatus
  );

  const updateAPIStatus = (newState: Partial<APIStatus>) => {
    setAPIStatus({ ...apiStatus, ...newState });
  };

  const fetchCategoryDetails = useCallback(async () => {
    updateAPIStatus({
      msg: CATEGORY.FETCHING_CATEGORY_DETAILS,
      status: LOADING
    });

    try {
      const { data }  = await getList(`(${queryId})`);
      setCategoryDetails(data);
      setCategoryName(data.Value);
      setIsDefault(data.IsDefault);
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    } finally {
      updateAPIStatus({ status: DONE });
    }
  }, [queryId]);

  const fetchCategoryDefaultInstance = useCallback(async () => {
    updateAPIStatus({
      msg: CATEGORY.FETCHING_CATEGORY_DEFAULT_INSTANCE,
      status: LOADING
    });

    try {
      const {
        data: { value }
      } = await api.getDefaultInstance(`?v=${new Date().getTime()}`);
      const parsedValue = JSON.parse(value);
      const requiredProps: StatusDetail = (({
        Value,
        Position,
        IsArchived,
        IsDefault,
        IsSystem,
        ParentId,
        Id,
        ModifiedDate,
        ModifiedById,
        CreatedDate,
        CreatedById,
        Guid,
        IsDeleted
      }) => ({
        Value,
        Position,
        IsArchived,
        IsDefault,
        IsSystem,
        ParentId,
        Id,
        ModifiedDate,
        ModifiedById,
        CreatedDate,
        CreatedById,
        Guid,
        IsDeleted
      }))(parsedValue);

      setCategoryDetails(requiredProps);
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    } finally {
      updateAPIStatus({ status: DONE });
    }
  }, []);

  const [isCheckIn, setIsCheckIn] = useState<boolean>(false);

  const loadPage = () => {
    setIsCheckIn(true);
  }

  useEffect(() => {
    if (isCheckIn) {
      if (queryId > 0 && open) fetchCategoryDetails();
      else fetchCategoryDefaultInstance();
    }
  }, [isCheckIn, queryId, open]);

  const checkAndUndefaultOther = async (currentDefaultId: number) => {
    try {
      const {
        data: { value }
      } = await getList(`?v=${new Date().getTime()}`);
      const defaultIdList: number[] = value
        .filter(
          (val: StatusDetail) =>
            val.IsDefault && val.Id !== currentDefaultId
        )
        .map((value: StatusDetail) => value.Id);

      if (defaultIdList.length > 0) {
        for (const id of defaultIdList) {
          const payload: UpdateCategoryPayload = {
            Id: id.toString(),
            IsDefault: false
          };

          await updateData(payload);
        }
      }
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    }
    // no finally block to avoid the flicker effect because finally sets the status to done
  };

  const createCategory = async (value: string, defaultCheck: boolean) => {
    const payload: StatusDetail = {
      ...categoryDetails,
      Value: value,
      IsDefault: defaultCheck
    };
    updateAPIStatus({
      msg: CATEGORY.CREATING_NEW_CATEGORY,
      status: LOADING
    });

    try {
      const {
        data: { Id }
      } = await createData(payload);

      if (defaultCheck) {
        await checkAndUndefaultOther(Id);
      }

      onCancel();
      onSuccessFullCall();
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    }
    // no finally block to avoid the flicker effect because finally sets the category to done
  };

  const updateCategory = async (value: string, defaultCheck: boolean) => {
    const payload: UpdateCategoryPayload = {
      Value: value,
      Id: categoryDetails.Id.toString(),
      IsDefault: defaultCheck
    };
    updateAPIStatus({
      msg: CATEGORY.UPDATING_CATEGORY,
      status: LOADING
    });

    try {
      await updateData(payload);

      if (defaultCheck) {
        await checkAndUndefaultOther(categoryDetails.Id);
      }

      onCancel();
      onSuccessFullCall();
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    }
    // no finally block to avoid the flicker effect because finally sets the category to done
  };

  const saveData = async (value: string, defaultCheck: boolean) => {
    if (queryId > 0) updateCategory(value, defaultCheck);
    else createCategory(value, defaultCheck);
  };

  const closeModal = () => {
    setCategoryDetails(null);
    setCategoryName('');
    setIsDefault(false);

    onCancel();
  };

  return (
    <CheckLogin onSignedIn={loadPage}>
      <Modal
        apiStatus={apiStatus}
        name={title}
        open={open}
        isUpdate={queryId > 0}
        inputValue={categoryName}
        checkBoxValue={isDefault}
        onCancelHandler={closeModal}
        onConfirmationHandler={(inputValue, checkBoxValue) => {
          saveData(inputValue, checkBoxValue);
        }}
      />
    </CheckLogin>
  );
};
