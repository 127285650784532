import React, { useEffect, useState } from 'react';
import '../../../../responsive.css';
import {
  Flex,
  Menu,
  Tooltip,
  ProviderConsumer as FluentUIThemeConsumer,
  Button,
  ArrowLeftIcon
} from '@fluentui/react-northstar';
import { appState, useSetState } from '../../../AppState';
import { platformService } from '../../../shared/services/platform.service';
import { RequestDetailCard } from './RequestDetailCard/RequestDetailCard';
import { TicketConversation } from '../../../shared/components/TicketConversation/TicketConversation';
import { EditRequestDetailsSkeleton } from '../Skeletons/EditRequestDetailsSkeleton';
import { EditTicketAttachments } from '../../../shared/components/EditTicketAttachments/EditTicketAttachments';
import { Helmet } from 'react-helmet';
import { EditTicketEvent, getCommentsCount, threedigitized, mergedCollaboratorOrAffectedUsers, updateApproval, updateApprovalList, getStatusIdByGuid } from '../../ticketHelper';
import { getCachedFeature } from '../../../shared/cache/FeatureCache';
import { LICENSE_FEATURE, PRODUCT_LICENSE, USER_TYPE, TicketApprovalState, toastDefault, MyWorkListViewType, STATUS_GUID } from '../../../shared/utils/constants';
import { getAllMergedTicketIds } from '../../../shared/components/EditTicketRelateTickets/RelateTicketHelper';
import { CheckFeature } from '../../../shared/components/CheckFeature';
import { GroupUsersCard } from './GroupUsersCard/GroupUsersCard';
import { EditTicketApprovals } from '../../../shared/components/EditTicketApprovals/EditTicketApprovals';
import { TicketApprovalsList } from '../../../shared/components/EditTicketApprovals/TicketApprovalsList';
import { TicketApprovalsSkeleton } from '../../../shared/components/EditTicketApprovals/TicketApprovalsSkeleton';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { EditTicketCustomFormAnswers } from '../../../shared/components/EditTicketCustomFormAnswers/EditTicketCustomFormAnswers';
import { ResolutionNoteState, TikitConfiguration } from '../../../shared/components/TicketResolutionNoteComponents/ResolutionHelper';
import { ResolutionDetails } from '../../../shared/components/TicketResolutionNoteComponents/ResolutionDetails';
import { themeNames } from '@fluentui/react-teams';

interface Props {
  ticketId: number;
  signInUser: SignedInUser;
  ticket: Ticket | null;
  isUserLicensed: boolean;
  setTicket: (value: Ticket | null) => void;
  setIsUserLicensed: (value: boolean | null) => void;
  toggleEditingToolbar?: (value: boolean) => void;
  isEditingOpen?: boolean;
  fileTriggerChannel?: string;
  setIsAuthorizedAccess?: (value: boolean) => void;
}

const api = new platformService();

export const EditRequestDetails = ({
  ticketId,
  signInUser,
  toggleEditingToolbar,
  isEditingOpen,
  fileTriggerChannel,
  setIsAuthorizedAccess,
  ticket,
  isUserLicensed,
  setTicket,
  setIsUserLicensed
}: Props) => {
  const currentState = appState();
  const setState = useSetState();
  const showIsEditing = isEditingOpen ? `editting-state-form` : ``;
  const [isLoading, setIsLoading] = useState(true);
  const [loadingApprovals, setLoadingApprovals] = useState(true);
  const [menu, setMenu] = useState(0);
  const [isAttachmentOpen, setIsAttachmentOpen] = useState(false);
  const [approvals, setApprovlas] = useState([]);
  const [fileAttachments, setFileAttachments] = useState([]);
  const [commentsCount, setCommentsCount] = useState<number>(0);
  const [attachmentsCount, setAttachmentsCount] = useState<number>(0);
  const [mergedTicketIds, setMergedTicketIds] = useState<number[]>([]);
  const [seeMoreCollaborators, setSeeMoreCollaborators] = useState(false);
  const [seeMoreAffectedUsers, setSeeMoreAffectedUsers] = useState(false);
  const [reloadConversation, setReloadConversation] = useState<boolean>(false);
  const [isCustomFormAnswersAccordionOpen, setIsCustomFormAnswersAccordionOpen] = useState(false);
  const [resolutionNoteConfig, setResolutionNoteConfig] = useState<TikitConfiguration>(ResolutionNoteState);
  const [isResolutionNoteEnabled, setIsResolutionNoteEnabled] = useState(false);
  const [menuItems, setMenuItems] = useState([]);

 

  const clickOnSeeMoreCollaboratorBtn = () => {
    setSeeMoreCollaborators(!seeMoreCollaborators);
  };

  const clickOnSeeMoreAffectedUsersBtn = () => {
    setSeeMoreAffectedUsers(!seeMoreAffectedUsers);
  };

  const [isApprovalsOpen, setIsApprovalsOpen] = useState(false);
  const [isLifeCycleEnabled, setIsLifeCycleEnabled] = useState<boolean>(false);
  const {t} = useTranslation();

  const itemRenderer = (MenuItem, props) => {
    const { tooltip = '', key, ...rest } = props;
    if (key === 'template' && (ticket.TemplateId || 0) <= 0)
      rest.disabled = true;

    return (
      <Tooltip content={tooltip} key={key}>
        <MenuItem {...rest} />
      </Tooltip>
    );
  };

  const _menuItems = [
    {
      key: 'activity',
      content: t('ticket-details.menu.activity'),
      tooltip: t('ticket-details.menu.tooltip'),
      children: itemRenderer
    }
  ];
  
  const getTicketData = async (id: number) => {
    try {
      const isRelateTicketEnabled = await getCachedFeature(LICENSE_FEATURE.RelatedTickets);
      const results = await Promise.all([
        api.getTicket(id, '?$expand=AffectedUsers($select=PlatformUserId),TicketCollaborators($select=PlatformUserId),Status,Category,Priority,Assignee,Requester,FileAttachments,Comments,Template,TicketLifecycle($expand=Status,Lifecycle($select=Title),Phases($orderby=Order))'),
        api.getTicketFiles(id),
        isRelateTicketEnabled ? api.getAllMergedTickets() : Promise.resolve([]),
        getCachedFeature(PRODUCT_LICENSE.AnalystAccess),
        api.getTeamsBotConfiguration(),
        api.getSystemSetting('ServiceDesk.Core.Configuration.ResolutionNoteConfiguration'),
        getCachedFeature(LICENSE_FEATURE.ResolutionNote)
      ]);

      setState(prevState => {
        prevState.teamsConfig = results[4].data
        return prevState;
      });

      setIsUserLicensed(results[3]);

      const ticket = results[0].data;
      setTicket(ticket);
      setIsLoading(false);
      setFileAttachments(results[1]);

      const mergedTicketIdsList = isRelateTicketEnabled ? getAllMergedTicketIds(id, results[2]) : [];
      setMergedTicketIds(mergedTicketIdsList);
      if (mergedTicketIdsList && mergedTicketIdsList.length > 0) setTicketCommentsCount(id, mergedTicketIdsList);
      else setCommentsCount(ticket?.Comments?.length ?? 0);

      setResolutionNoteConfig(results[5].data);
      setIsResolutionNoteEnabled(results[6]);

    } catch (error) {
      setIsLoading(false);
      const errCode = error['response']?.status;
      if(errCode == 404) setIsAuthorizedAccess(false);
      console.error(error);
    }
  };

  useEffect(()=>{
    if (isResolutionNoteEnabled && resolutionNoteConfig.IsEnabled && ticket.StatusId){
      const resolvedStatusId = getStatusIdByGuid(currentState.ticketStatus, STATUS_GUID.RESOLVED);
      const closedStatusId = getStatusIdByGuid(currentState.ticketStatus, STATUS_GUID.CLOSED);
      if ([resolvedStatusId,closedStatusId].includes(ticket.StatusId) || ticket.ResolutionId!=null){
        _menuItems.push({
          key: 'resolution',
          content: t('resolution-note.details-tab'),
          tooltip: t('resolution-note.details-tab'),
          children: itemRenderer
        });
      }
      
    }
    setMenuItems(_menuItems);
  },[isResolutionNoteEnabled]);

  const loadPage = async (id: number) => {
    setFileAttachments([]);
    setAttachmentsCount(0);
    setCommentsCount(0);
    setIsAttachmentOpen(false);
    setIsLoading(true);
    getTicketData(id);
    setIsCustomFormAnswersAccordionOpen(false);
    setIsApprovalsOpen(currentState.selectedTaskOrApproval?.type === MyWorkListViewType.Approvals);
    setState((prev) => ({ ...prev, selectedTaskOrApproval: undefined }));
  }

  const setTicketCommentsCount = async (id: number, mergedTicketIdsList: number[] = []) => {
    const ticketIds = [...mergedTicketIdsList, parseInt(id.toString())].join(',');
    const result = await api.getTicketComments(ticketIds);
    setCommentsCount(getCommentsCount(result));
  }

  const resetCommentsCount = () => {
    setTicketCommentsCount(ticket.Id, mergedTicketIds);
  }

  useEffect(() => {
    if (ticketId) loadPage(ticketId);
  }, [ticketId]);

  useEffect(() => {
    if (currentState.currentUserId && ticket) loadApprovals();
  }, [ticket]);

  const loadApprovals = async () => {
    try {
      setLoadingApprovals(true);
      const [isApprovalsEnabled, isLifecyclesEnabled] = await Promise.all([
        getCachedFeature(LICENSE_FEATURE.Approvals),
        getCachedFeature(LICENSE_FEATURE.TicketLifecycle)
      ]);
      if (isApprovalsEnabled) {
        setApprovlas(await getPendingApprovals(isLifecyclesEnabled));
      }
      setIsLifeCycleEnabled(isLifecyclesEnabled);
      setLoadingApprovals(false);
    }
    catch (error) {
      setLoadingApprovals(false);
      console.error(error);
    }
  }

  const getPendingApprovals = async (isLifecyclesEnabled: boolean) => {
    let isRequester = ticket ? ticket.Requester?.Id==currentState.currentUserId : false;
    return await api.getApprovalsByUserId(currentState.currentUserId, isLifecyclesEnabled, ticketId, isRequester);
  }




  const menuIndexChange = (_e, props: any) => {
    setMenu(props.activeIndex);
  };

  const ContentMenu = () => (
    <Menu
      defaultActiveIndex={menu}
      items={menuItems}
      underlined
      primary
      onActiveIndexChange={menuIndexChange}
    />
  );

  const onInteraction = async action => {
    if (action.event === EditTicketEvent.SaveSuccess)
      getTicketData(ticketId);
    if (action.event === 'onAttachmentsUpdate')
      setReloadConversation(true);
  };

  const updateApprovalStatus = async (approval: Approval, approverId: number, status: TicketApprovalState) => {
    try {
      const result = await updateApproval(api, approval, approverId, status, t);
      result.IsError ? toast.error(result.Message, toastDefault) : toast.success(result.Message, toastDefault);
      refreshApprovalList(approval.Id, approverId, status, result.UpdatedStatus);
    } catch (e) {
      toast.error(t('ticket-details.edit-ticket-form.approval.error.update'), toastDefault);
      console.error(e);
    }
  }

  const refreshApprovalList = (approvalId: number, approverId: number, state: TicketApprovalState, updatedStatus: TicketApprovalState) => {
    const updatedApprovals = updateApprovalList(approvals, approverId, approvalId, state, updatedStatus);
    setApprovlas(updatedApprovals);
  }

  const getLatestDroppedIndex = (fileAttachments: any): number => {
    let idx = 1;
    for (const file of fileAttachments) {
      if (file.FileName.indexOf('image-') > -1) {
        const indexNumber = parseInt(file.FileName.substring(file.FileName.indexOf('image-') + 'image-'.length, file.FileName.indexOf('image-') + 'image-'.length + 3));
        if (indexNumber > idx)
          idx = indexNumber;
      }
    }
    return idx;
  }

  return (
    <FluentUIThemeConsumer
      render={globalTheme => (
        <>
          {isLoading && <EditRequestDetailsSkeleton />}
          {!isLoading && (
            <>
              {ticket ? (
                <>
                  <Helmet>
                    <title>Ticket #{threedigitized(ticket?.Id)} - Tikit</title>
                  </Helmet>
                  <Flex.Item styles={{ flex: 1 }}>
                    <Flex column className="md:pt-5 md:px-5 md:pb-1">
                      <RequestDetailCard 
                        ticketData={ticket} 
                        commentsCount={commentsCount}
                        attachmentsCount={attachmentsCount}
                      />
                      <Flex.Item>
                        <ContentMenu />
                      </Flex.Item>
                      {menuItems[menu].key==="activity" && signInUser && (
                        <TicketConversation
                          isTicketMerged={ticket.IsMerged}
                          isTicketClosed={ticket.Closed}
                          isUserLicensed={isUserLicensed}
                          ticketId={ticketId}
                          assigneeId={ticket.AssigneeId}
                          requester={ticket.Requester}
                          signedInUser={signInUser}
                          isEndUserPage={true}
                          resetCommentsCount={resetCommentsCount}
                          priority={ticket.PriorityId} 
                          status={ticket.StatusId} 
                          dueDate={ticket.DueDate}        
                          ticketData={null} 
                          uploadedFiles={fileAttachments}
                          setUploadedFiles={setFileAttachments}
                          reloadConversation={reloadConversation}
                          setReloadConversation={setReloadConversation}
                          globalTheme={globalTheme}   
                          droppedIndex={getLatestDroppedIndex(fileAttachments)}           
                        />
                      )}
                      {menuItems[menu].key==="resolution" && (
                          <div className='resolution-note-activity' style={{background : globalTheme.siteVariables.theme===themeNames.Dark ? "var(--mgt-theme-background)" : "white"}}>
                            <ResolutionDetails
                              isEndUser={true}
                              ticketData={ticket}
                              resolutionNoteConfig={resolutionNoteConfig}
                            />
                          </div>
                        )}
                    </Flex>
                  </Flex.Item>

                  <Flex.Item className={`collapsible-component ${showIsEditing} w-1/4 h-full overflow-auto pb-10`}>
                    <div className={`m-0 overflow-auto mobile-editform px-3 py-5`}>
                      <div className={`block md:hidden mb-3`}>
                        <Button
                          onClick={() => { toggleEditingToolbar(false); }}
                          className={`mb-1`}
                          content={<ArrowLeftIcon size="large" />}
                          text
                          iconOnly
                          style={{ color: globalTheme.siteVariables.colorScheme.brand.foregroundActive }}
                        />
                      </div>
                      
                      <CheckFeature featureName={LICENSE_FEATURE.AffectedUsers}>
                        {(ticket.AffectedUsers?.length > 0 || ticket.Requester) && (
                          <div>
                            <GroupUsersCard
                              title={t('requests.affected-users')}
                              usersList={mergedCollaboratorOrAffectedUsers(_.cloneDeep(ticket.AffectedUsers?.map(a => currentState.platformusers.find(u => u.Id == a.PlatformUserId))), ticket.Requester, USER_TYPE.AFFECTED_USER)}
                              clickOnSeeMoreBtn={clickOnSeeMoreAffectedUsersBtn}
                              seeMore={seeMoreAffectedUsers}
                            />
                          </div>
                        )}
                      </CheckFeature>

                      <CheckFeature featureName={LICENSE_FEATURE.Collaborators}>
                        {(ticket.TicketCollaborators?.length > 0 ||
                          ticket.Assignee) && (
                          <div className={`mt-2`}>
                            <GroupUsersCard
                              title={t('requests.assigned-agents')}
                              usersList={mergedCollaboratorOrAffectedUsers(_.cloneDeep(ticket.TicketCollaborators?.map(a => currentState.platformusers.find(u => u.Id == a.PlatformUserId))), ticket.Assignee, USER_TYPE.COLLABORATOR)}
                              clickOnSeeMoreBtn={clickOnSeeMoreCollaboratorBtn}
                              seeMore={seeMoreCollaborators}
                            />
                          </div>
                        )}
                      </CheckFeature>

                      <EditTicketAttachments
                        ticketId={ticketId}
                        globalTheme={globalTheme}
                        isOpen={isAttachmentOpen}
                        onCLose={value => {
                          setIsAttachmentOpen(value);
                        }}
                        primaryButtonColor={ globalTheme.siteVariables.colorScheme.brand.foreground1}
                        isSaving={false}
                        onInteraction={onInteraction}
                        fileTriggerChannel={fileTriggerChannel}
                        isEndUserPage={true}
                        isTicketClosed={ticket.Closed}
                        fileAttachments={fileAttachments}
                        teamsConfig={currentState.teamsConfig}
                        signedInUser={signInUser}
                      />

                      <EditTicketCustomFormAnswers
                        globalTheme={globalTheme}
                        ticketId={ticketId}
                        isOpen={isCustomFormAnswersAccordionOpen}
                        onCLose={value => { setIsCustomFormAnswersAccordionOpen(value) }}
                        primaryButtonColor={globalTheme.siteVariables.colorScheme.brand.foreground1}
                        isSaving={false}
                        parentData={ticket}
                        isDisabled={true}
                        isEndUserForm={true}
                      />

                      <CheckFeature featureName={LICENSE_FEATURE.Approvals}>
                        <EditTicketApprovals
                          ticketApprovalsCount={approvals?.length ?? 0}
                          isFormDisabled={ticket.Closed}
                          isApprovalsOpen={isApprovalsOpen}
                          setIsApprovalsOpen={setIsApprovalsOpen}
                          primaryButtonColor={globalTheme.siteVariables.colorScheme.brand.foreground1}
                          isEndUser={true}
                        />
                        {isApprovalsOpen && ((loadingApprovals || !currentState.currentUserId) ? (<TicketApprovalsSkeleton />) : (
                          <TicketApprovalsList 
                            ticketApprovals={approvals}
                            currentUserId={currentState.currentUserId}
                            isTicketClosed={ticket.Closed}
                            isEndUser={true}
                            updateApprovalStatus={updateApprovalStatus}
                            disableButonOnUpdate={true}
                          />
                        ))}
                      </CheckFeature>
                    </div>
                  </Flex.Item>
                </>
              ) : null}
            </>
          )}
        </>
      )}
    />
  );
};
