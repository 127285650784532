import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Flex, Dropdown, Text, TextArea, ProviderConsumer as FluentUIThemeConsumer, Button, Dialog, CloseIcon, ThemePrepared } from '@fluentui/react-northstar';
import { useSetState, appState } from '../../AppState';
import { platformService } from '../services/platform.service';
import { MemoizedRequesterCard } from './RequesterCard';
import { PlatformUser } from '../interfaces/platformuser.interface';
import { PropertiesAccordion } from './EditTicketForm/PropertiesAccordion';
import { PeoplePicker } from '@microsoft/mgt-react';
import { NestedDropdown } from './NestedDropdown/NestedDropdown';
import { PropertiesSkeletonLoader } from './EditTicketForm/SkeletonLoaders/PropertiesSkeletonLoader';
import { LICENSE_FEATURE, TAGS, TicketApprovalState, STATUS_GUID, toastDefault, FILTERS_STRINGS, TASK_STATUS_GUID, LIFECYCLE_STATUS_GUID, ADMINISTRATORS, KNOWLEDGEAGENT, ANALYSTS } from '../utils/constants';
import { EditTicketAttachments } from './EditTicketAttachments/EditTicketAttachments';
import { EditTicketCustomFormAnswers } from './EditTicketCustomFormAnswers/EditTicketCustomFormAnswers';
import { EditTicketRelateTickets } from './EditTicketRelateTickets/EditTicketRelateTickets';
import { RelatedTicketsList } from './EditTicketRelateTickets/RelatedTicketsList';
import { IRelatedTickets, IRelatedTicketsCard, IRelateTickets, RelationType } from './EditTicketRelateTickets/RelateTicketHelper';
import { RelatedTicketsListSkeleton } from './EditTicketRelateTickets/RelatedTicketsListSkeleton';
import useUnsavedChangesWarning from '../hooks/useUnsavedChangesWarning';
import { CheckFeature } from './CheckFeature';
import { toast } from 'react-toastify';
import { EditTicketApprovals } from './EditTicketApprovals/EditTicketApprovals';
import { TicketApprovalsList } from './EditTicketApprovals/TicketApprovalsList';
import { TicketApprovalsSkeleton } from './EditTicketApprovals/TicketApprovalsSkeleton';
import { EditTicketEvent, filterPlatformUsersInRole, getStatusIdByGuid, refershPlatformUserState, updateApproval } from '../../tikit/ticketHelper';
import { getCachedFeature } from '../cache/FeatureCache';
import { UserType, PersonType } from '@microsoft/mgt-components';
import { LabelElement } from './TicketForm';
import { TicketTasks } from './TicketTasks/TicketTasks';
import { dialogContent, dialogHeader, listOfOpenTask, listOfPenddingApprovals } from './TicketTasks/TaskCommon/TaskCommon';
import { LifecycleSelection } from '../../lifecycle/LifecycleSelection';
import { TicketDatePicker } from '../common/Controls/TicketDateTimePicker';
import { TicketTagPicker } from '../common/Controls/TagsPicker';
import { sendRequestToApplyLifecycle, TemplateListDialog } from '../../templates/TemplateListDialog';
import { TicketApprovalStateString } from '../../lifecycle/utils/constants';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import {AddOrUpdateResolutionToDraftKB, CheckIfResolutionIsChanged, IResolution,TikitConfiguration} from './TicketResolutionNoteComponents/ResolutionHelper';
import { clone } from "lodash";
import { ResolutionNoteDialog } from './TicketResolutionNoteComponents/ResolutionNoteDialog';


interface EditTicketProps {
  signedInUser: {
    FullName: string;
    Id: number;
    Email: string;
  };
  ticket: Ticket;
  isUserLicensed: boolean;
  dispatchParentEvt?: any;
  savingState: boolean;
  unassigningState: boolean;
  takingState: boolean;
  applyTemplateState: boolean;
  applyLifecycleState: boolean;
  resetUpdateTicketState: boolean;
  onInteraction?: (interaction: any) => void;
  onToolbarInteraction?: (interaction: any) => void;
  globalTheme: ThemePrepared<any>;
  fileTriggerChannel: string;
  setTicketsStateHandler: React.Dispatch<React.SetStateAction<SideViewTicketState>>;
  SetdetailCardApprovalsList: (approvalsList: TicketApprovals[]) => void;
  setDetailCardTaskList: (tasksList: TicketTask[]) => void;  
  ResolutionNoteConfig:TikitConfiguration;
  isDropzoneDisabled?: boolean;
  defaultTicket?: Ticket;
}

// Reducer Routines
const ACTIONS = {
  ADD_OLD_TAGS: 'add-old-tags',
  SET_TICKET: 'set-ticket',
  TICKET_LOAD_SUCCESS: 'ticket-load-success',
  RELATED_TICKETS_LOAD_SUCCESS: 'related-tickets-load-success',
  RELATED_TICKETS_LOADING: 'related-tickets-loading',
  ADD_TAG_TO_LIST: 'add-tag-to-list',
  CREATE_TAG_SUCCESS: 'create-tag-success',
  CREATING_TAG: 'creating-tag',
  UPDATE_TICKET: 'update-ticket',
  UPDATE_REQUESTER: 'update-requester',
  SET_PLATFORM_USERS: 'set-platform-users',
  SET_SUPPORT_GROUPS: 'set-support-groups',
  SET_TEAMS: 'set-teams',
  SET_TEAM_NAME: 'set-team-name',
  SET_TEMPLATE: 'set-template',
  SET_SELECTED_TEMPLATE: 'set-selected-template',
  SET_HANDLE_CUSTOMFORM: 'set-handle-customform',
  PLATFORM_USER_SEARCHING: 'platform-user-searching',
  PLATFORM_USER_SEARCH_ONBLUR: 'platform-user-search-onblur',
  SET_ASSIGNEE_NAME: 'SET_ASSIGNEE_NAME',
  TICKET_APPROVALS_LOAD_SUCCESS:'ticket-approvals-load-success',
  TICKET_APPROVALS_LOADING:'ticket-approvals-loading',
  APPLY_LIFECYCLE_LOADING: 'apply-lifecycle-loading',
  TEMPLATE_HAS_ERROR: "TemplateHasError",
  TEMPLATE_ERROR_MESSAGE: "TemplateErrorMessage",
  TICKET_TASKS_LOAD_SUCCESS: 'ticket-tasks-load-success',
  TICKET_TASKS_LOADING: 'ticket-tasks-loading',
  IsTemplateSelectionSaving: "IsTemplateSelectionSaving",
  SET_DEFAULT_TICKET: 'set-defaut-ticket'
};



const editTicketReducer = (state, action) => {

  switch (action.type) {
    case ACTIONS.ADD_OLD_TAGS: {
      return {
        ...state,
        OldTags: action.data
      };
    }
    case ACTIONS.SET_TICKET:
    case ACTIONS.SET_TEAMS:
    case ACTIONS.SET_SUPPORT_GROUPS:
    case ACTIONS.SET_HANDLE_CUSTOMFORM:
    case ACTIONS.SET_SELECTED_TEMPLATE:
    case ACTIONS.SET_TEMPLATE: {
      return {
        ...state,
        ...action.data
      };
    }
    case ACTIONS.TICKET_LOAD_SUCCESS: {
      return {
        ...state,
        LoadingTicketData: false
      };
    }
    case ACTIONS.RELATED_TICKETS_LOAD_SUCCESS: {
      return {
        ...state,
        LoadingRelatedTickets: false
      };
    }
    case ACTIONS.RELATED_TICKETS_LOADING: {
      return {
        ...state,
        LoadingRelatedTickets: true
      };
    }
    case ACTIONS.ADD_TAG_TO_LIST: {
      let list = state.TagList;
      list.push(action.data);
      return {
        ...state,
        TagList: list
      };
    }
    case ACTIONS.CREATING_TAG: {
      return {
        ...state,
        IsCreatingTag: true
      };
    }
    case ACTIONS.CREATE_TAG_SUCCESS: {
      return {
        ...state,
        IsCreatingTag: false
      };
    }
    case ACTIONS.UPDATE_TICKET: {
      return {
        ...state,
        UpdatedTicket: {
          ...state.UpdatedTicket,
          ...action.data
        }
      };
    }
    case ACTIONS.UPDATE_REQUESTER: {
      const {
        RequesterId,
        Requester: { FullName, Email, AadObjectId, UserName, GivenName, FamilyName }
      } = action.data;

      return {
        ...state,
        RequesterId,
        Requester: { FullName, Email, AadObjectId, UserName, GivenName, FamilyName }
      };
    }
    case ACTIONS.SET_PLATFORM_USERS: {
      return {
        ...state,
        PlatformUsers: action.data,
        AssigneeName: action.AssigneeName
      };
    }
    case ACTIONS.PLATFORM_USER_SEARCHING: {
      return {
        ...state,
        platformUserSearching: true
      };
    }
    case ACTIONS.PLATFORM_USER_SEARCH_ONBLUR: {
      return {
        ...state,
        platformUserSearching: false
      };
    }
    case ACTIONS.SET_ASSIGNEE_NAME: {
      return {
        ...state,
        AssigneeName: action.data
      };
    }
    case ACTIONS.SET_TEAM_NAME: {
      return {
        ...state,
        TeamName: action.data
      };
    }
    case ACTIONS.TICKET_APPROVALS_LOAD_SUCCESS: {
      return {
        ...state,
        LoadingTicketApprovals: false
      };
    }
    case ACTIONS.TICKET_APPROVALS_LOADING: {
      return {
        ...state,
        LoadingTicketApprovals: true
      };
    }
    case ACTIONS.TEMPLATE_HAS_ERROR:
      return {
        ...state,
        TemplateHasError: action.data
      };
    case ACTIONS.TEMPLATE_ERROR_MESSAGE:
      return {
        ...state,
        TemplateErrorMessage: action.data
      };
    case ACTIONS.IsTemplateSelectionSaving:
      return {
        ...state,
        IsTemplateSelectionSaving: action.data
      };
    case ACTIONS.TICKET_TASKS_LOAD_SUCCESS: {
      return {
        ...state,
        LoadingTicketTasks: false
      };
    }
    case ACTIONS.TICKET_TASKS_LOADING: {
      return {
        ...state,
        LoadingTicketTasks: true
      };
    }
    case ACTIONS.SET_DEFAULT_TICKET:{
      return {
        ...state,
        defaultTicket: {
          ...state.defaultTicket,
          ...action.data
        }
      };
    }
    default:
      throw new Error(`Unexpected action`);
  }
};

const initialState = {
  platformUserSearching: false,
  LoadingTicketData: true,
  LoadingRelatedTickets: true,
  RequesterId: null,
  Requester: { Name: '', Email: '', AadObjectId: '', UserName: '', GivenName: '', FamilyName: '' },
  Title: '',
  StatusId: null,
  StatusName: '',
  Statuses: [],
  Status: {},
  CategoryId: null,
  CategoryName: '',
  Categories: [],
  TicketTypeId: null,
  TicketTypeName: '',
  TicketTypes: [],
  PriorityId: null,
  PriorityName: '',
  Priorities: [],
  AssigneeId: null,
  AssigneeName: '',
  SupportGroupId: null,
  SupportGroupName: '',
  SupportGroupMembers: [],
  SupportGroups: {},
  TeamId: null,
  TeamName: '',
  TeamsAadObjectId: '',
  Teams: {},
  TemplateId: null,
  TemplateName: '',
  Template: {},
  SelectedTemplate: {},
  CardTemplateJson: '',
  CardAnswerJson: '',
  PlatformUsers: [],
  DueDate: null,
  ResolutionDate: null,
  Tags: [],
  TagList: [],
  TagSearchQuery: '',
  OldTags: [],
  NewTags: [],
  IsCreatingTag: false,
  UpdatedTicket: {},
  TicketCollaborators: [],
  AffectedUsers:[],
  LoadingTicketApprovals:true,
  TemplateHasError:false,
  TemplateErrorMessage:"",
  LoadingTicketTasks: true,
  ResolutionId: null,
  Resolution:{Note: "", InternalNote: "", ResolutionCategoryId:0, ResolutionCategory:null, DraftKB:false, AnswerId:null},
  ResolutionCategories:[]
};

interface TicketInitialState {
  Title?: string | null;
  RequesterId?: number | null;
  StatusId?: number | null;
  TicketTypeId?: number | null;
  CategoryId?: number | null;
  PriorityId?: number | null;
  SupportGroupId?: number | null;
  TeamId?: number | null;
  AssigneeId?: number | null;
  DueDate?: string | null;
  ResolutionDate?: string | null;
  Tags: any[];
  TicketCollaborators?: PlatformUser[];
  AffectedUsers?: any[];
  ResolutionId: number | null;
}

const collaboratorsFormat = (analystList: Assignee[], selectedCollaborator: PlatformUser[] | null) => {
  let collaboratorFormatedList = [];
  analystList.forEach((t: { FullName: any; Id: number }) => {
      let collaboratorObj = {};
      collaboratorObj['header'] = t.FullName;
      collaboratorObj['accessibilityitemprops'] = t.Id;
      if (selectedCollaborator?.some(item => item.Id == t.Id)) {
        collaboratorObj['selected'] = true;
      } else {
        collaboratorObj['selected'] = false;
      }
      collaboratorFormatedList.push(collaboratorObj);
  });
  return collaboratorFormatedList;
};

export const getRelativeDateValue = (relativeDate: string) => {
  const [relative, number] = relativeDate.split('-');
  const numberVal = +number;
  switch(relative){
    case 'today':
      return DateTime.local();
    case 'yesterday':
      return DateTime.local().plus({ days: -1 });
    case 'tomorrow':
      return DateTime.local().plus({ days: 1 });
    case 'firstdayofmonth':
      return DateTime.local().startOf('month');
    case 'firstdayofquarter':
      return DateTime.local().startOf('month');
    case 'firstdayofyear':
      return DateTime.local().startOf('year');
    case 'lastdayofmonth':
      return DateTime.local().endOf('month');
    case 'lastdayofquarter':
      return DateTime.local().endOf('month');
    case 'lastdayofyear':
      return DateTime.local().endOf('year');
    case 'minutesago':
      return DateTime.local().minus({ minutes: numberVal });
    case 'hoursago':
      return DateTime.local().minus({ hours: numberVal });
    case 'daysago':
      return DateTime.local().minus({ days: numberVal });
    case 'monthsago':
      return DateTime.local().minus({ months: numberVal });
    case 'yearsago':
      return DateTime.local().minus({ years: numberVal });
    case 'minutesfromnow':
      return DateTime.local().plus({ minutes: numberVal });
    case 'hoursfromnow':
      return DateTime.local().plus({ hours: numberVal });
    case 'daysfromnow':
      return DateTime.local().plus({ days: numberVal });
    case 'monthsfromnow':
      return DateTime.local().plus({ months: numberVal });
    case 'yearsfromnow':
      return DateTime.local().plus({ years: numberVal });
    default:
      return DateTime.local();
  }
}

// Component
export const EditTicketForm = (props: EditTicketProps) => {
  const { t } = useTranslation();
  const api = new platformService();
  const parentDispatch = props.dispatchParentEvt;
  const templatePages = ['select', 'done'];
  const setAppState = useSetState();
  const currentState = appState();
  const tagSearch = useRef(null);

  const [formState, dispatch] = useReducer(editTicketReducer, initialState);

  const [isPropertiesAccordionOpen, setIsPropertiesAccordionOpen] = useState(false);
  const [isAttachmentAccordionOpen, setIsAttachmentAccordionOpen] = useState(false);
  const [reloadAttachmentData, setReloadAttachmentData] = useState(false);
  const [isRelateTicketsOpen, setIsRelateTicketsOpen] = useState(false);
  
  const [isApprovalsOpen, setIsApprovalsOpen] = useState(false);
  const [isCustomFormAnswersAccordionOpen, setIsCustomFormAnswersAccordionOpen] = useState(false);
  const [openApplyTemplate, setOpenApplyTemplate] = useState(false);
  const [isApplyingTemplateWithLifecycle, setIsApplyingTemplateWithLifecycle] = useState(false);
  const [openApplyLifecycle, setOpenApplyLifecycle] = useState(false);
  const [redirectAfterSave, setRedirectAfterSave] = useState(true);
  const [templates, setTemplates] = useState<any[]>([]);
  const [templatePage, setTemplatePage] = useState(1);
  const [fileAttachments, setFileAttachments] = useState([]);
  const [relatedTickets, setRelatedTickets] = useState<IRelatedTicketsCard[]>([]);
  const [ticketInitialState, setTicketInitialState] = useState<TicketInitialState>(null);
  const [isFormUpdated, setIsFormUpdated] = useState<boolean>(false);
  const [reloadTaskList, setReloadTaskList] = useState<boolean>(false);
  const [reloadApprovals, setReloadApprovals] = useState<boolean>(false);
  const [ticketApprovals, setTicketApprovals] = useState<TicketApprovals[]>([])
  const [approversList, setApproversList] = useState<PlatformUser[]>([]);
  const [penddingTicketApprovals, setPenddingTicketApprovals] = useState<TicketApprovals[]>([]);
  const [showRequestFiledError, setShowRequestFieldError] = useState<boolean>(false);
  const [showTeamsRequiredError, setTeamsRequiredError] = useState<boolean>(false);
  const [showTaskWarningDialog, setShowTaskWarningDialog] = useState<boolean>(false);
  const [showResolvedDialog, setShowResolvedDialog] = useState<boolean>(false);
  const [openTaskList, setOpenTaskList] = useState<TicketTask[]>([]);
  const [isLifecycleEnabled, setIsLifecycleEnabled] = useState<boolean>(true);
  const [ticketTasks, setTicketTasks] = useState<TicketTask[]>([]);

  const [Prompt, setDirty] = useUnsavedChangesWarning();
  const setFormDirty: (boolVlaue: boolean) => void = setDirty as (boolVlaue: boolean) => void;

  const isFormDisabled = props.ticket.Closed || !props.isUserLicensed;
  const isMergedOrDeflected = !ticketInitialState || props.ticket.IsMerged || !props.isUserLicensed || ticketInitialState.StatusId == getStatusIdByGuid(currentState.ticketStatus, STATUS_GUID.DEFLECTED);

  const [collaboratorList, setCollaboratorList] = useState([]);
  
  const [isPickerLoaded, setIsPickerLoaded] = useState(false);
  const [isAffectedPickerLoaded, setIsAffectedPickerLoaded] = useState(false);
  const [affectedUsersEnabled, setAffectedUsersEnabled] = useState(false);
  const [isEnableResolutionNote, setIsEnableResolutionNote] = useState(false);
  const [isResolutionRole, setIsResolutionRole] = useState(false);
  const [resolution, setResolution] = useState<ResolutionDetail>();
  

  useEffect(() => {
    if (isPropertiesAccordionOpen && !formState.LoadingTicketData) {
      setIsResolutionRole(currentState.userRoles.roles.some(x=>[ADMINISTRATORS,ANALYSTS,KNOWLEDGEAGENT].includes(x)));
      if (!isPickerLoaded) {
        const picker: any = document.getElementById('mgt-requester');
        if (picker) {
          picker.selectedPeople = formState?.Requester ? [{
            displayName: formState?.Requester.FullName,
            userPrincipalName: formState?.Requester.UserName,
            id: formState?.Requester.AadObjectId
          }] : [];
          setIsPickerLoaded(true);
        }
      }

      if (!isAffectedPickerLoaded) {
        const picker: any = document.getElementById('mgt-affected');
        if (picker) {
          picker.selectedPeople = formState?.AffectedUsers ? formState.AffectedUsers?.map((i: any) => { 
            const item = currentState.platformusers.find(u => u.Id == i.PlatformUserId);
            return {
              displayName: item?.FullName,
              userPrincipalName: item?.UserName,
              id: item?.AadObjectId
            };
          }) : [];
          setIsAffectedPickerLoaded(true);
        }
      }
    } else {
      setIsPickerLoaded(false);
      setIsAffectedPickerLoaded(false);
    }
  }, [isPropertiesAccordionOpen, formState.LoadingTicketData]);



  // Functions
  const formatProperty = (
    items: any[],
    returnName: string,
    returnValue: string,
    selectedValue: number | null,
    valueProperty: string = 'Value',
    idProperty: string = 'Id',
    extraProperties: string[] = [],
    extraReturn: any[] = []
  ) => {
    const formatted = [];
    let selectedItem: any = null;

    if (!items) items = [];

    items.forEach(i => {
      const f = {
        header: i[valueProperty],
        accessibilityitemprops: { id: i[idProperty] },
        selected: i.Id === selectedValue,
        ...i
      };
      extraProperties.forEach(p => { f[p.toLowerCase()] = i[p]; });
      formatted.push(f);

      if (f.selected) selectedItem = i;
    });

    const ret = {
      [returnName]: formatted,
      [returnValue]: (selectedItem || { [valueProperty]: '' })[valueProperty]
    };
    extraReturn.forEach(r => {
      ret[r.ReturnName] = selectedItem ? selectedItem[r.PropertyName] : r.DefaultValue;
    });
    return ret;
  };

  const rebindDropdown = (items: any[], selectedId: number | null) => items.map(i => ({ selected: selectedId === i.accessibilityitemprops.id, ...i }));

  const saveTicketInitialState = (initialTicket: any) => {
    let initialTicketData: TicketInitialState = {
      Title: initialTicket.Title,
      RequesterId: initialTicket.RequesterId,
      StatusId: initialTicket.StatusId,
      TicketTypeId: initialTicket.TicketTypeId,
      CategoryId: initialTicket.CategoryId,
      PriorityId: initialTicket.PriorityId,
      SupportGroupId: initialTicket.SupportGroupId,
      TeamId: initialTicket.TeamId,
      AssigneeId: initialTicket.AssigneeId,
      DueDate: initialTicket.DueDate,
      ResolutionDate: initialTicket.ResolutionDate,
      Tags: [],
      TicketCollaborators: initialTicket.TicketCollaborators,
      AffectedUsers: initialTicket.AffectedUsers,
      ResolutionId: initialTicket.ResolutionId
    };
    setTicketInitialState(initialTicketData);
  };

  const getResponseValue = (value: any) => value || [];
  
  const loadPage = async () => {
    try {

      const [thisEnabledMultiDepartment, thisEnabledLifecycle, enabledTicketTags, enabledAffectedUsers, enabledResolutionNote] = await Promise.all([
        getCachedFeature(LICENSE_FEATURE.MultiDepartment),
        getCachedFeature(LICENSE_FEATURE.TicketLifecycle),
        getCachedFeature(LICENSE_FEATURE.TicketTags),
        getCachedFeature(LICENSE_FEATURE.AffectedUsers),
        getCachedFeature(LICENSE_FEATURE.ResolutionNote)
      ]);

      setAffectedUsersEnabled(enabledAffectedUsers);
      setIsEnableResolutionNote(enabledResolutionNote);

      const results = await Promise.all([
        enabledTicketTags ? api.getTags(FILTERS_STRINGS.DELETE_FALSE) : Promise.resolve([]),
        api.getTicketTemplates(`${FILTERS_STRINGS.FILTERS_URL_PARAMS}&$expand=Lifecycle($expand=Phases),Tags,AffectedUsers,Collaborators&$orderby=Name`),
        api.getTicketFiles(props.ticket.Id),
        api.getAllApprovals()
      ]);

      const tags = getResponseValue(results[0]);

      setIsLifecycleEnabled(thisEnabledLifecycle);
      setTemplates(results[1].data.value);
      setFileAttachments(results[2]);
      setAppState((prev) => ({
        ...prev,
        ticketApprovals: getResponseValue(results[3]),
        tags: tags
      }));

      // Process Ticket
      dispatch({ type: ACTIONS.SET_TICKET, data: props.ticket });

      // Process Tags
      const oldTags = [];
      props.ticket.Tags.forEach((t: any) => { oldTags.push(t.Id) });
      dispatch({ type: ACTIONS.ADD_OLD_TAGS, data: oldTags });

      const selectedTags = [], itemTags = [];
      if (oldTags && oldTags.length > 0) {
        oldTags.forEach(d => selectedTags.push(d));
        tags.forEach((t: any) => itemTags.push({ header: t.Name, accessibilityitemprops: t.Id, selected: selectedTags.includes(t.Id) }));
      } else {
        tags.forEach((t: any) => itemTags.push({ header: t.Name, accessibilityitemprops: t.Id, selected: false }));
      }
      
      // Process properties
      dispatch({
        type: ACTIONS.SET_TICKET,
        data: {
          NewTags: oldTags, TagList: itemTags,
          ...formatProperty(currentState.ticketStatus, 'Statuses', 'StatusName', props.ticket.StatusId),
          ...formatProperty(currentState.categories, 'Categories', 'CategoryName', props.ticket.CategoryId),
          ...formatProperty(currentState.priority, 'Priorities', 'PriorityName', props.ticket.PriorityId),
          ...formatProperty(currentState.ticketTypes, 'TicketTypes', 'TicketTypeName', props.ticket.TicketTypeId),
          ...formatProperty(currentState.resolutionCategory, 'ResolutionCategories', 'ResolutionCategoryName', props.ticket.Resolution?.ResolutionCategoryId),
        }
      });

      dispatch({
        type: ACTIONS.SET_TEAMS,
        data: formatProperty(currentState.teams, 'Teams', 'TeamName', props.ticket.TeamId, 'FriendlyName', 'Id')
      });

      const extraReturn = [{ ReturnName: 'SupportGroupMembers', PropertyName: 'Members', DefaultValue: [] }];
      if (props.ticket.TeamId && thisEnabledMultiDepartment) {
        const teamsAadObjectId = currentState.teams.filter(x => x.Id == props.ticket.TeamId)[0]['TeamsAadObjectId'];
        const items = (teamsAadObjectId) ? currentState.supportgroups.filter(x => x.TeamsAadObjectId == teamsAadObjectId) : [];
        dispatch({
          type: ACTIONS.SET_SUPPORT_GROUPS,
          data: formatProperty(items, 'SupportGroups', 'SupportGroupName', props.ticket.SupportGroupId, 'Name', 'Id', ['Members'], extraReturn)
        });
      } else {
        dispatch({
          type: ACTIONS.SET_SUPPORT_GROUPS,
          data: formatProperty(currentState.supportgroups, 'SupportGroups', 'SupportGroupName', props.ticket.SupportGroupId, 'Name', 'Id', ['Members'], extraReturn)
        });
      }

      dispatch({
        type: ACTIONS.SET_TEMPLATE,
        data: formatProperty(results[1].data.value, 'Template', 'TemplateName', props.ticket.TemplateId, 'Name', 'Id')
      });

      if (props.ticket.SupportGroupId) {
        dispatch({
          type: ACTIONS.SET_PLATFORM_USERS,
          ...formatAssigneeProperty(
            currentState.supportgroups.find(s => s.Id == props.ticket.SupportGroupId)?.Members ?? currentState.teams.find(s => s.Id == props.ticket.TeamId)?.Users ??  currentState.platformusers,
            props.ticket.AssigneeId
          )
        });
      }
      else if (props.ticket.TeamId && thisEnabledMultiDepartment) {
        dispatch({
          type: ACTIONS.SET_PLATFORM_USERS,
          ...formatAssigneeProperty(
            currentState.teams.find(s => s.Id == props.ticket.TeamId).Users,
            props.ticket.AssigneeId
          )
        });
      }
      else {
        dispatch({
          type: ACTIONS.SET_PLATFORM_USERS,
          ...formatAssigneeProperty(currentState.platformusers, props.ticket.AssigneeId)
        });
      }

      dispatch({ type: ACTIONS.TICKET_LOAD_SUCCESS });

      saveTicketInitialState(props.ticket);
      setAppState((prev)=>({
        ...prev,
        isLoadingTemplates: false
      }));
    } catch (e) {
      console.error(e);
    }
  };

  // Save Routines
  const createTag = async (data: any) => {
    try {
      const enabledTicketTags = await getCachedFeature(LICENSE_FEATURE.TicketTags);
      if(enabledTicketTags) {
        const getTags = await api.getTags(`?$filter=Name eq '${encodeURIComponent(data.Name.replace(/'/ig, "''"))}'`);
        if (getTags.length == 0) {
          const addTagResponse = await api.addTag(data);
          setAppState(pre => ({ ...pre, tags: [...pre.tags, addTagResponse.data] }));
          return addTagResponse.data;
        } else {
          throw new Error('duplicate');
        }
      }
    } catch (err) {
      return err;
    }
  };


  const tagOnSearchQueryChange= (e, p) => {
    dispatch({
      type: ACTIONS.SET_TICKET,
      data: { TagSearchQuery: p.searchQuery }
    });
  }

  const selectTag = (e, p, key) => {
      // just to remove typescript error
      const stringifyTheValue = JSON.stringify(p.value);
      const parsedValue: any[] = JSON.parse(stringifyTheValue);
      let formattedNewTags = [];
      parsedValue.forEach(item => {
        const tagId = item['accessibilityitemprops'];
        formattedNewTags.push(tagId);
      });

      //update the list of tags
      let updatedTagList = [];
      formState.TagList.forEach(t => {
        updatedTagList.push({
          header: t.header,
          accessibilityitemprops: t.accessibilityitemprops,
          selected: formattedNewTags.includes(t.accessibilityitemprops)
        });
      });

      dispatch({
        type: ACTIONS.SET_TICKET,
        data: {
          TagList: updatedTagList,
          NewTags: formattedNewTags
        }
      });
    }

  const addNewTag = () => {
      dispatch({ type: ACTIONS.CREATING_TAG });
      createTag({
        Name: formState.TagSearchQuery
      }).then(response => {
        tagSearch.current.value = '';
        const newTag = {
          header: formState.TagSearchQuery,
          accessibilityitemprops: response.Id,
          selected: false
        };
        dispatch({
          type: ACTIONS.ADD_TAG_TO_LIST,
          data: newTag
        });
        dispatch({
          type: ACTIONS.CREATE_TAG_SUCCESS
        });
      });
    }

  const saveData = async (updatedTicket: any) => {
    const deletedTags = formState.OldTags.filter((x: any) => !formState.NewTags.includes(x));
    const refTags: number[] = formState.NewTags.filter((x: any) => !formState.OldTags.includes(x));
    const tagList = formState.TagList;
    const allTickets = [...currentState.tickets, ...currentState.closedTickets].sort((a, b) => (b.Id - a.Id));
    const updatedFormState = { ...formState, ...updatedTicket, OldTags: formState.NewTags, UpdatedTicket: {} };
    const updatedTickets = allTickets.map(t => t.Id === props.ticket.Id ? { ...t, ...updatedFormState } : t) as Ticket[];

    const deletedTagsName = [];
    tagList.forEach((t: any) => {
      if (deletedTags.includes(t['accessibilityitemprops']))
        deletedTagsName.push(t['header']);
    });

    let affectedUsers: any;
    let collaborators: any;
    const isCollaboratorsEnabled = await getCachedFeature(LICENSE_FEATURE.Collaborators);
    const isAffectedUsersEnabled = await getCachedFeature(LICENSE_FEATURE.AffectedUsers);

    if (updatedTicket.AffectedUsers) {
      affectedUsers = updatedTicket.AffectedUsers;
      delete updatedTicket.AffectedUsers;
    }
    if (updatedTicket.TicketCollaborators) {
      collaborators = updatedTicket.TicketCollaborators;
      delete updatedTicket.TicketCollaborators;
    }

    const _selectedTags = tagList.filter((x: any) => x.selected);
    updatedFormState.Tags = _selectedTags;

    try {
      const [_, selectedResolved] = checkIfResolved();
      if (isEnableResolutionNote && props.ResolutionNoteConfig.IsEnabled && isResolutionRole && selectedResolved){
        if ((updatedTicket?.Resolution?.DraftKB || props.ticket?.Resolution?.DraftKB) && CheckIfResolutionIsChanged(props.defaultTicket?.Resolution, updatedTicket?.Resolution)){
          let tempTicket = clone(props.ticket);
          tempTicket.Resolution = updatedTicket.Resolution;
           let kbId = await AddOrUpdateResolutionToDraftKB(tempTicket.Resolution, props.defaultTicket, currentState, t);
           if (props.defaultTicket?.Resolution?.AnswerId == null)
            updatedTicket.Resolution.AnswerId=kbId;
        }

        if (updatedTicket.Resolution==undefined){
          updatedTicket.Resolution = clone(formState.Resolution);
        }
      }
      
      await api.updateTicket(props.ticket.Id, updatedTicket);

      await Promise.all([
        (affectedUsers && isAffectedUsersEnabled) ? api.UpdateAffectedUsers(props.ticket.Id, affectedUsers.map(a => a.PlatformUserId)) : null, 
        (collaborators !== undefined && isCollaboratorsEnabled) ? api.UpdateCollaborators(props.ticket.Id, collaborators.map(a => a.PlatformUserId)) : null,
        (deletedTagsName.length > 0) ? api.removeRefTags(props.ticket.Id, deletedTagsName.join(',')) : null,
        (refTags.length > 0) ? api.udpateTicketTags(props.ticket.Id, refTags.join(',')) : null
      ].filter(o => o !== null));

      if (updatedFormState.Status.Guid == STATUS_GUID.RESOLVED && CheckIfResolutionIsChanged(props.defaultTicket, updatedTicket)){
        let ticketResolution = await api.getTicket(props.ticket.Id,`?$select=ResolutionId&$expand=Resolution`);
        updatedFormState.ResolutionId = ticketResolution.data.ResolutionId;
        updatedFormState.Resolution = ticketResolution.data.Resolution;
      }

      if (props.onInteraction) {
        props.onInteraction({ event: EditTicketEvent.SaveSuccess, data: { redirect: redirectAfterSave, updatedTicket: updatedFormState } });
      }


      dispatch({ type: ACTIONS.SET_TICKET, data: updatedFormState });
      
      parentDispatch({
        type: ACTIONS.SET_DEFAULT_TICKET,
        data:  updatedFormState
      });

      setAppState((prevState) => ({
        ...prevState,
        tickets: updatedTickets.filter((t: Ticket) => !t.Closed) || [],
        closedTickets: updatedTickets.filter((t: Ticket) => t.Closed) || []
      }));

      saveTicketInitialState(updatedFormState);
      setRedirectAfterSave(true);
    } catch (e) {
      console.error(e);
    }
  };

  // Change Routines
  const onChangeAssignee = (value: any, id: number, currentTemplate: any = null) => {
    const assigneeId = (id > 0) ? id : null;
    const supportGroupId = currentTemplate ? currentTemplate.SupportGroupId : formState.SupportGroupId;
    const teamId = currentTemplate ? currentTemplate.TeamId : formState.TeamId;

    const filteredUsers = (supportGroupId) ? currentState.supportgroups.find(x => x.Id == supportGroupId)?.Members ?? [] :
                          (teamId) ? currentState.teams.find(x => x.Id == teamId)?.Users || [] :
                          [...currentState.platformusers];

    const formattedProperty = { ...formatAssigneeProperty(filteredUsers, assigneeId) };
    updateFormState({ AssigneeId: assigneeId });
    dispatch({ type: ACTIONS.SET_PLATFORM_USERS, ...formattedProperty, AssigneeName: currentState.platformusers.find(x => x.Id == assigneeId)?.FullName });
  };

  const onChangeGroup = (value: any, id: number, updateAssignee: boolean, currentTemplate: any = null) => {
    if (value) {
      const selectedGroup = id;
      formState.SupportGroups = formState.SupportGroups.map((t: any)=> {
        t.selected = t['accessibilityitemprops']['id'] == selectedGroup;
        return t;
      });
      updateFormState({ SupportGroupId: selectedGroup });
      if (updateAssignee) {
        const formattedProperty = { ...formatAssigneeProperty(currentState.supportgroups.find(x => x.Id == id)?.Members, null) };
        updateFormState({ AssigneeId: currentTemplate?.AssigneeId ?? null });
        dispatch({ type: ACTIONS.SET_PLATFORM_USERS, ...formattedProperty });
      }
    } else {
      updateFormState({ SupportGroupId: null });
      formState.SupportGroups = formState.SupportGroups.map((t: any) => {
        if (t.selected) t.selected = false;
        return t;
      });
      dispatch({ type: ACTIONS.SET_SUPPORT_GROUPS, data: { SupportGroupName: '', SupportGroupMembers: [] } });
      if (updateAssignee) {
        const teamId = currentTemplate?.TeamId ?? formState.TeamId
        const filteredUsers = (!teamId) ? [...currentState.platformusers] : currentState.teams.find(x => x.Id == teamId)?.Users || [];
        updateFormState({ AssigneeId: currentTemplate?.AssigneeId ?? null });
        dispatch({ type: ACTIONS.SET_PLATFORM_USERS, ...formatAssigneeProperty(filteredUsers, null) });
      }
    }
  };

  const getFilterSupportGroups = (teamsAadObjectId) => {
    return (teamsAadObjectId) ? currentState.supportgroups.filter(x => x.TeamsAadObjectId == teamsAadObjectId) : [];
  }

  const onChangeTeam = (value: any, id: number, updateGroup: boolean, updateAssignee: boolean, currentTemplate: any = null) => {
    const extraReturn = [{ ReturnName: 'SupportGroupMembers', PropertyName: 'Members', DefaultValue: [] }];
    if (value != undefined) {
      const selectedTeam = id;
      formState.Teams = formState.Teams.map((t: any) => {
        t.selected = t['accessibilityitemprops']['id'] == selectedTeam;
        return t;
      });
      updateFormState({ TeamId: selectedTeam });

      let group = null;
      if (updateGroup) {
        const currentTeam = currentState.teams.find(x => x.Id == id);
        const filteredGroups = getFilterSupportGroups(currentTeam.TeamsAadObjectId);
        
        group = (filteredGroups.length > 0) ? filteredGroups.find(x => (currentTemplate && x.Id == currentTemplate.SupportGroupId) || (!currentTemplate && x.TeamsChannelId == currentTeam.TeamsChannelId)) : null;
        const formattedProperty = { ...formatProperty(filteredGroups, 'SupportGroups', 'SupportGroupName', group?.Id, 'Name', 'Id', ['Members'], extraReturn) };
        
        if (filteredGroups.length > 0) 
          onChangeGroup(group, group?.Id, true, currentTemplate);
        else 
          updateFormState({ SupportGroupId: currentTemplate?.SupportGroupId ?? null });
  
        dispatch({ type: ACTIONS.SET_SUPPORT_GROUPS, data: formattedProperty });        
      }
      if (updateAssignee) {
        const formattedProperty = {
          ...formatAssigneeProperty( group?.Members ?? currentState.teams.find(x => x.Id == id)?.Users ?? [], null)
        };
        updateFormState({ AssigneeId: currentTemplate?.AssigneeId ?? null });
        dispatch({ type: ACTIONS.SET_PLATFORM_USERS, ...formattedProperty });
      }
    } else {
      updateFormState({ TeamId: null });
      formState.Teams = formState.Teams.map((t: any) => {
        if (t.selected) t.selected = false;
        return t;
      });
      dispatch({ type: ACTIONS.SET_TEAMS, data: { TeamName: '', TeamsAadObjectId: ''} });
      if (updateGroup) {
        updateFormState({ SupportGroupId: currentTemplate?.SupportGroupId ?? null });
        dispatch({
          type: ACTIONS.SET_SUPPORT_GROUPS,
          data: formatProperty(currentState.supportgroups, 'SupportGroups', 'SupportGroupName', null, 'Name', 'Id', ['Members'], extraReturn)
        });
      }
      if (updateAssignee) {
        updateFormState({ AssigneeId: currentTemplate?.AssigneeId ?? null });
        dispatch({ type: ACTIONS.SET_PLATFORM_USERS, ...formatAssigneeProperty(currentState.platformusers, null) });
      }
    }
  };
  

  const onChangePicker = (
    value: any,
    id: number,
    listField: string,
    valueField: string,
    textField: string,
    textFieldObj: string = ''
  ) => {
    if (value != undefined) {
      formState[listField] = formState[listField].map((t: any) => {
        t.selected = t['accessibilityitemprops']['id'] == id;
        return t;
      });
      let data = {};
      data[valueField] = id;
      dispatch({ type: ACTIONS.UPDATE_TICKET, data: data });
      let ticketData = {};
      ticketData[valueField] = id;
      ticketData[textField] = value.Value;
      const selectedObject = formState[listField].find((s: any) => s.Id == id);
      ticketData[textFieldObj] = selectedObject;

      dispatch({ type: ACTIONS.SET_TICKET, data: ticketData });
    } else {
      let data = {};
      data[valueField] = null;
      dispatch({ type: ACTIONS.UPDATE_TICKET, data: data });
      formState[listField] = formState[listField].map((t: any) => {
        if (t.selected) t.selected = false;
        return t;
      });
      let ticketData = {};
      ticketData[valueField] = null;
      ticketData[textField] = '';
      ticketData[textFieldObj] = null;
      dispatch({ type: ACTIONS.SET_TICKET, data: ticketData });
    }
  };
  
  const onChangeRequester = async (e: any) => {
    if (e.detail.length === 0) return;
    setIsFormUpdated(false);
    const { displayName, givenName, surname, userPrincipalName, scoredEmailAddresses, id } = e.detail[0];
    const payload = {
      AadObjectId: id,
      FullName: displayName,
      GivenName: givenName,
      FamilyName: surname,
      Email: scoredEmailAddresses?.[0]?.address,
      UserName: userPrincipalName
    };
    const { Id, FullName, Email, AadObjectId, UserName, GivenName, FamilyName } = await api.getOrCreatePlatformUser(payload);
    dispatch({ type: ACTIONS.UPDATE_REQUESTER, data: { RequesterId: Id, Requester: { FullName, Email, AadObjectId, UserName, GivenName, FamilyName } } });
    updateFormState({ RequesterId: Id });
  };

  const onChangeCollaborators = (_e, p) => {
    const stringifyTheValue = JSON.stringify(p.value);
    const parsedValue: any[] = JSON.parse(stringifyTheValue);
    let selectedCollaboratorList = [];
    parsedValue.forEach(item => {
      const collaboratorId = item['accessibilityitemprops'];
      selectedCollaboratorList.push(collaboratorId);
    });

    //update the list of Collaborator
    const updatedCollaboratorList = [];
    currentState.usersInRole.forEach(obj => {
      if (selectedCollaboratorList.includes(obj.Id)) 
        updatedCollaboratorList.push({ PlatformUserId: obj.Id });
    });
    updateFormState({ TicketCollaborators: updatedCollaboratorList });
  }

  const onChangeAffectedUsers = async (e: any) => {
    if (e.detail.length === 0) {
      updateFormState({ AffectedUsers: [] });
      return;
    }
    setIsFormUpdated(false);

    let list = [];
    e.detail.map((item: any) => {
      const { id, displayName, givenName, surname, userPrincipalName, scoredEmailAddresses } = item;
      const payload = {
        FullName: displayName,
        GivenName: givenName,
        FamilyName: surname,
        Email: scoredEmailAddresses?.[0]?.address,
        UserName: userPrincipalName, 
        AadObjectId: id,
      };
      list.push(payload);
    });

    const userList = [];
    for (let u of list)
      userList.push({ PlatformUserId: currentState.platformusers.find(i => (i.AadObjectId && u.AadObjectId && i.AadObjectId == u.AadObjectId) || i.UserName == u.UserName)?.Id ?? (await api.getOrCreatePlatformUser(u)).Id });

    updateFormState({ AffectedUsers: userList });
  };

  // Template Routines
  const closeApplyTemplate = () => {
    if (props.onInteraction)
      props.onInteraction({ event: EditTicketEvent.TemplateApplied });
  };

  const closeApplyLifecycle = (savedState?: any) => {
    setOpenApplyLifecycle(false);
    if (props.onInteraction)
      props.onInteraction({ event: 'onApplyLifecycleSuccess' });
      
    if(savedState.hasOwnProperty("IsSuccess")){
      if(savedState["IsSuccess"]){
        applyLifecycleToTicketSuccess();
        toast.success(`Lifecycle applied to ticket successfully`, toastDefault);
      } else 
        toast.error(`Error while applying lifecycle to ticket`, toastDefault);
    }
  };

  const applyLifecycleToTicketSuccess = async function(){

    await getAllTicketLifecycle();
  }

  const getDueDateValue = (currentTemplate: any) => {
    let dueDateValue = currentTemplate.DueDate;
    if(!dueDateValue && currentTemplate.RelativeDueDate?.length > 0)
      dueDateValue = getRelativeDateValue(currentTemplate.RelativeDueDate).toISO();

    return dueDateValue;
  }

  const applyTemplate = async() => {
    const currentTemplate = formState.SelectedTemplate;
    delete formState.SelectedTemplate['hidden'];
    const filteredTeam = formState.Teams.find((s: any) => s.Id == currentTemplate.TeamId);
    const filteredGroups = getFilterSupportGroups(filteredTeam?.TeamsAadObjectId);
    let data = {
      TemplateId: currentTemplate.Id,
      Template: templates.find((s: any) => s.Id == currentTemplate.Id),
      AssigneeId: currentTemplate.AssigneeId,
      Assignee: formState.PlatformUsers.find((s: any) => s.Id == currentTemplate.AssigneeId),
      StatusId: currentTemplate.StatusId,
      Status: formState.Statuses.find((s: any) => s.Id == currentTemplate.StatusId),
      PriorityId: currentTemplate.PriorityId,
      Priority: formState.Priorities.find((s: any) => s.Id == currentTemplate.PriorityId),
      CategoryId: currentTemplate.CategoryId,
      Category: formState.Categories.find((s: any) => s.Id == currentTemplate.CategoryId),
      TicketTypeId: currentTemplate.TicketTypeId,
      TicketType: formState.TicketTypes.find((s: any) => s.Id == currentTemplate.TicketTypeId),
      SupportGroupId: currentTemplate.SupportGroupId,
      SupportGroup: filteredGroups.find((s: any) => s.Id == currentTemplate.SupportGroupId),
      TeamId: currentTemplate.TeamId,
      Team: filteredTeam,
      DueDate: getDueDateValue(currentTemplate),
      Tags:[],
      ResolutionCategory: formState.ResolutionCategories.find((s: any) => s.IsDefault)
    };

    if (data.TemplateId) {
      formState.Template = templates.map((t: any) => ({ ...t, selected: t.Id === data.TemplateId }));
      dispatch({ type: ACTIONS.UPDATE_TICKET, data: { TemplateId: data.TemplateId || 0, Template: data.Template } });
    } else {
      formState.Template = formState.Template.map((t: any) => ({ ...t, selected: false }));
      dispatch({ type: ACTIONS.UPDATE_TICKET, data: { TemplateId: null, Template: null } });
    }

    //This will update the tags
    if (currentTemplate.Tags!=null){
      let newTags = [];
      formState.TagList.forEach(x=>{x.selected=false;});
      currentTemplate.Tags.forEach(item => {
        newTags.push(item.Id);
        let tag = formState.TagList.find(x=>x.accessibilityitemprops==item.Id);
        tag.selected = true;
      });

      formState.NewTags = newTags;
      formState.Tags = currentTemplate.Tags;

      const tagData = { Tags: currentTemplate.Tags, NewTags: newTags};
      dispatch({ type: ACTIONS.UPDATE_TICKET, data: tagData });
      data = Object.assign(data, tagData);

      updateFormState(tagData);
    }

    if (currentTemplate.AffectedUsers) {
      let userList = [...formState.AffectedUsers];

      if (currentTemplate.AffectedUsers.length > 0){
        currentTemplate.AffectedUsers.forEach(a => {
          if (!userList.some(p => p.PlatformUserId == a.Id))
            userList.push({ PlatformUserId: a.Id });
        }); 
      }
      updateFormState({ AffectedUsers: userList });
    }

    if (currentTemplate.Collaborators) {
      let userList = [...formState.TicketCollaborators];
      
      if (currentTemplate.Collaborators.length > 0){
        currentTemplate.Collaborators.forEach(a => {
          if (!userList.some(p => p.PlatformUserId == a.Id))
            userList.push({ PlatformUserId: a.Id });
        }); 
      }
      updateFormState({ TicketCollaborators: userList });
    }
    
    if (currentTemplate.DueDate || currentTemplate.RelativeDueDate){
      const dueDate = { DueDate: getDueDateValue(currentTemplate)};
      dispatch({ type: ACTIONS.UPDATE_TICKET, data: dueDate });
    }

    if (currentTemplate.StatusId) onChangePicker(data.Status, data.StatusId || 0, 'Statuses', 'StatusId', 'StatusName', 'Status');
    if (currentTemplate.PriorityId) onChangePicker( data.Priority, data.PriorityId || 0, 'Priorities', 'PriorityId', 'PriorityName', 'Priority');
    if (currentTemplate.UnsetCategory || currentTemplate.CategoryId) onChangePicker(data.Category, data.CategoryId || 0, 'Categories', 'CategoryId', 'CategoryName', 'Category');
    if (currentTemplate.UnsetTicketType || currentTemplate.TicketTypeId) onChangePicker(data.TicketType, data.TicketTypeId || 0, 'TicketTypes', 'TicketTypeId', 'TicketTypeName', 'TicketType');

    if (currentTemplate.UnsetTeam || currentTemplate.TeamId) onChangeTeam(data.Team, data.TeamId, true, true, currentTemplate);
    if (currentTemplate.UnsetSupportGroup || currentTemplate.SupportGroupId) onChangeGroup(data.SupportGroup, data.SupportGroupId, true, currentTemplate);
    if (currentTemplate.UnsetAssignee || currentTemplate.AssigneeId) 
      onChangeAssignee(data.Assignee, data.AssigneeId || 0, currentTemplate);
    else {
      delete data.Assignee;
      delete data.AssigneeId;
    }

    let lifecycleAppliedId = 0;
    if(currentTemplate.LifecycleId){
      setIsApplyingTemplateWithLifecycle(true);
      lifecycleAppliedId = await sendRequestToApplyLifecycle(currentTemplate.LifecycleId, props.ticket.Id);
      currentTemplate.Lifecycle.Phases.map(x => {
        x["IsCurrent"] = x.Order == 0;
      });
      if(lifecycleAppliedId > 0){
        //set temp data for display on the fly
        const extraData = {
          TicketLifecycle: {...currentTemplate.Lifecycle, Id: lifecycleAppliedId},
          TicketLifecycleId: lifecycleAppliedId
        }
        extraData.TicketLifecycle.Lifecycle = currentTemplate.Lifecycle;
        extraData.TicketLifecycle.Status = {
          Guid: LIFECYCLE_STATUS_GUID.IN_PROGRESS,
          Value: "In Progress"
        }
        dispatch({ type: ACTIONS.UPDATE_TICKET, data: extraData });
        data = Object.assign(data, extraData);
      }
    } else if (!currentTemplate.LifecycleId && formState.TicketLifecycleId) {
      setIsApplyingTemplateWithLifecycle(true);
      setReloadTaskList(true);
      setReloadApprovals(true);
      await api.deleteTicketLifecycle(formState.TicketLifecycleId);

      const extraData = {
        TicketLifecycle: null,
        TicketLifecycleId: null,
        TicketTasks: formState.TicketTasks?.filter(x => x.TicketLifecyclePhaseId == null) ?? [],
        Approvals: formState.Approvals?.filter(x => x.TicketLifecyclePhaseId == null) ?? []
      }
      dispatch({ type: ACTIONS.UPDATE_TICKET, data: extraData });
      data = Object.assign(data, extraData);
    }

    const extraData = {
      CardTemplateJson: currentTemplate.CardResponseJson,
      CardAnswerJson: currentTemplate.CardAnswerJson
    };
    dispatch({ type: ACTIONS.UPDATE_TICKET, data: extraData });
    data = Object.assign(data, extraData);

    parentDispatch({
      type: ACTIONS.UPDATE_TICKET,
      data: Object.assign(formState, data)
    });
    setRedirectAfterSave(false);
    parentDispatch({ type: 'saving-state' });

    if(lifecycleAppliedId > 0)
      setReloadTaskList(true);

    closeApplyTemplate();

    if (!currentTemplate.NoCustomForm && currentTemplate.ClearFormAnswer)
      sendCustomForm(formState.Id, currentTemplate.Id);

  };

  const sendCustomForm = async (TicketId: number, TemplateId: number) => {
    try {
      await api.sendCustomFormToConversation({ TicketId, TemplateId, IsShowTyping: true });
    } catch (e) {
      console.error(e);
    }
  };

  const getRelatedTickets = async () => {
    const relatedTicketsList = await api.getRelatedTickets(props.ticket.Id);
    setRelatedTicketsList(relatedTicketsList);
  };

  const setRelatedTicketsList = (tickets: any) => {
    try {
      
      let relatedTicketsList: IRelatedTicketsCard[] = [];
      tickets?.forEach((ticket: any) => {
        let isCurrentTicket = props.ticket.Id == ticket.RelatedTicket.Id;
        relatedTicketsList.push({
          RelationshipTypeId: ticket.RelationshipTypeId,
          CreatedById: ticket.CreatedById,
          CreatedDate: ticket.CreatedDate,
          Id: isCurrentTicket ? ticket.SourceTicket.Id : ticket.RelatedTicket.Id,
          Title: isCurrentTicket ? ticket.SourceTicket.Title : ticket.RelatedTicket.Title,
          StatusId: isCurrentTicket ? ticket.SourceTicket.StatusId : ticket.RelatedTicket.StatusId,
        });
      });
      setRelatedTickets(relatedTicketsList);
      dispatch({ type: ACTIONS.RELATED_TICKETS_LOAD_SUCCESS });
    } catch (e) {
      console.error(e);
    }
  };

  const onRelateSuccess = async () => {
    const results = await Promise.all([
      api.getRelatedTickets(props.ticket.Id),
      api.getTicketFiles(props.ticket.Id),
      api.getAllMergedTickets(),
      api.getTicket(props.ticket.Id, FILTERS_STRINGS.TICKET_DETAILS)
    ]);
    setRelatedTicketsList(results[0]);
    setFileAttachments(results[1]);
    dispatch({ type: ACTIONS.SET_TICKET, data: results[3].data });
    const mergedTickets: IRelatedTickets[] = getResponseValue(results[2]);
    props.onInteraction({ event: EditTicketEvent.TicketsRelated, data: mergedTickets });
  };

  const getTicketApprovals = async () => {
    try {
      let approvals: TicketApprovals[] = [];
      approvals = await api.getTicketApprovals(props.ticket.Id);
      setTicketApprovals(approvals);
      const hasPendingApprovals: boolean = approvals.some((item: any) => item.Approval.ApprovalState == TicketApprovalStateString.Pending);
      setAppState(prevState => ({
        ...prevState,
        lifecycleApprovals: approvals,
        hasPendingApprovals: hasPendingApprovals
      }));
      dispatch({ type: ACTIONS.TICKET_APPROVALS_LOAD_SUCCESS });
      setReloadApprovals(true);
    } catch(e) {
      console.error(e);
    }
  }

  const updateApprovalStatus = async (approval: Approval, approverId: number, status: TicketApprovalState) => {
    try {
      dispatch({ type: ACTIONS.TICKET_APPROVALS_LOADING });
      const result = await updateApproval(api, approval, approverId, status, t);
      result.IsError ? toast.error(result.Message, toastDefault) : toast.success(result.Message, toastDefault);
      await getTicketApprovals();
    } catch (e) {
      toast.error(t('ticket-details.edit-ticket-form.approval.error.update'), toastDefault);
      getTicketApprovals();
      console.error(e);
    }
  }

  const addTicketApproval = async (data: Approval) => {
    try {
      dispatch({ type: ACTIONS.TICKET_APPROVALS_LOADING });
      if (approversList != null) {
        const results = await Promise.all(approversList.map(async user => api.getOrCreatePlatformUser(user)))
        await Promise.all(approversList.map(async user => refershPlatformUserState(user, currentState, setAppState)));

        data.ApprovalState = TicketApprovalState.Pending;
        results.forEach(result => {
          data.Approvers.push({
            ApprovalId: data.Id,
            ApproverId: result.Id,
            HasApproved: 3,
            Id: 0
          });
        });
      }

      data.TicketApprovals.push({ Id: 0, TicketId: formState.Id, ApprovalId: data.Id })
      let savedApproval = await api.addTicketApproval(data);
      if (savedApproval != null) {
        await getTicketApprovals();
        toast.success(t('ticket-details.edit-ticket-form.approval.success.created'), toastDefault);
      }
      setApproversList([]);
      data.Approvers = [];
      data.TicketApprovals = [];
      data.Title= "";
      data.AdditionalDetails="";
      data.IsRequiredByAll=false;
    } catch (e) {
      console.error(e);
      toast.error(t('ticket-details.edit-ticket-form.approval.error.create'), toastDefault);
      getTicketApprovals();
      setApproversList([]);
      data.Approvers = [];
      data.TicketApprovals = [];
      data.Title= "";
      data.AdditionalDetails="";
      data.IsRequiredByAll=false;
    }
  }

  const updateTicketApproval = async (approval: Approval) => {
    try {
      dispatch({ type: ACTIONS.TICKET_APPROVALS_LOADING });

      const currentApproval = await api.getApproval(approval.Id);
      const isUpdateAllowed = currentApproval[0].ApprovalState === TicketApprovalState.Pending;

      if(!isUpdateAllowed){
        const errorMessage = currentApproval[0].ApprovalState === TicketApprovalState.Approved ? 
        t('ticket-details.edit-ticket-form.approval.error.update-already-approved') :
        t('ticket-details.edit-ticket-form.approval.error.update-already-rejected')

        toast.error(errorMessage, toastDefault);
        await getTicketApprovals();
        return;
      }

      const approvalData = {
        AdditionalDetails: approval.AdditionalDetails,
        Title: approval.Title,
        IsRequiredByAll: approval.IsRequiredByAll
      };

      let approverIds = [];
      if (approversList != null) {
        const results = await Promise.all(approversList.map(async user => api.getOrCreatePlatformUser(user)))
         approverIds = results.map(item => item.Id);
      }

      let savedApproval = await api.updateTicketApprovalApprovers(approval.Id, approverIds, JSON.stringify(approvalData));
      if (savedApproval != null) {
        await getTicketApprovals();
        toast.success(t('ticket-details.edit-ticket-form.approval.success.updated'), toastDefault);
      }
    } catch (e) {
      console.error(e);
      toast.error(t('ticket-details.edit-ticket-form.approval.error.update'), toastDefault);
      await getTicketApprovals();
      setApproversList([]);
    }
  }

  const relateTickets = async (data: IRelateTickets) => {
    try {
      dispatch({ type: ACTIONS.RELATED_TICKETS_LOADING });
      let result = await api.relateTickets(data);
      if (result.data.value) {
        onRelateSuccess();
        toast.success(t('ticket-details.edit-ticket-form.relate-ticket.success.relate'), toastDefault);
      } else {
        toast.error(t('ticket-details.edit-ticket-form.relate-ticket.error.relate'), toastDefault);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getAllTicketLifecycle = async function(){
    try {
      const {data} = await api.getTicket(props.ticket.Id, '?$select=TicketLifecycle&$expand=TicketLifecycle($expand=Phases($orderby=Order))');

      props.onInteraction({ event: 'onApplyLifecycleToTicket', data: data.TicketLifecycle });
    } catch (e) {
      console.error(e);
    }
  }

  const updateFileAttachmentsData = async () => {
    if (!reloadAttachmentData) return;
    
    setIsAttachmentAccordionOpen(false);
    try {
       //update this property to mark move has started
      const thisFiles = fileAttachments.map(x => {x.MoveItemFailed = true; return x;});
      setFileAttachments(thisFiles);
    } catch (e) {
      console.error(e);
    }
    setReloadAttachmentData(false);
  }

  const onSaveSubmit = () => {
    const closeStatusId = getStatusIdByGuid(currentState.ticketStatus, STATUS_GUID.CLOSED);
    const [_,isSelectedStatusResolved] = checkIfResolved();
    
    let isClosed = formState.UpdatedTicket?.StatusId === closeStatusId;
    const lifecycleIsCompleted= (formState.TicketLifecycle?.Status ?? false) && 
      [LIFECYCLE_STATUS_GUID.FAILED, LIFECYCLE_STATUS_GUID.COMPLETED].includes(formState.TicketLifecycle.Status.Guid);      
    if (!lifecycleIsCompleted && (currentState.hasActiveTasks || currentState.hasPendingApprovals) && (isClosed || isSelectedStatusResolved)) {
      setShowTaskWarningDialog(true);
      const listTask = listOfOpenTask(currentState.taskStatus, currentState.ticketTask, TASK_STATUS_GUID.COMPLETED, TASK_STATUS_GUID.FAILED);
      setOpenTaskList(listTask);
      const penddingApprovalsList = listOfPenddingApprovals(TicketApprovalState.Pending, ticketApprovals);
      setPenddingTicketApprovals(penddingApprovalsList);
      
    } else if(isEnableResolutionNote && props.ResolutionNoteConfig.IsEnabled && isSelectedStatusResolved) {
      showResolveDialogPopup();
    } else {
      onConfirmModal();
      
    }

  }

  const checkIfResolved = () => {
    const resolvedStatusId = getStatusIdByGuid(currentState.ticketStatus, STATUS_GUID.RESOLVED);
    let isStatusResolved = resolvedStatusId == formState.StatusId;
    let isSelectedStatusResolved = (formState.UpdatedTicket?.StatusId!=undefined && (isStatusResolved || formState.UpdatedTicket?.StatusId === resolvedStatusId));
    return [isStatusResolved, isSelectedStatusResolved];
  }

  const confirmResolve = () => {
    const [_ ,isSelectedStatusResolved] = checkIfResolved();
    if(isEnableResolutionNote && props.ResolutionNoteConfig.IsEnabled && isSelectedStatusResolved) {
      onCloseConfirmModal();
      showResolveDialogPopup();
    }
    else onConfirmModal();
  }


  

  const showResolveDialogPopup = () => {
    setShowResolvedDialog(true);
    
    if (props.ResolutionNoteConfig?.RequireCategory){
      if (formState?.Resolution && formState?.Resolution.ResolutionCategoryId==undefined){
        formState.Resolution.ResolutionCategory = null;
        formState.Resolution.ResolutionCategoryId=null;
      }
      
    }
    else if (formState?.Resolution && formState?.Resolution.ResolutionCategory==null){
      
      let defaultCat = formState.ResolutionCategories.find((s: any) => s.IsDefault);

      formState.Resolution.ResolutionCategory = defaultCat;
      formState.Resolution.ResolutionCategoryId=defaultCat.Id;

    }
  }

  const onCloseConfirmModal = () => {
    setShowTaskWarningDialog(false);
  }

  const onConfirmModal = () => {
    onCloseConfirmModal();
    if (props.onToolbarInteraction) {
      props.onToolbarInteraction({ event: 'saveTicket' });
    }
  }

  const getTicketTasks = async (ticketId: number, appliedTicketLifecycle?: TicketLifecycle) => {
    try {
      dispatch({type: ACTIONS.TICKET_TASKS_LOADING});
      let params: string = `?$filter=TicketId eq ${ticketId}&$expand=Status,Assignee,TicketLifecyclePhase,Team,SupportGroup,TaskCollaborators`;
      const isTicketTaskEnabled = await getCachedFeature(LICENSE_FEATURE.TicketTasks)
      const list = isTicketTaskEnabled ? await api.getTicketTasks(params) : [];
      const tasksList: TicketTask[] = list;
      const hasActiveTasks: boolean = tasksList.some((item: any) => item.Status.Guid !== TASK_STATUS_GUID.COMPLETED && item.Status.Guid !== TASK_STATUS_GUID.FAILED);
      setAppState(prevState => ({
        ...prevState,
        ticketTask: tasksList,
        hasActiveTasks: hasActiveTasks,
        isLoading: true
      }));
      setTicketTasks(tasksList);
      dispatch({ type: ACTIONS.TICKET_TASKS_LOAD_SUCCESS });
      setReloadTaskList(false);
    } catch(e) {
      console.error(e);
    }
  }

  const setIsLoadingTasks = (loading:boolean) =>{
    if (loading){
     dispatch({type:ACTIONS.TICKET_TASKS_LOADING})
    }
    else {
      dispatch({type:ACTIONS.TICKET_TASKS_LOAD_SUCCESS})
    }
  }
  // Effects
  useEffect(() => {
    props.SetdetailCardApprovalsList(ticketApprovals);
  }, [ticketApprovals]);
  
  useEffect(()=>{
    props.setDetailCardTaskList(ticketTasks);
  }, [ticketTasks]);

  useEffect(() => {
    loadPage();
  }, []);

  useEffect(() => {
    if (formState.PlatformUsers && formState.PlatformUsers.length > 0) {
      const selectedAssignee = formState.PlatformUsers.find((o: any) => o.Id == props.ticket.AssigneeId);
      onChangeAssignee(selectedAssignee, props.ticket.AssigneeId);
      saveTicketInitialState(props.ticket);
    }

    if (formState.Status && !props.applyTemplateState) {
      dispatch({ type: ACTIONS.SET_TICKET, data: { ...props.ticket, UpdatedTicket: {} } });
    }
  }, [props.ticket]);

  useEffect(() => {
    if (props.resetUpdateTicketState) {
      dispatch({ type: ACTIONS.SET_TICKET, data: { ...props.ticket, UpdatedTicket: {} } });
    }
  }, [props.resetUpdateTicketState]);

  useEffect(() => {
    getRelatedTickets();
    //getting ticket tasks and approvals irrespective of lifecycle applied as we need their count on ticket details card
    getTicketApprovals();
    getTicketTasks(props.ticket.Id, props.ticket?.TicketLifecycle);
  }, [props.ticket.Id]);

  useEffect(() => {
    if (props.savingState && !props.applyTemplateState)
      saveData(formState.UpdatedTicket);
    else
      updateFileAttachmentsData();

    if (props.unassigningState) {
      setTicketInitialState({ ...ticketInitialState, AssigneeId: null });
      onChangeAssignee(null, 0);
      if (props.onInteraction) props.onInteraction({ event: EditTicketEvent.TicketUnassigned });
    }
    if (props.takingState) {
      setTicketInitialState({ ...ticketInitialState, AssigneeId: props.signedInUser.Id });
      onChangeAssignee(props.signedInUser, props.signedInUser.Id);
      if (props.onInteraction) props.onInteraction({ event: EditTicketEvent.TicketTaken });
    }
    if (props.applyTemplateState) {
      formState.SelectedTemplate = {};
      setTemplatePage(0);
      setOpenApplyTemplate(true);
      dispatch({type:ACTIONS.TEMPLATE_HAS_ERROR, data:false});
      dispatch({type:ACTIONS.TEMPLATE_ERROR_MESSAGE, data:""});
    }else{
      setTemplatePage(1);
      setOpenApplyTemplate(false);
      setIsApplyingTemplateWithLifecycle(false);
      
      if(reloadTaskList)
        getTicketTasks(props.ticket.Id, props.ticket?.TicketLifecycle);

      if (reloadApprovals)
        getTicketApprovals();
    }
    if (props.applyLifecycleState) {
      setOpenApplyLifecycle(true);
    }
  }, [props.savingState, props.takingState, props.unassigningState, props.applyTemplateState]);

  useEffect(() => {
    if (ticketInitialState) {

      if(formState.Status?.Id != formState.StatusId)
        formState.Status = getCurrentPickerItemObject("Statuses", "StatusId");
       if(formState.Category?.Id != formState.CategoryId)
         formState.Category = getCurrentPickerItemObject("Categories", "CategoryId");
      if(formState.Priority?.Id != formState.PriorityId)
        formState.Priority = getCurrentPickerItemObject("Priorities", "PriorityId");
      if(formState.TicketType?.Id != formState.TicketTypeId)
        formState.TicketType = getCurrentPickerItemObject("TicketTypes", "TicketTypeId");
      if(formState.Assignee?.Id !=formState.AssigneeId)
        formState.Assignee = formState.PlatformUsers.find((s: any) => s.Id == formState.AssigneeId);
      if(formState.SupportGroup?.Id !=formState.SupportGroupId)
        formState.SupportGroup = formState.SupportGroups.find((s: any) => s.Id == formState.SupportGroupId);
      if(formState.Team?.Id !=formState.TeamId)
        formState.Team = formState.Teams.find((s: any) => s.Id == formState.TeamId);

      if(formState.ResolutionCategory?.Id !=formState.ResolutionCategoryId)
        formState.ResolutionCategory = formState.ResolutionCategories.find((s: any) => s.Id == formState.ResolutionCategoryId);

      setIsFormUpdated(checkIfFormUpdated());
    }
  }, [formState, ticketInitialState]);
  
  useEffect(() => {
    const collaboratorFormatedList: any[] = collaboratorsFormat(
      currentState.usersInRole,
      formState?.TicketCollaborators.map(o => currentState.platformusers.find(i => i.Id == o.PlatformUserId))
    );
    setCollaboratorList(collaboratorFormatedList);
  }, [formState?.TicketCollaborators, currentState.usersInRole]);

  useEffect(() => {
    setFormDirty(isFormUpdated);
  }, [isFormUpdated]);

  const parseDate = (date: string) => date === undefined ? date : Date.parse(date);

  const checkIfFormUpdated = () => {
    if (isMergedOrDeflected) return false;
    let isDirty = false;
    let propertiesList = Object.keys(ticketInitialState);
    
    for (let property of propertiesList) {
      let updatedPropertyValue: any;
      let initialPropetyValue: any;
      if (property === 'Tags') {
        updatedPropertyValue = formState.NewTags.sort().join('~');
        initialPropetyValue = formState.OldTags.sort().join('~');
      } else if (property === 'TicketCollaborators') {
        updatedPropertyValue = formState.TicketCollaborators?.map((obj: any) => obj.PlatformUserId).sort((a, b) => (a - b)).join('~');
        initialPropetyValue = ticketInitialState[property]?.map((obj: any) => obj.PlatformUserId).sort((a, b) => (a - b)).join('~');
      } else if (property === 'AffectedUsers') {
        updatedPropertyValue = formState.AffectedUsers?.map((obj: any) => obj.PlatformUserId).sort((a, b) => (a - b)).join('~');
        initialPropetyValue = ticketInitialState[property]?.map((obj: any) => obj.PlatformUserId).sort((a, b) => (a - b)).join('~');
      } else {
        updatedPropertyValue = formState.UpdatedTicket[property];
        initialPropetyValue = ticketInitialState[property];
        if (property.includes('Date')) {
          updatedPropertyValue = parseDate(updatedPropertyValue);
          initialPropetyValue = parseDate(initialPropetyValue);
        }
      }

      if (property === 'TeamId') setReloadAttachmentData(true);

      if (updatedPropertyValue !== undefined && updatedPropertyValue?.toString() !== initialPropetyValue?.toString()) {
        isDirty = true;
        break;
      }
    }
    return isDirty;
  };

  const updateFormState = (updatedObject: any) => {
    dispatch({ type: ACTIONS.UPDATE_TICKET, data: updatedObject });
    dispatch({ type: ACTIONS.SET_TICKET, data: updatedObject });
  };

  const formatAssigneeProperty = (items: any[], selectedValue: number | null) => {
    return formatProperty(
      filterPlatformUsersInRole(items, currentState.usersInRole),
      'data',
      'AssigneeName',
      selectedValue,
      'FullName',
      "Id"
    );
  }

  const getCurrentPickerItemObject = (selectionField: string, valueField: string) => {
    const find = formState[selectionField].find(t => t.Id == formState[valueField]);
    return find;
  }

  const getTemplatePageCountDialog = () => {
    if (formState.SelectedTemplate.NoCustomForm){ //this is when custom form is not used
      formState.SelectedTemplate.ClearFormAnswer=false;
      return 1;
    }
    else //This will use the custom form
      return 2;
  }

  const templateButtonText = (formStateSelectedTemplate): string => {
    return formStateSelectedTemplate && formStateSelectedTemplate.SelectedTemplate.ClearFormAnswer == false ? t('ticket-details.apply-template.dialog.button.save') : t('ticket-details.apply-template.dialog.button.save-send')
  }
  const [currentCountFiles, setCurrentCountFiles] = useState<number>(0);

  useEffect(() => {
    setCurrentCountFiles(currentState.uploadingState.acceptedFiles.length);
    if(currentState.uploadingState.acceptedFiles.length > currentCountFiles) {
      setIsAttachmentAccordionOpen(true);
    }
  }, [currentState.uploadingState.acceptedFiles])


  // JSX
  return (
    <>
      <FluentUIThemeConsumer render={globalTheme => (
          <>
            <MemoizedRequesterCard Requester={props.ticket.Requester as PlatformUser} ticketId={props.ticket.Id} ticketSubject={props.ticket.Title} ccAddress={formState.Team?.EmailAddress ?? ""} />

            <PropertiesAccordion
              isPropertiesAccordionOpen={isPropertiesAccordionOpen}
              setIsPropertiesAccordionOpen={setIsPropertiesAccordionOpen}
              primaryButtonColor={ globalTheme.siteVariables.colorScheme.brand.foreground1 }
              isSaving={props.savingState}
              onToolbarInteraction={props.onToolbarInteraction}
              onSaveSubmit={onSaveSubmit}
              isFormUpdated={isFormUpdated}
              isUserLicensed={props.isUserLicensed}
              isMergedOrDeflected={isMergedOrDeflected}
              isRequestFieldEmpty={showRequestFiledError || showTeamsRequiredError}
            />

            {isPropertiesAccordionOpen && !formState.LoadingTicketData && (
              <Flex column gap="gap.small" className={`pt-5`}>
                <div>
                  <LabelElement label={t('ticket-details.edit-ticket-form.properties.request')} required={!isFormDisabled}>
                    <TextArea
                      disabled={isFormDisabled}
                      styles={{ height: '60px' }}
                      fluid
                      maxLength={1000}
                      required
                      resize="vertical"
                      defaultValue={formState?.Title}
                      onChange={(event, p) => {
                        updateFormState({ Title: p.value });
                        if (p.value !== '') {
                          setShowRequestFieldError(false);
                        } else {
                          setShowRequestFieldError(true);
                        }
                      }}
                    />
                  </LabelElement>
                  {showRequestFiledError && <div className={`text-red-700`}>{t('ticket-details.edit-ticket-form.properties.error.request-field')}</div>}
                </div>
                <div>
                  <Text content={t('ticket.add-ticket.requester')} />
                  <PeoplePicker
                    id='mgt-requester'
                    userType={UserType.user}
                    type={PersonType.person}
                    disabled={isFormDisabled}
                    selectionMode="single"
                    selectionChanged={onChangeRequester}
                  />
                </div>

                {affectedUsersEnabled &&
                <div>
                  <Text content={t('ticket.ticket-list.affected-users')} />
                  <PeoplePicker
                    id='mgt-affected'
                    disabled={isFormDisabled}
                    userType={UserType.user} 
                    type={PersonType.person}
                    selectionChanged={onChangeAffectedUsers}
                  />
                </div>}
                
                <div>
                  <NestedDropdown
                    tabIndex={0}
                    disabled={isMergedOrDeflected}
                    label={t('ticket.add-ticket.status')}
                    items={formState.Statuses.filter((item: any) => {
                      const initialCondition = item.Id != getStatusIdByGuid(currentState.ticketStatus, STATUS_GUID.DEFLECTED);
                      const lifecycleIsActive = (formState.TicketLifecycle?.Status ?? false) && 
                        [LIFECYCLE_STATUS_GUID.FAILED, LIFECYCLE_STATUS_GUID.COMPLETED].indexOf(formState.TicketLifecycle.Status.Guid) < 0;
                      const lifecycleCondition = isLifecycleEnabled && lifecycleIsActive && 
                        (item.Id == getStatusIdByGuid(currentState.ticketStatus, STATUS_GUID.CLOSED) ||
                        item.Id == getStatusIdByGuid(currentState.ticketStatus, STATUS_GUID.RESOLVED));  
                      return initialCondition && !lifecycleCondition;
                    }).map((item: any) => ({
                      id: item.Id,
                      parentId: item.ParentId,
                      position: item.Position,
                      value: item.Value,
                      ...item
                    }))}
                    dataItem={{
                      id: formState.Status?.Id,
                      parentId: formState.Status?.ParentId,
                      position: formState.Status?.Position,
                      value: formState.Status?.Value,
                      ...formState.Status
                    }}
                    defaultValue={formState.StatusName}
                    onChange={value => {
                      onChangePicker(
                        value,
                        value?.id ? value.id : 0,
                        'Statuses',
                        'StatusId',
                        'StatusName',
                        'Status'
                      );
                    }}
                  />
                </div>
                <CheckFeature featureName={LICENSE_FEATURE.TicketTypes}>
                  <div>
                    <NestedDropdown
                      tabIndex={0}
                      showClearBtn={formState.TicketTypeName ? true : false}
                      disabled={isFormDisabled}
                      label={t('ticket.add-ticket.type')}
                      items={formState.TicketTypes.map(item => ({
                        id: item.Id,
                        parentId: item.ParentId,
                        position: item.Position,
                        value: item.Value,
                        ...item
                      }))}
                      dataItem={{
                        id: formState.TicketType?.Id,
                        parentId: formState.TicketType?.ParentId,
                        position: formState.TicketType?.Position,
                        value: formState.TicketType?.Value,
                        ...formState.TicketType
                      }}
                      defaultValue={formState.TicketTypeName}
                      onChange={value => {
                        onChangePicker(
                          value,
                          value?.id ? value.id : 0,
                          'TicketTypes',
                          'TicketTypeId',
                          'TicketTypeName',
                          'TicketType'
                        );
                      }}
                    />
                  </div>
                </CheckFeature>
                <div>
                  <NestedDropdown
                    tabIndex={0}
                    disabled={isFormDisabled}
                    label={t('ticket.add-ticket.category')}
                    items={formState.Categories.map(item => ({
                      id: item.Id,
                      parentId: item.ParentId,
                      position: item.Position,
                      value: item.Value,
                      ...item
                    }))}
                    dataItem={{
                      id: formState.Category?.Id,
                      parentId: formState.Category?.ParentId,
                      position: formState.Category?.Position,
                      value: formState.Category?.Value,
                      ...formState.Category
                    }}
                    defaultValue={formState.CategoryName}
                    onChange={value => {
                      onChangePicker(
                        value,
                        value?.id ? value.id : 0,
                        'Categories',
                        'CategoryId',
                        'CategoryName',
                        'Category'
                      );
                    }}
                  />
                </div>
                <div>
                  <NestedDropdown
                    tabIndex={0}
                    disabled={isFormDisabled}
                    label={t('ticket.add-ticket.priority')}
                    items={formState.Priorities.map(item => ({
                      id: item.Id,
                      parentId: item.ParentId,
                      position: item.Position,
                      value: item.Value,
                      ...item
                    }))}
                    dataItem={{
                      id: formState.Priority?.Id,
                      parentId: formState.Priority?.ParentId,
                      position: formState.Priority?.Position,
                      value: formState.Priority?.Value,
                      ...formState.Priority
                    }}
                    defaultValue={formState.PriorityName}
                    onChange={value => {
                      onChangePicker(
                        value,
                        value?.id ? value.id : 0,
                        'Priorities',
                        'PriorityId',
                        'PriorityName',
                        'Priority'
                      );
                    }}
                  />
                </div>
                <CheckFeature featureName={LICENSE_FEATURE.MultiDepartment}>
                  <div>
                    <LabelElement label={t('ticket.add-ticket.team')} required={!isFormDisabled}>
                    <Dropdown
                      className={!isFormDisabled && `cursor-pointer`}
                      disabled={isFormDisabled}
                      items={formState.Teams}
                      fluid
                      clearable
                      search
                      searchQuery={formState.TeamName}
                      onSearchQueryChange={(_e, data) => {
                        dispatch({ type: ACTIONS.SET_TEAM_NAME, data: data.searchQuery });
                      }}
                      onChange={(_event, p) => {
                        setTeamsRequiredError(!p.value);
                        onChangeTeam(
                          p.value,
                          p.items[p.highlightedIndex] ? p.items[p.highlightedIndex]['accessibilityitemprops']['id'] : 0,
                          true,
                          true
                        );
                      }}
                      placeholder={formState.TeamName}
                      checkable
                      value={formState.Teams.filter(t => t.selected)}
                      getA11ySelectionMessage={{
                        onAdd: item => `${item} has been selected.`,
                        onRemove: item => `${item} has been removed.`
                      }}
                      style={{backgroundColor: globalTheme.siteVariables.colorScheme.default.background2 }}
                      noResultsMessage={
                        <div style={{ textAlign: 'center' }}>
                          <Text content={t('ticket.add-ticket.team-not-found')} />
                        </div>
                      }
                    />
                    </LabelElement>
                    {showTeamsRequiredError && <div className={`text-red-700`}>{t('ticket-details.edit-ticket-form.properties.error.request-field')}</div>}
                  </div>
                </CheckFeature>
                <div>
                  <Text content={t('ticket.add-ticket.group')} />
                  <Dropdown
                    className={!isFormDisabled && `cursor-pointer`}
                    disabled={isFormDisabled}
                    items={formState.SupportGroups}
                    fluid
                    onChange={(_event, p) => {
                      onChangeGroup(
                        p.value,
                        p.items[p.highlightedIndex] ? p.items[p.highlightedIndex]['accessibilityitemprops']['id'] : 0,
                        true
                      );
                    }}
                    placeholder={formState.SupportGroupName}
                    checkable
                    clearable={!isFormDisabled}
                    value={formState.SupportGroups.filter(t => t.selected)}
                    getA11ySelectionMessage={{
                      onAdd: item => `${item} has been selected.`,
                      onRemove: item => `${item} has been removed.`
                    }}
                    noResultsMessage={
                      <div style={{ textAlign: 'center' }}>
                        <Text content={t('ticket.add-ticket.group-not-found')} />
                      </div>
                    }
                    style={{backgroundColor: globalTheme.siteVariables.colorScheme.default.background2 }}
                  />
                </div>
                <div>
                  <Text content={t('ticket.add-ticket.assignee')} />
                  <Dropdown
                    disabled={isFormDisabled}
                    items={formState.PlatformUsers.filter((t: any) => !formState.TicketCollaborators.some((item: any) => item.PlatformUserId === t.accessibilityitemprops.id))}
                    search
                    fluid
                    checkable
                    clearable={!isFormDisabled}
                    value={formState.PlatformUsers.filter((t: any) => t.selected)}
                    searchQuery={formState.AssigneeName}
                    onSearchQueryChange={(_e, data) => {
                      dispatch({ type: ACTIONS.SET_ASSIGNEE_NAME, data: data.searchQuery });
                    }}
                    onChange={(_event, p) => {
                      onChangeAssignee(
                        p.value,
                        p.items[p.highlightedIndex] ? p.items[p.highlightedIndex]['accessibilityitemprops']['id'] : 0
                      );
                    }}
                    noResultsMessage={
                      <div style={{ textAlign: 'center' }}>
                        <Text content={t('ticket.add-ticket.user-not-found')} />
                      </div>
                    }
                    style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background2 }}
                  />
                </div>

                <CheckFeature featureName={LICENSE_FEATURE.Collaborators}>
                <div>
                  <Text content={t('ticket.ticket-list.collaborators')} />
                  <Dropdown
                    placeholder={t('ticket.add-ticket.collaborators')}
                    disabled={isFormDisabled}
                    multiple
                    search
                    fluid
                    items={collaboratorList.filter((t: any) => formState.AssigneeId != t.accessibilityitemprops)}
                    a11ySelectedItemsMessage="Press Delete or Backspace to remove"
                    onChange={onChangeCollaborators}
                    defaultValue={collaboratorList.filter(t => t.selected)}
                    searchInput={{ inline: true, inputRef: tagSearch, styles: { paddingLeft: '8px', height: '32px' } }}
                    noResultsMessage={<div>No User found</div>}
                    style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background2 }}
                    className={'collaborators--dropdown'}
                  />
                </div>
                </CheckFeature >

                <div>
                  <Text content={t('ticket.add-ticket.due-date')} />
                  {TicketDatePicker(props.ticket.Closed,
                  formState.DueDate == null ? null : new Date(formState.DueDate),
                  null,
                  (e, p) => {
                      let selectedDate = p.value === null ? null : p.value.toISOString();
                      updateFormState({ DueDate: selectedDate });
                    })}
                  
                </div>
                <div>
                  <Text content={t('ticket.add-ticket.resolution-date')} />
                  {TicketDatePicker(props.ticket.Closed,
                  formState.ResolutionDate == null ? null : new Date(formState.ResolutionDate),
                  null,
                  (e, p) => {
                    let selectedDate =
                    p.value === null ? null : p.value?.toISOString();
                  updateFormState({ ResolutionDate: selectedDate });
                    })}
                  
                </div>
                <CheckFeature featureName={LICENSE_FEATURE.TicketTags}>
                  <div>
                    <Text content={t('ticket.add-ticket.tag')} />
                    {TicketTagPicker(globalTheme, "", tagSearch, props.ticket.Closed, formState.IsCreatingTag, formState.TagList, TAGS.SELECT_TAGS, formState.TagSearchQuery, addNewTag, selectTag, tagOnSearchQueryChange)}
                  </div>
                </CheckFeature>
              </Flex>
            )}
            {isPropertiesAccordionOpen && formState.LoadingTicketData && (
              <PropertiesSkeletonLoader />
            )}

            <EditTicketAttachments
              globalTheme={props.globalTheme}
              ticketId={props.ticket.Id}
              isOpen={isAttachmentAccordionOpen}
              onCLose={value => { setIsAttachmentAccordionOpen(value) }}
              primaryButtonColor={ globalTheme.siteVariables.colorScheme.brand.foreground1 }
              isSaving={false}
              onInteraction={props.onInteraction}
              onToolbarInteraction={props.onToolbarInteraction}
              fileTriggerChannel={props.fileTriggerChannel}
              isEndUserPage={false}
              isTicketClosed={props.ticket.Closed}
              fileAttachments={fileAttachments}
              signedInUser={props.signedInUser}
              teamsConfig={currentState.teamsConfig}
            />

            <EditTicketCustomFormAnswers
              globalTheme={props.globalTheme}
              ticketId={props.ticket.Id}
              isOpen={isCustomFormAnswersAccordionOpen}
              onCLose={value => {setIsCustomFormAnswersAccordionOpen(value)}}
              primaryButtonColor={globalTheme.siteVariables.colorScheme.brand.foreground1}
              isSaving={props.savingState}
              parentData={props.ticket}
              isDisabled={isFormDisabled}
            />

            <CheckFeature featureName={LICENSE_FEATURE.RelatedTickets}>
              <EditTicketRelateTickets
                relatedTicketsCount={relatedTickets?.length}
                oldRelatedTicketIds={relatedTickets.filter(t => t.RelationshipTypeId === RelationType.RelateTickets).map(t => t.Id)}
                sourceTicket={props.ticket}
                isFormDisabled={isFormDisabled}
                isRelateTicketsOpen={isRelateTicketsOpen}
                setIsRelateTicketsOpen={setIsRelateTicketsOpen}
                relateTickets={relateTickets}
                isLoading={formState.LoadingRelatedTickets}
                primaryButtonColor={globalTheme.siteVariables.colorScheme.brand.foreground1}
              />
              {isRelateTicketsOpen && (formState.LoadingRelatedTickets ? <RelatedTicketsListSkeleton /> : <RelatedTicketsList relatedTickets={relatedTickets} />)}
            </CheckFeature>

            {(!props.ticket.TicketLifecycle || !isLifecycleEnabled) && (
            <>
              <CheckFeature featureName={LICENSE_FEATURE.Approvals}>
                <EditTicketApprovals
                    ticketApprovalsCount={ticketApprovals?.length}
                    isFormDisabled={isFormDisabled}
                    isApprovalsOpen={isApprovalsOpen}
                    setIsApprovalsOpen={setIsApprovalsOpen}
                    addTicketApprovals={addTicketApproval}
                    approversList={approversList}
                    setApproversList={setApproversList}
                    isLoading={formState.LoadingTicketApprovals}
                    primaryButtonColor={globalTheme.siteVariables.colorScheme.brand.foreground1}
                />
                {isApprovalsOpen && (formState.LoadingTicketApprovals ? ( <TicketApprovalsSkeleton/>):(
                  <TicketApprovalsList 
                    ticketApprovals={ticketApprovals}
                    currentUserId={currentState.currentUserId}
                    updateApprovalStatus={updateApprovalStatus}
                    isTicketClosed={props.ticket.Closed}
                    isLifecycleEnabled={isLifecycleEnabled}
                    updateTicketApproval= {updateTicketApproval}
                    approversList={approversList}
                    setApproversList={setApproversList}
                  />
                ))}
              </CheckFeature>
              <CheckFeature featureName={LICENSE_FEATURE.TicketTasks}>
                <TicketTasks
                  statuses={currentState.taskStatus}
                  analysts={currentState.usersInRole}
                  ticketId={props.ticket.Id}
                  ticketTitle={props.ticket.Title}
                  reloadTicketList={reloadTaskList}
                  setReloadTaskList={setReloadTaskList}
                  isFormDisabled={isFormDisabled}
                  ticketTasks={ticketTasks}
                  getTicketTasks={getTicketTasks}
                  isLoading={formState.LoadingTicketTasks}
                  setIsLoading={setIsLoadingTasks}
                />
              </CheckFeature>
             </>
            )}
             <TemplateListDialog
                isNewTicket={false}
                formState={formState}
                showTemplate={openApplyTemplate}
                isApplyingTemplateWithLifecycle={isApplyingTemplateWithLifecycle}
                templateList={templates}
                dispatch={dispatch}
                closeApplyTemplate={closeApplyTemplate}
                applyTemplate={applyTemplate}
                ticketApprovals={ticketApprovals}
              />
            
            <LifecycleSelection 
              ticketData={formState}
              dispatchEvent={dispatch} 
              openApplyLifecycle={openApplyLifecycle}
              closeApplyLifecycle={closeApplyLifecycle}
              applyLifecycleToTickets={sendRequestToApplyLifecycle}
            />

            <Dialog
              content={dialogContent(openTaskList, penddingTicketApprovals)}
              header={dialogHeader(formState.Status, props.ticket.Id, t)}
              open={showTaskWarningDialog}
              headerAction={{
                icon: <CloseIcon />,
                title: t('ticket-details.apply-template.dialog.button.close'),
                onClick: onCloseConfirmModal
              }}
              footer={
                <Flex gap="gap.small">
                  <Flex.Item push>
                    <Button
                      content={t('ticket-details.apply-template.dialog.button.cancel')}
                      onClick={onCloseConfirmModal}
                    />
                  </Flex.Item>
                  <Flex.Item>
                    <Button
                      content={t('common.buttons.confirm')}
                      primary
                      onClick={confirmResolve}
                    />
                  </Flex.Item>
                </Flex>
              }
            />

            {showResolvedDialog && 
              <ResolutionNoteDialog
                resolutionProp={{ticketData:formState, resolutionNoteConfig: props.ResolutionNoteConfig} as IResolution}
                setShowDialog={setShowResolvedDialog}
                showDialog={showResolvedDialog}
                setUpdatedResolution={setResolution}
                confirmSave={async ()=>{
                  updateFormState({ Resolution: resolution });
                  onConfirmModal();
                  setShowResolvedDialog(false);
                }}
              />
            }
            
          </>
        )}
      />
      {Prompt}
    </>
  );
};
