import React from 'react';
import _ from 'lodash';
import { platformService } from '../shared/services/platform.service';
import {
  CATEGORY
} from '../shared/utils/constants';
import { useTranslation } from 'react-i18next';
import { EnumList } from '../enum-list/EnumList';

export const CategoryList = () => {
  const {t} = useTranslation();
  const api = new platformService();
  

  const updateCategory = async (param:any) => {
    await api.updateCategory(param);
  }

  const createCategory = async (param:any) => {
    return await api.createCategory(param as CategoryDetail);
  }

  

  const getList = async(param?:string) => {
    return await api.getCategories(param ?? `?v=${new Date().getTime()}`);
  }

  return (
    <EnumList
    typeName={'ServiceDesk.Core.Models.Pickers.Ticket.Category'}
    updateData={updateCategory}
    createData={createCategory}
    getList={getList}
    entityName={"Category"}
    title={CATEGORY.CATEGORY}
    successMgs={t('category.changes-are-saved')}
    pageTitle={t('category.title')}
    featchingListMsg={CATEGORY.GETTING_ALL_CATEGORIES}
    updateMsg={CATEGORY.UPDATING_CATEGORY}
    userRoleHasAccess={true}
     />
  );
};
