import React from "react";

export const truncateString = (str, num) => {
    if (str == null || str.length <= num) {
      return str;
    }
    let truncatedStr = str.slice(0, num);
    const nextSpaceIndex = str.indexOf(' ', num);
  
    if (nextSpaceIndex !== -1) {
      truncatedStr = str.slice(0, nextSpaceIndex);
    }
  
    return truncatedStr + " ...";
  }

  const kbStyle = {
    whiteSpace: 'normal',
    wordBreak: 'break-word'
  };