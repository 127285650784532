import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { platformService } from '../shared/services/platform.service';
import {
  STATUS,
  ADMINISTRATORS,
  ANALYSTS
} from '../shared/utils/constants';
import { useTranslation } from 'react-i18next';
import { appState } from '../AppState';
import { EnumList } from '../enum-list/EnumList';

export const StatusList = () => {
  
  const currentState = appState();
  const {t} = useTranslation();
  const api = new platformService();
  const [hasAccess, setHasAccess] = useState(true);

  useEffect(()=>{
    let _hasAccess = currentState.userRoles.roles.includes(ADMINISTRATORS) || currentState.userRoles.roles.includes(ANALYSTS);
    setHasAccess(_hasAccess);
  },[]);

  const update = async (param:any) => {
    await api.updateStatus(param);
  }

  const create = async (param:any) => {
    return await api.createStatus(param as PriorityDetail);
  }


  const getList = async(param?:string) => {
    return await api.getStatus(param ?? `?v=${new Date().getTime()}`);
  }

  return (
    <EnumList
      typeName={'ServiceDesk.Core.Models.Pickers.Ticket.Status'}
      updateData={update}
      createData={create}
      getList={getList}
      entityName={"Status"}
      title={STATUS.STATUS}
      pageTitle={t('status.title')}
      successMgs={t('status.changes-are-saved')}
      featchingListMsg={STATUS.GETTING_ALL_STATUSES}
      updateMsg={STATUS.UPDATING_STATUS}
      userRoleHasAccess={hasAccess}
    />
  );
};
