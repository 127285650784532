import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import {
    createEditor,
    Editor,
    Transforms,
    Range,
    Descendant,
    Node as SlateNode,
    BasePoint,
    Text as SlateText
} from 'slate'
import pipe from "lodash/fp/pipe";

import { slateToHtml } from '@slate-serializers/html';
import TurndownService from 'turndown';
import { Element as DomElement } from 'domhandler';

import { Slate, Editable, withReact, useSelected, useFocused, ReactEditor } from 'slate-react'
import { withHistory } from 'slate-history';
import { jsx } from 'slate-hyperscript'

import isHotkey from 'is-hotkey';

import ReactDOM from 'react-dom';
import { CustomAncestor, MentionElement } from './editortypes';
import { PlatformUser } from '../../interfaces/platformuser.interface';
import SlateToolbar from './SlateComponents/SlateToolbar';
import { Person, PersonViewType } from '@microsoft/mgt-react';
import { ProviderConsumer as FluentUIThemeConsumer } from "@fluentui/react-northstar";
import { useTranslation } from 'react-i18next';
import { marked } from 'marked';
import './Editor.css';

interface SlateEditorProps {
    onChange: (data: any) => void;
    slateValue?: Descendant[];
    markdownString?: string;
    isFocus: boolean;
    noImage: boolean;
    usersInMention?: PlatformUser[];
    readOnly?: boolean;
    onImagePaste?: (data: File) => void;
    minHeight?: string;
    className?:string;
    disabled?:boolean;
}

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
    'mod+d': 'strikethrough',
}

const ELEMENT_TAGS = {
    A: el => ({ type: 'link', url: el.getAttribute('href') }),
    BLOCKQUOTE: () => ({ type: 'blockquote' }),
    PRE: () => ({ type: 'code' }),
    H1: () => ({ type: 'h1' }),
    H2: () => ({ type: 'h2' }),
    H3: () => ({ type: 'h3' }),
    H4: () => ({ type: 'heading-four' }),
    H5: () => ({ type: 'heading-five' }),
    H6: () => ({ type: 'heading-six' }),
    IMG: el => ({ type: 'image', url: el.getAttribute('src') }),
    LI: () => ({ type: 'list-item' }),
    OL: () => ({ type: 'numbered-list' }),
    P: () => ({ type: 'paragraph' }),
    UL: () => ({ type: 'bulleted-list' }),
}

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
    CODE: () => ({ code: true }),
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    STRONG: () => ({ bold: true }),
    U: () => ({ underline: true }),
}

const withBullets = editor => {
    const { insertBreak } = editor;

    editor.insertBreak = () => {
        const { selection } = editor;

        if (selection && Range.isCollapsed(selection)) {
            const [start] = Range.edges(selection);
            const startNode = Editor.node(editor, start);

            if (Editor.isBlock(editor, startNode[0])) {
                const [parentNode, parentPath] = Editor.parent(editor, start.path);
                const pNode = parentNode as CustomAncestor;
                if (pNode.type === 'list-item') {
                    const newListItem = { type: 'list-item', children: [{ text: '' }] };
                    Transforms.insertNodes(editor, newListItem, { at: parentPath });
                    return;
                }
            }
        }

        insertBreak();
    };

    return editor;
};

const withHtml = editor => {
    const { insertData, isInline, isVoid } = editor
    editor.isInline = element => {
        return element.type === 'link' ? true : isInline(element)
    }

    editor.isVoid = element => {
        return element.type === 'image' ? true : isVoid(element)
    }

    editor.insertData = data => {
        const html = data.getData('text/html')

        if (html) {
            const parsed = new DOMParser().parseFromString(html, 'text/html')
            const fragment = deserialize(parsed.body)
            Transforms.insertFragment(editor, fragment)
            const end = Editor.end(editor, []);
            Transforms.select(editor, end);
            return
        }

        insertData(data)
    }

    return editor
}
export const deserialize = el => {
    if (el.nodeType === 3) {
        return el.textContent; // Trim leading and trailing whitespace
    } else if (el.nodeType !== 1) {
        return null;
    } else if (el.nodeName === 'BR') {
        return { text:"\n" };
    }

    const { nodeName } = el;
    let parent = el;

    if (
        nodeName === 'PRE' &&
        el.childNodes[0] &&
        el.childNodes[0].nodeName === 'CODE'
    ) {
        parent = el.childNodes[0];
    }

    let children = Array.from(parent.childNodes).map(deserialize).flat();

    if (children.length === 0) {
        children = [{ text: '' }];
    }

    if (el.nodeName === 'BODY') {
        children.push({ type: 'paragraph', children: [{ text: '' }] })
        return jsx('fragment', {}, children);
    }

    if (ELEMENT_TAGS[nodeName]) {
        const attrs = ELEMENT_TAGS[nodeName](el);
        return jsx('element', attrs, children);
    }

    if (TEXT_TAGS[nodeName]) {
        const attrs = TEXT_TAGS[nodeName](el);
        return children.map(child => jsx('text', attrs, child));
    }

    return children;
};

export const Portal = ({ children }: { children?: ReactNode }) => {
    return typeof document === 'object'
        ? ReactDOM.createPortal(children, document.body)
        : null
}

const insertMention = (editor, character, email, firstMention?: boolean) => {
    const mention: MentionElement = {
        type: 'mention',
        character,
        email,
        children: [{ text: character }],
    }
    if (firstMention) {
        removeFirstCharacter(editor)
    }
    Transforms.insertNodes(editor, mention as any)
    Transforms.move(editor)
}

const withMentions = editor => {
    const { isInline, isVoid, markableVoid } = editor

    editor.isInline = element => {
        return element.type === 'mention' ? true : isInline(element)
    }

    editor.isVoid = element => {
        return element.type === 'mention' ? true : isVoid(element)
    }

    editor.markableVoid = element => {
        return element.type === 'mention' || markableVoid(element)
    }

    return editor
}

const insertImage = (editor, url) => {
    const text = { text: '' };
    const image = { type: 'image', url, children: [text] };
    Transforms.insertNodes(editor, image);
    const paragraph = { type: 'paragraph', children: [{ text: '' }] };
    Transforms.insertNodes(editor, paragraph);
};

const El = props => {
    const { attributes, children, element } = props
    const style = { textAlign: element.align }
    switch (element.type) {
        case 'h1':
            return (
                <h1 className={'text-6xl'} style={style} {...attributes}>
                    {children}
                </h1>
            )
        case 'h2':
            return (
                <h2 className={'text-5xl'} style={style} {...attributes}>
                    {children}
                </h2>
            )
        case 'h3':
            return (
                <h3 className={'text-4xl'} style={style} {...attributes}>
                    {children}
                </h3>
            )
        case 'code':
            return (
                <pre style={style} {...attributes}>
                    {children}
                </pre>
            )
        case 'heading-four':
            return (
                <h2 className={'text-3xl'} style={style} {...attributes}>
                    {children}
                </h2>
            )
        case 'bulleted-list':
            return (
                <ul className='mx-4 list-disc' style={style} {...attributes}>
                    {children}
                </ul>
            )
        case 'numbered-list':
            return (
                <ol className='mx-4 list-decimal' style={style} {...attributes}>
                    {children}
                </ol>
            )
        case 'image':
            return <Image {...props} />
        case 'mention':
            return <Mention {...props} />
        case 'list-item':
            return (
                <li style={style} {...attributes}>
                    {children}
                </li>
            )
        case 'link':
            return <a href={element.url} style={{ color: "var(--mgt-theme-brand-foreground1)", cursor: "pointer" }}>{children}</a>
        default:
            return (
                <p style={style} {...attributes}>
                    {children}
                </p>
            )
    }
}

const Image = ({ attributes, children, element }) => {
    return (
        <div {...attributes}>
            <div
                contentEditable={false}
            >
                <img
                    src={element.url}
                />
            </div>
            {children}
        </div>
    )
}

const Mention = ({ attributes, children, element, theme }) => {
    const selected = useSelected()
    const focused = useFocused()

    const style: React.CSSProperties = {
        padding: '3px 3px 2px',
        margin: '0 1px',
        verticalAlign: 'baseline',
        display: 'inline-block',
        borderRadius: '4px',
        fontSize: '0.9em',
        backgroundColor: '#eee',
        boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
    }

    if (theme === 'light') {
        style.backgroundColor = "#eee";
        style.boxShadow = selected && focused ? '0 0 0 2px #B4D5FF' : 'none'
    } else {
        style.backgroundColor = "#222";
        style.color = "rgb(166, 167, 220)"
    }
    // See if our empty text child has any styling marks applied and apply those
    if (element.children[0].bold) {
        style.fontWeight = 'bold'
    }
    if (element.children[0].italic) {
        style.fontStyle = 'italic'
    }
    return (
        <span
            {...attributes}
            contentEditable={false}
            data-cy={`mention-${element.email}`}
            style={style}
        >
            {element.character}
            {children}
        </span>
    )
}

const Leaf = ({ attributes, children, leaf, theme }) => {

    const darkCode = 'border-gray-500 bg-gray-800'
    const lightCode = 'border-gray-300 bg-gray-100'

    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code className={`border rounded-sm px-1 mx-1 ${theme === 'dark' ? darkCode : lightCode}`}>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
}


const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
}

const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

export const deepEqual = (obj1: any, obj2: any): boolean => {
    if (obj1 === obj2) {
        return true;
    }

    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}

export const slateToMd = (data) => {
    const turndownService = new TurndownService({
        headingStyle: 'atx', // Use ATX-style headings (e.g., # for h1),
    });

    const htmlvalue = slateToHtml(data, {
        elementMap: {
            'paragraph': 'p',
            'bulleted-list': 'ul',
            'list-item': 'li',
            'numbered-list': 'ol'
        },
        elementTransforms: {
            image: ({ node }) => {
                return new DomElement('img', { src: node.url, style: 'width: 100%' })
            },

            link: ({ node, children }) => {
                return new DomElement('a', { href: node.url }, children)
            },
            paragraph: ({ children }) => {
                if (children[0]['children'][0].data === "") {
                    return new DomElement('br', {})
                }
                return new DomElement('p', {}, children)
            },
            'numbered-list': ({ children }) => {
                return new DomElement('ol', {}, children)
            },
            'bulleted-list': ({ children }) => {
                return new DomElement('ul', {}, children)
            },
            'list-item': ({ children }) => {
                return new DomElement('li', {}, children)
            },
            h1: ({ children }) => {
                if (children[0]['children'][0].data === "") {
                    return new DomElement('br', {})
                }
                return new DomElement('h1', {}, children)
            },
            h2: ({ children }) => {
                if (children[0]['children'][0].data === "") {
                    return new DomElement('br', {})
                }
                return new DomElement('h2', {}, children)
            },
            h3: ({ children }) => {
                if (children[0]['children'][0].data === "") {
                    return new DomElement('br', {})
                }
                return new DomElement('h3', {}, children)
            },
            mention: ({ node, children }) => {
                if (children[0]['children'][0].data === "") {
                    return new DomElement('br', {})
                }
                return new DomElement('a', { href: 'mention:' + node.email }, children);
            },
        },
        convertLineBreakToBr: true,
        markMap: { bold: ['strong'], italic: ['em'], underline: ['u'], strikethrough: ['s'], image: ['img'], code: ['code'] }
    });

    return turndownService.turndown(htmlvalue);
}

 export const markdownToSlate = (_markdown) => {
    let decendants = [];
    let tempChildren = [];
    if (_markdown==undefined || _markdown=="") return [{
        children:  [{ text: '' }],
        },
        ];
    const html = marked(_markdown, { async: false });
    
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    let children = deserialize(doc.body);

    children.filter(x=>["numbered-list","bulleted-list"].includes(x.type))?.forEach(item=>{
        item.children = item.children.filter(x=>x?.text!="\n");
        if (item.type=='numbered-list'){
            item.children.forEach(numbered => {
                numbered.children = numbered.children.filter(x=>x?.text!="\n");
            })
        }
    });
    return children
}

  


const removeFirstCharacter = (editor) => {
    const firstNode = editor.children[0];
    if (SlateText.isText(firstNode.children[0])) {
        const text = firstNode.children[0].text;
        if (text.length > 0) {
            Transforms.delete(editor, {
                at: { path: [0, 0], offset: 0 },
                distance: 1,
                unit: 'character',
            });
        }
    }
};

const SlateEditor: React.FC<SlateEditorProps> = (props: SlateEditorProps) => {
    const { t } = useTranslation();
    const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches
    let theme = localStorage.getItem("TikitTheme") ?? 'light'

    if (theme === 'system') {
        theme = isDark ? 'dark' : 'light'
    }

    const withImages = editor => {
        const { insertData, isVoid } = editor;

        editor.isVoid = element => {
            return element.type === 'image' ? true : isVoid(element);
        };

        editor.insertData = data => {
            const { files } = data;

            if (files && files.length > 0) {
                const file = files[0];
                const [mime] = file.type.split('/');

                if (mime === 'image' && props.onImagePaste) 
                    props.onImagePaste(file);
            } else {
                insertData(data);
            }
        };

        return editor;
    };

    const renderElement = useCallback(props => <El {...props} theme={theme} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} theme={theme} />, [])
    const ref = useRef<HTMLDivElement | null>()
    const createEditorWithPlugins = pipe(
        withReact,
        withHistory,
        withHtml,
        withBullets,
        withImages,
        withMentions,
    );

    const editor = React.useMemo(() => createEditorWithPlugins(createEditor()), []);

    const [target, setTarget] = useState<Range | undefined>()
    const [index, setIndex] = useState(0)
    const [search, setSearch] = useState(null)
    const [chars, setChars] = useState<PlatformUser[]>([])

    useEffect(() => {
        if (props.usersInMention && search !== null) {
            if (search === "") {
                setChars(props.usersInMention)
            }
            const peopleToMention: PlatformUser[] = props.usersInMention.filter(c => c.FullName?.toLowerCase().startsWith(search.toLowerCase()) || c.FamilyName?.toLowerCase().startsWith(search.toLowerCase()));
            if (peopleToMention.length === 0) {
                setTarget(null)
                setIndex(0)
            } else {
                setChars(peopleToMention)
            }
        }

    }, [search])

    const getPlainText = (nodes) => {
        return nodes.map(n => SlateNode.string(n)).join('\n');
    };

    const [isFirstMention, setIsFirstMention] = useState<boolean>(false)

    const checkIfSearchLength = (text: string) => props.usersInMention.some(c => c.FullName?.toLowerCase().startsWith(text.toLowerCase()) || c.FamilyName?.toLowerCase().startsWith(text.toLowerCase()));

    const handleChange = (nextValue: Descendant[]) => {
        setValue(nextValue)
        const { selection } = editor

        if (selection && Range.isCollapsed(selection) && value !== nextValue) {
            const [start] = Range.edges(selection);

            // for character lookup
            const characterBefore = Editor.before(editor, start, { unit: 'character' });
            const cbefore = characterBefore && Editor.before(editor, characterBefore);
            const cbeforeRange = cbefore && Editor.range(editor, cbefore, start);
            const cbeforeText = cbeforeRange && Editor.string(editor, cbeforeRange);

            // for word lookup
            let wordBefore = Editor.before(editor, start, { unit: 'word' });
            let before = wordBefore && Editor.before(editor, wordBefore);
            let beforeRange = before && Editor.range(editor, before, start);
            let beforeText = beforeRange && Editor.string(editor, beforeRange);

            if (before) {
                while ((before?.offset ?? 0) > 0 && !beforeText.startsWith("@")) {
                    const [startForWordBefore] = Range.edges({
                        anchor: {
                            offset: before.offset - 1,
                            path: before.path,
                        },
                        focus: {
                            offset: before.offset - 1,
                            path: before.path,
                        },
                    });
                    wordBefore = Editor.before(editor, startForWordBefore, { unit: 'word' });
                    before = wordBefore && Editor.before(editor, wordBefore);
                    
                    beforeRange = before ? Editor.range(editor, before, start) : beforeRange;
                    beforeText = beforeRange && Editor.string(editor, beforeRange);
                }
            }

            const beforeMatch = beforeText && /^@([\w\s]+)$/.exec(beforeText);               

            const after = Editor.after(editor, start);
            const afterRange = Editor.range(editor, start, after);
            const afterText = Editor.string(editor, afterRange);
            const afterMatch = /^(\s|$)/.exec(afterText);

            const startOfEditor: BasePoint = {
                "path": [0, 0],
                "offset": 1
            };
            
            if (beforeMatch && afterMatch && checkIfSearchLength(beforeMatch[1])) {
                setTarget(beforeRange);
                setSearch(beforeMatch[1]);
                setIndex(0);
                return;
            } else if (cbeforeText && cbeforeText?.slice(1).startsWith("@") && cbeforeText.startsWith(" ")) {
                setTarget(cbeforeRange);
                setSearch("");
                setIndex(0);
                return;
            } else if (getPlainText(nextValue).length > 0 && getPlainText(nextValue).startsWith("@") && deepEqual(start, startOfEditor)) {
                setIsFirstMention(true);
                setTarget(Editor.range(editor, startOfEditor));
                setSearch("");
                setIndex(0);
                return;
            }
        }
        setTarget(null)
        props.onChange(nextValue)
    }

    const onKeyDown = useCallback(
        (event, editor) => {
            if (editor.isComposing) {
                return;
            }
            if (isHotkey('mod+a', event)) {
                event.preventDefault()
                Transforms.select(editor, [])
            }

            for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event)) {
                    event.preventDefault()
                    const mark = HOTKEYS[hotkey]
                    toggleMark(editor, mark)
                }
            }

            if (target && chars.length > 0) {
                switch (event.key) {
                    case 'ArrowDown':
                        event.preventDefault()
                        const prevIndex = index >= chars.length - 1 ? 0 : index + 1
                        setIndex(prevIndex)
                        break
                    case 'ArrowUp':
                        event.preventDefault()
                        const nextIndex = index <= 0 ? chars.length - 1 : index - 1
                        setIndex(nextIndex)
                        break
                    case 'Tab':
                    case 'Enter':
                        event.preventDefault()
                        Transforms.select(editor, target)
                        insertMention(editor, chars[index].FullName, chars[index].AadObjectId ?? chars[index].UserName, isFirstMention)
                        setIsFirstMention(false)
                        setTarget(null)
                        break
                    case 'Escape':
                        event.preventDefault()
                        setTarget(null)
                        break
                }
            }
        },
        [chars, editor, index, target]
    )

    useEffect(() => {
        if (target && chars.length > 0) {
            const el = ref.current
            const domRange = ReactEditor.toDOMRange(editor, target)
            const rect = domRange.getBoundingClientRect()
            // changing the position of the mention dropdown

            el.style.bottom = `calc(100% - ${rect.top + window.scrollY}px)`
            el.style.left = `${rect.left + window.scrollX}px`
        }
    }, [chars.length, editor, index, search, target])

    const [value, setValue] = useState<Descendant[]>(props.slateValue);

    useEffect(() => {
        return () => {
            setTarget(null)
            setSearch(null)
        };
    }, []);

    const findDifference = (original, updated) => {
        let start = 0;
        while (start < original.length && original[start] === updated[start]) {
            start++;
        }

        let endOriginal = original.length - 1;
        let endUpdated = updated.length - 1;
        while (
            endOriginal >= start &&
            endUpdated >= start &&
            original[endOriginal] === updated[endUpdated]
        ) {
            endOriginal--;
            endUpdated--;
        }

        return {
            start,
            removed: original.slice(start, endOriginal + 1),
            added: updated.slice(start, endUpdated + 1),
        };
    }

    const getForegroundColor = ({ siteVariables: { colorScheme } }: any, disabled?: boolean) => {
        return (disabled) ? colorScheme.default.foregroundDisabled1 : colorScheme.default.foreground;
      }

    return (
        <FluentUIThemeConsumer
          render={(globalTheme) => (
            <div style={{ position: 'relative' }} className={props.className}>
                <Slate editor={editor} value={value} onChange={handleChange}>
                    <SlateToolbar editor={editor} noImage={props.noImage} value={value} />
                    <Editable
                        renderElement={renderElement} 
                        renderLeaf={renderLeaf} 
                        onKeyDown={(event) => onKeyDown(event, editor)} 
                        placeholder="" 
                        autoFocus={true} 
                        spellCheck="true" 
                        readOnly={(props.readOnly || props.disabled) ?? false}
                        onInput={(event) => {
                            if (editor.isComposing) {
                                return;
                            }
                            const domSelection = window.getSelection();
                            if (!domSelection || !editor.selection) return;

                            const domNode = domSelection.anchorNode;

                            if (domNode.textContent) {
                                const correctedText = domNode.textContent;
                                // Get the current text content in Slate for the current block
                                const [block] = Editor.node(editor, editor.selection.anchor.path);
                                const originalText = SlateNode.string(block);

                                // Find the difference between the original text and corrected text
                                const { start, removed, added } = findDifference(originalText, correctedText);

                                // Calculate the range to replace in the Slate editor
                                const anchorPath = editor.selection.anchor.path;
                                const range = {
                                    anchor: { path: anchorPath, offset: start },
                                    focus: { path: anchorPath, offset: start + removed.length },
                                };

                                // Replace the removed text with the corrected text
                                Transforms.delete(editor, { at: range });
                                Transforms.insertText(editor, added, { at: range.anchor });

                                // Prevent default to stop duplication
                                event.preventDefault();
                            }
                        }}
                        onCompositionStart={(_event) => {
                            editor.isComposing = true;
                        }}
                        onCompositionEnd={(_event) => {
                            editor.isComposing = false;
                        }}
                        className={`p-3 border-none focus:border-b-2 focus:border-b-teams rounded outline-none max-h-40 overflow-auto`} 
                        style={{ ...(props.minHeight ? { minHeight: props.minHeight } : {}),color:getForegroundColor(globalTheme,props.disabled)}} {...({spellcheck: "true" })} />
                    {target && chars.length > 0 && (
                        <Portal>
                            <div ref={ref} style={{ bottom: '-9999px', left: '-9999px', position: 'absolute', zIndex: 9999999, padding: '3px', background: globalTheme.siteVariables.colorScheme.default.background, borderRadius: '4px', boxShadow: '0 1px 5px rgba(0,0,0,.2)', maxHeight: '35vh', overflow: 'auto' }}
                                className='mentions-container drop-shadow-lg' data-cy="mentions-portal">
                                <div className="px-2 py-3" style={{ color: `${globalTheme.siteVariables.colorScheme.default.foreground}` }}>{t('ticket-details.ticket-conversation.suggestions')}</div>
                                {chars.map((char, i) => (
                                    <div key={char.Email} style={{ padding: '1px 3px', borderRadius: '3px', }} className={`${(i === index ? 'bg-purple-400 text-white' : '')} mention-selection cursor-pointer`}>
                                        <a className={"block p-2 mention-selection"} href="/" onClick={(e) => {
                                            e.preventDefault();
                                            Transforms.select(editor, target)
                                            insertMention(editor, char.FullName, char.AadObjectId ?? char.UserName, isFirstMention)
                                            setIsFirstMention(false)
                                            setTarget(null)
                                            ReactEditor.focus(editor);  
                                        }}>
                                            <div className='mention-selection flex items-center'>
                                                <Person personQuery={char.Email} view={PersonViewType.avatar} avatarSize='small' />
                                                <div
                                                    className="ml-2 mention-selection"
                                                    style={{
                                                    color: `${globalTheme.siteVariables.colorScheme.default.foreground}`,
                                                    }}
                                                >
                                                    <div className='mention-selection'>{char.FullName}</div>
                                                    <div className='mention-selection text-xs'>{char.Email}</div>
                                                </div>
                                            </div>
                                        </a>

                                    </div>
                                ))}
                            </div>
                        </Portal>
                    )}
                </Slate>
            </div>
          )} />
    )
}

export default SlateEditor;