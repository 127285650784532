import { ListContext, ListContextType } from './ListContextProvider';
import { platformService } from '../../shared/services/platform.service';
import { FILTERS, FILTERS_STRINGS, STATUS_GUID, TICKET_LIST_COLUMN_IDS, TICKET_PAGINATION, FilterDataType } from '../../shared/utils/constants';
import { useContext, useRef } from 'react';
import { AppStateContext, AppStateValue } from '../../AppState';
import { TicketProps } from '../../shared/interfaces/ticketproperties.interface';
import { PlatformUser } from '../../shared/interfaces/platformuser.interface';
import { ISupportGroup } from '../../shared/interfaces/supportGroup.interface';
import { Lifecycle } from '../../lifecycle/utils/typings';
import { CustomViewContext, DEFAULT_SORT_ORDER, EnumSortOrderType } from '../toolbar/CustomViewContextProvider';
import { useLocation } from 'react-router-dom';
import { ListInputItemsEnum } from '../ticketHelper';
import { checkIfDefaultFilter, getChildFiltersFromStorage, getSortOrderFromStorage, getViewIdFromStorage } from '../toolbar/CustomViewPopup/heplers/customPopupHelper';
import { retrieveTicketInformation } from '../../shared/utils/propertyHelper';

export const DEFAULT_SORT_ORDER_FOR_QUERYING: ISortOrder = DEFAULT_SORT_ORDER || {
  orderBy: 1,
  orderType: EnumSortOrderType.desc
}

const api = new platformService();

const getFirstDayOfWeek = () => {
  const today = new Date();
  return new Date(
    today.getUTCFullYear(),
    today.getMonth(),
    today.getDate() - today.getDay()
  );
};

const getFirstDayNextWeek = () => {
  const today = new Date();
  const daysToAdd = 7 - today.getDay();
  return new Date(
    today.getUTCFullYear(),
    today.getMonth(),
    today.getDate() + daysToAdd
  );
};

const getLastDayNextWeek = () => {
  const today = new Date();
  const daysToAdd = 14 - today.getDay();
  return new Date(
    today.getUTCFullYear(),
    today.getMonth(),
    today.getDate() + daysToAdd
  );
};

const getDateTillEndOfTheDay= (modifiedDate: string) => {
  var date = new Date(modifiedDate);
  date.setHours(23, 59, 59, 999);
  return date.toISOString();
}

//for processing due, resolution and modified date
const getDatesQuery = (selectedValuesArr: string[], dateTitle: 'DueDate' | 'ResolutionDate' | 'CreatedDate' |'ModifiedDate', childFiltervalues?: FilterPropsItems[], modifiedDateValueFromCustomView?: any, createdDateValueFromCustomView?: any) => {
  let getCurrentDateTime = new Date();
  let today = new Date(
    getCurrentDateTime.getUTCFullYear(),
    getCurrentDateTime.getMonth(),
    getCurrentDateTime.getDate()
  );

  let tomorrow = new Date(
    getCurrentDateTime.getUTCFullYear(),
    getCurrentDateTime.getMonth(),
    getCurrentDateTime.getDate() + 1
  );

  const _getQuery = (acc: string, condition: string) => {
    return acc ? acc + ` or ${condition}` : `(${condition}`;
  };

  let query;
  if (['ModifiedDate','CreatedDate'].includes(dateTitle)) {
    const date = childFiltervalues?.find(x => x.key == dateTitle).values?.find(x => x.selected)?.date;
    const viewDate = dateTitle === 'ModifiedDate' ? modifiedDateValueFromCustomView : createdDateValueFromCustomView;
    if (date || viewDate) {
      query = selectedValuesArr.reduce((acc: string, current: any) => {
        switch (current) {
          case 0:
            acc = _getQuery(acc, `${dateTitle} lt ${new Date(date ?? viewDate).toISOString()}`);
            break;
          case 1:
            acc = _getQuery(acc, `${dateTitle} le ${getDateTillEndOfTheDay(date ?? viewDate)}`);
            break;
          case 2:
            acc = _getQuery(acc, `${dateTitle} gt ${getDateTillEndOfTheDay(date ?? viewDate)}`);
            break;
          case 3:
            acc = _getQuery(acc, `${dateTitle} ge ${new Date(date ?? viewDate).toISOString()}`);
            break;
          default: break;
        }
        return acc;  
      }, '');
    }
  } else {
    query = selectedValuesArr.reduce((acc: string, current: string) => {
      switch (parseInt(current, 10)) {
        case 0:
          acc = _getQuery(acc, `${dateTitle} lt ${today.toJSON()}`);
          break;
        case 1:
          acc = _getQuery(acc, `${dateTitle} eq ${today.toJSON()}`);
          break;
        case 2:
          acc = _getQuery(acc, `${dateTitle} eq ${tomorrow.toJSON()}`);
          break;
        case 3:
          acc = _getQuery(acc, `(${dateTitle} ge ${getFirstDayOfWeek().toJSON()}) and (${dateTitle} lt ${getFirstDayNextWeek().toJSON()})`);
          break;
        case 4:
          acc = _getQuery(acc, `(${dateTitle} ge ${getFirstDayNextWeek().toJSON()}) and (${dateTitle} lt ${getLastDayNextWeek().toJSON()})`);
          break;
        case 5:
          acc = _getQuery(acc, `${dateTitle} gt ${today.toJSON()}`);
          break;
        case 6:
          acc = _getQuery(acc, `${dateTitle} eq null`);
          break;
        default: break;
      }
      return acc;
    }, '');
  }
  return query;
};

export const isUndefinedOrEmpty = (query: string) => {
  return typeof query === 'undefined' || query === '';
};

export const filterTickets = (state: AppStateValue) => {

  const queryCheckLast = (query: string) => query === '' ? '' : ' )';
  const queryCheckStart = (query: string) => query === '' ? '' : ' and (';
  const dateQueryCheck = (query: string) => query === '' ? '' : ' or ';

  let datesFilterSelected = state.filterDropdown.selectedValues[0].values.filter((sv: FilterAccordionValues) => sv.selected);
  let statusFilterSelected = state.filterDropdown.selectedValues[1].values.filter((sv: FilterAccordionValues) => sv.selected);
  let priorityFilterSelected = state.filterDropdown.selectedValues[2].values.filter((sv: FilterAccordionValues) => sv.selected);
  let categoryFilterSelected = state.filterDropdown.selectedValues[3].values.filter((sv: FilterAccordionValues) => sv.selected);
  let assigneeFilterSelected = state.filterDropdown.selectedValues[4].values.filter((sv: FilterAccordionValues) => sv.selected);
  let groupsFilterSelected = state.filterDropdown.selectedValues[5].values.filter((sv: FilterAccordionValues) => sv.selected);
  let lifecycleFilterSelected = state.filterDropdown.selectedValues[9].values.filter((sv: FilterAccordionValues) => sv.selected);
  
  let status = state.ticketStatus;
  let priorities = state.priority;
  let categories = state.categories;
  let assignees = state.platformusers;
  let groups = state.supportgroups;
  let lifecycles = state.lifecycles;
  let getCurrentDateTime = new Date();
  let today = new Date(
    getCurrentDateTime.getUTCFullYear(),
    getCurrentDateTime.getMonth(),
    getCurrentDateTime.getDate()
  );

  let tomorrow = new Date(
    getCurrentDateTime.getUTCFullYear(),
    getCurrentDateTime.getMonth(),
    getCurrentDateTime.getDate() + 1
  );

  let query = '';

  query += state.filterDropdown.queryValue !== ''
    ? (queryCheckStart(query)) + (/^\d+$/.test(state.filterDropdown.queryValue) ? `(contains(Title, '${state.filterDropdown.queryValue}') or Id eq ${state.filterDropdown.queryValue})` : `(contains(Title, '${state.filterDropdown.queryValue}'))`) + (queryCheckLast(query))
    : '';

  let datesquery = '';
  datesFilterSelected.forEach((selected: FilterAccordionValues) => {
    switch (selected.key) {
      case 0:
        datesquery += (dateQueryCheck(datesquery)) + `DueDate lt ${today.toJSON()}`;
        break;
      case 1:
        datesquery += (dateQueryCheck(datesquery)) + `DueDate eq ${today.toJSON()}`;
        break;
      case 2:
        datesquery += (dateQueryCheck(datesquery)) + `DueDate eq ${tomorrow.toJSON()}`;
        break;
      case 3:
        datesquery += (dateQueryCheck(datesquery)) + `(DueDate gt ${getFirstDayOfWeek().toJSON()}) and (DueDate lt ${getFirstDayNextWeek().toJSON()})`;
        break;
      case 4:
        datesquery += (dateQueryCheck(datesquery)) + `(DueDate gt ${getFirstDayNextWeek().toJSON()}) and (DueDate lt ${getLastDayNextWeek().toJSON()})`;
        break;
      case 5:
        datesquery += (dateQueryCheck(datesquery)) + `DueDate gt ${today.toJSON()}`;
        break;
      case 6:
        datesquery += (dateQueryCheck(datesquery)) + 'DueDate eq null';
        break;
      default: break;
    }
  });
  query += datesquery !== '' ? (queryCheckStart(query)) + `${datesquery}` + (queryCheckLast(query)) : '';

  let statusquery = '';
  statusFilterSelected.forEach((selected: FilterAccordionValues) => {
    status.forEach((s: TicketProps) => {
      if (s.Id === selected.key) {
        statusquery += (statusquery === '' ? '' : ' or ') + `StatusId eq ${s.Id}`;
      }
    });
  });
  query += statusquery !== '' ? (queryCheckStart(query)) + `${statusquery}` + (queryCheckLast(query)) : '';

  let priorityquery = '';
  priorityFilterSelected.forEach((selected: FilterAccordionValues) => {
    priorities.forEach((priority: TicketProps) => {
      if (priority.Id === selected.key) {
        priorityquery += (priorityquery === '' ? '' : ' or ') + `PriorityId eq ${priority.Id}`;
      }
    });
  });
  query += priorityquery !== '' ? (queryCheckStart(query)) + `${priorityquery}` + (queryCheckLast(query)) : '';

  let categoryquery = '';
  categoryFilterSelected.forEach((selected: FilterAccordionValues) => {
    categories.forEach((category: TicketProps) => {
      if (category.Id === selected.key) {
        categoryquery += (categoryquery === '' ? '' : ' or ') + `CategoryId eq ${category.Id}`;
      }
    });
  });
  query += categoryquery !== '' ? (queryCheckStart(query)) + `${categoryquery}` + (queryCheckLast(query)) : '';

  let assigneequery = '';
  assigneeFilterSelected.forEach((selected: FilterAccordionValues) => {
    if (selected.key === 0) {
      assigneequery += (assigneequery === '' ? '' : ' or ') + `AssigneeId eq null`;
    }
    assignees.forEach((assignee: PlatformUser) => {
      if (assignee.Id === selected.key) {
        assigneequery += (assigneequery === '' ? '' : ' or ') + `AssigneeId eq ${assignee.Id}`;
      }
    });
  });
  query += assigneequery !== '' ? (queryCheckStart(query)) + `${assigneequery}` + (queryCheckLast(query)) : '';

  let groupsquery = '';
  groupsFilterSelected.forEach((selected: FilterAccordionValues) => {
    if (selected.key === 0) {
      groupsquery += (groupsquery === '' ? '' : ' or ') + `SupportGroupId eq null`;
    }
    groups.forEach((group: ISupportGroup) => {
      if (group.Id === selected.key) {
        groupsquery += (groupsquery === '' ? '' : ' or ') + `SupportGroupId eq ${group.Id}`;
      }
    });
  });

  query += groupsquery !== '' ? (queryCheckStart(query)) + `${groupsquery}` + (queryCheckLast(query)) : '';

  let lifecyclequery = '';
  lifecycleFilterSelected.forEach((selected: FilterAccordionValues) => {
    if (selected.key === 0) {
      lifecyclequery +=
        (lifecyclequery === '' ? '' : ' or ') + `LifecycleId eq null`;
    }
    lifecycles.forEach((s: Lifecycle) => {
      if (s.Id === selected.key) {
        lifecyclequery +=
          (lifecyclequery === '' ? '' : ' or ') + `LifecycleId eq ${s.Id}`;
      }
    });
  });
  query +=
  lifecyclequery !== ''
      ? (queryCheckStart(query)) +
        `${lifecyclequery}` +
        (queryCheckLast(query))
      : '';

  return query;
};

interface SearchParams {
  id: string;
}

const ListFunctions = () => {
  const state = useContext(AppStateContext);
  const { listStates, setListStates } = useContext(ListContext);
  const { customViewStates } = useContext(CustomViewContext);
  const location = useLocation();

  const stateRef = useRef(listStates);

  const searchParams = location.search.substring(1).split('&').reduce((acc, param) => {
    const currentParamArr = param.split('=');
    acc = { ...acc, [currentParamArr[0]]: currentParamArr[1] };
    return acc;
  }, {} as SearchParams);

  const customViewId = searchParams?.id || getViewIdFromStorage('custom'); // sometimes searchParams?.id will exist but its relevant custom view will not. i.e; wrong id
  const defaultViewId = checkIfDefaultFilter(state.listFilter) ? state.listFilter : getViewIdFromStorage('default') ?? ListInputItemsEnum.All_Active;
  const isDefaultViewId = !customViewId || customViewStates.selectedCustomView === undefined; // customViewStates.selectedCustomView === undefined means 'searchParams?.id' exists but no custom_view exists for that id. i.e; invalid id

  const moveToPage = (action: string) => {
    setListStates((prev) => ({
      ...prev,
      isTicketsFetching: true,
    }));

    if (action === 'next') {
      setListStates((prev) => ({
        ...prev,
        isTicketsFetching: true,
        currentPage: prev.currentPage + 1,
      }));
      getpaginatedTickets({ page: listStates.currentPage + 1 });
    } else {
      setListStates((prev) => ({
        ...prev,
        isTicketsFetching: true,
        currentPage: prev.currentPage - 1,
      }));
      getpaginatedTickets({ page: listStates.currentPage - 1 });
    }
  };

  const getCurrentUser = () => {
    let currentUserValue = state.platformusers.find((user: PlatformUser) => user.Id === state.currentUserId);
    return currentUserValue;
  };

  const title: { [key: string]: string } = {
    Assignee: 'AssigneeId',
    Group: 'SupportGroupId',
    'Ticket Type': 'TicketTypeId',
    Due: 'DueDate',
    Lifecycle: 'TicketLifecycle/LifecycleId',
    Resolution: 'ResolutionDate',
    CreatedDate: 'CreatedDate',
    ModifiedDate: 'ModifiedDate',
    Tags: 't: t/Id',
    Requester: 'RequesterId',
    Collaborators: 'c: c/PlatformUserId',
    'Affected Users': 'a: a/PlatformUserId',
    DraftKB: "DraftKB"
  };

  const getStatusIdByGuid = (statusGuid: string) => {
    return state.ticketStatus.find((status: TicketProps) => status.Guid === statusGuid)?.Id;
  };

  const parentDefaultFilter: {
    0: () => FilterType;
    1: () => FilterType;
    2: () => FilterType;
    3: () => FilterType;
    4: () => FilterType;
  } = {
    0: () => ({ Closed: [false], Resolved: [false] }),
    1: () => ({ Closed: [false], 'AssigneeOrCollaborator': [getCurrentUser()?.Id], Resolved: [false] }),
    2: () => ({ Closed: [false], 'AssigneeId': [null], Resolved: [false] }),
    3: () => ({ StatusId: [getStatusIdByGuid(STATUS_GUID.CLOSED), getStatusIdByGuid(STATUS_GUID.RESOLVED)] }),
    4: () => ({ StatusId: [getStatusIdByGuid(STATUS_GUID.DEFLECTED)] }),
  };

  const objectFromSelectedFilters = (selectedFilterDropdownValues: FilterPropsItems[], isChild: boolean) => {
    let modifiedDateCustomView = null;
    let createdDateCustomView = null;
    const customFilters: FilterType = selectedFilterDropdownValues.reduce((acc, current) => {
      if (current.stringValue){
        const customFilter = { [current.key]: [{value: current.stringValue}] };
        acc = { ...acc, ...customFilter };
      }
      else
      {
        const titleKey = title[current.key] || `${current.key}Id`;
        const selectedValue = current.values.filter((value) => value.selected).map((value) => {
          if (!isChild && current.title === FILTERS.MODIFIED_DATE.title && !modifiedDateCustomView)
            modifiedDateCustomView = value.date;
          
          if (!isChild && current.title === FILTERS.CREATED_DATE.title && !createdDateCustomView)
            createdDateCustomView = value.date;
            
          // Due, Resolution, Created, Modified filter
          if (current.title === FILTERS.DUE.title || current.title === FILTERS.RESOLUTION.title || current.title === FILTERS.CREATED_DATE.title || current.title === FILTERS.MODIFIED_DATE.title)
            return value.key;
  
          return value.key === 0 ? null : value.key;
        });
        
        const customFilter = { [titleKey]: selectedValue };
        acc = { ...acc, ...customFilter };
      }
      
      return acc;
    }, {} as FilterType);

    return { customFilters, modifiedDateCustomView, createdDateCustomView };
  };

  // type object will be null
  const checkStringTypeForQuery = (value: boolean | string | number | object) => typeof value === 'string' ? `'${value}'` : `${value}`;
  const appendFilterQueryIfExists = (filterQuery: string) => filterQuery ? ' and ' + filterQuery : '';

  // converting object into query
  const _getFilterQuery = (filterObj: FilterType, childFiltervalues: FilterPropsItems[], modifiedDateFromCustomView?: any, createdDateFromCustomView?: any, expandedProperties?: FilterExpandDetails) => {
    // initially filterObj contains all values of dropdown filters as empty array
    let nonEmptyFilterQueryCount = 0; // if we do not select any custom filters then their values will be empty array
    let filterQuery = filterObj && Object.entries(filterObj).reduce((acc, current) => {

      const [title, selectedValues] = current as [string, any[]];
      let _title = title;
      
      if (expandedProperties.properties.includes(title)) _title=`${expandedProperties.name}/${title}`;

      if (['DueDate', 'ResolutionDate', 'CreatedDate', 'ModifiedDate'].includes(title)) return acc;
      acc = nonEmptyFilterQueryCount > 0 && selectedValues.length > 0 ? `${acc} and ` : acc;
      
      // if dropdownfilter is not an empty array
      if (selectedValues.length > 0) {
        nonEmptyFilterQueryCount++;
        
        const handleNull = title === "c: c/PlatformUserId" || title === "a: a/PlatformUserId";
        const filteredValues = handleNull ? selectedValues.filter(x => x != null) : selectedValues;

        let query = "";
        if (filteredValues.length === 1){
          
          let currentProp = childFiltervalues.find(x=>x.key==title || x.key+"Id"==title);
          if (currentProp?.FilterDataType==FilterDataType.String){

            query = `contains(${_title}, '${filteredValues[0]["value"]}')`;
          }
          else if (currentProp?.FilterDataType==FilterDataType.Boolean){
            query = `${_title} eq ${filteredValues[0]==1}`;
          }
          else
          {
            query = `${_title} eq ${checkStringTypeForQuery(filteredValues[0])}`;
          }
        }
        else
        {
          query = `${_title} in (${filteredValues.map(x => checkStringTypeForQuery(x)).join(',')})`;
        }

        if (title === "t: t/Id")
          query = `Tags/any(${query})`;
        else if (title === "Resolved")
          query = `StatusId ne ${getStatusIdByGuid(STATUS_GUID.RESOLVED)}`
        else if (title === "c: c/PlatformUserId")
          query = `TicketCollaborators/any(${query})`
        else if (title === "a: a/PlatformUserId")
          query = `AffectedUsers/any(${query})`
        else if (title === "AssigneeOrCollaborator")
          query = `AssigneeId eq ${checkStringTypeForQuery(filteredValues[0])} or TicketCollaborators/any(c: c/PlatformUserId eq ${checkStringTypeForQuery(filteredValues[0])})`


        if (handleNull && selectedValues.some(x => x == null)) 
          query = `${query} or ${(title === "c: c/PlatformUserId" ? 'not TicketCollaborators/any()' : 'not AffectedUsers/any()')}`;
          
        acc += `(${query})`;
      }

      return acc;
    }, '');

    const queryValue = state.filterDropdown.queryValue; // value entered in input field for searching values based on Request Column

    if (queryValue) {
      filterQuery = (/^\d+$/.test(queryValue) ? `(contains(Title, '${queryValue}') or Id eq ${queryValue})` : `(contains(Title, '${queryValue}'))`) + appendFilterQueryIfExists(filterQuery);
    }

    if (filterObj['DueDate']?.length > 0) {
      filterQuery = `${getDatesQuery(filterObj['DueDate'], 'DueDate')})${appendFilterQueryIfExists(filterQuery)}`;
    }

    if (filterObj['ResolutionDate']?.length > 0) {
      filterQuery = `${getDatesQuery(filterObj['ResolutionDate'], 'ResolutionDate')})${appendFilterQueryIfExists(filterQuery)}`;
    }
    if (filterObj['ModifiedDate']?.length > 0) {
      filterQuery = `${getDatesQuery(filterObj['ModifiedDate'], 'ModifiedDate', childFiltervalues, modifiedDateFromCustomView)})${appendFilterQueryIfExists(filterQuery)}`;
    }
    if (filterObj['CreatedDate']?.length > 0) {
      filterQuery = `${getDatesQuery(filterObj['CreatedDate'], 'CreatedDate', childFiltervalues, modifiedDateFromCustomView, createdDateFromCustomView)})${appendFilterQueryIfExists(filterQuery)}`;
    }
    if(filterQuery === 'undefined)'){
      return '';
    }
    return filterQuery;
  };

  const getFilterQuery = (customViewStates: CustomViewStatesType, FiltersDropdownValues?: FilterPropsItems[], defaultFilters?: FilterPropsItems[], sortOrderUponRefresh?: ISortOrder) => {
    let filterQuery = '';

    let isDefaultView: boolean;

    // this condition will run on first render or on refresh if id exists in search params
    if (customViewId && (FiltersDropdownValues || customViewStates?.selectedCustomView?.FiltersDropdownValues)) {
      isDefaultView = false; // view is not from default views
    } else {
      isDefaultView = true; // view is from default views
    }
       
    let expandResolution = {name:"Resolution", properties: ["DraftKB","ResolutionCategoryId","Note","InternalNote"]};
    const childFiltervalues = getChildFiltersFromStorage() || defaultFilters || state.filterDropdown.selectedValues;
    const customFilters = objectFromSelectedFilters(childFiltervalues, true);
    const parentFilter = isDefaultView
      ? {
          customFilters: parentDefaultFilter[defaultViewId](),
          modifiedDateCustomView: null,
          createdDateCustomView: null,
        }
      : objectFromSelectedFilters(
          FiltersDropdownValues ||
            customViewStates?.selectedCustomView?.FiltersDropdownValues,
          false // Means - not a child filter
        );

    let modifiedDateFromCustomView = parentFilter.modifiedDateCustomView ?? customFilters.modifiedDateCustomView;
    let createdDateFromCustomView = parentFilter.createdDateCustomView ?? customFilters.createdDateCustomView;

    const parentFilterQuery = _getFilterQuery(parentFilter.customFilters, childFiltervalues, modifiedDateFromCustomView, createdDateFromCustomView, expandResolution);
    const childFilterQuery = _getFilterQuery(customFilters.customFilters, childFiltervalues, modifiedDateFromCustomView, createdDateFromCustomView, expandResolution);
    const _childFilterQuery = (parentFilterQuery && childFilterQuery) ? ` and ${childFilterQuery}` : childFilterQuery;
      
    filterQuery = `${parentFilterQuery}${_childFilterQuery}`;
    filterQuery = filterQuery ? `$filter=${filterQuery}` : filterQuery;
    
    return `${filterQuery}&$orderby=${getOrderBy(sortOrderUponRefresh)}`;
  };

  const getOrderBy = (sortOrderUponRefresh?: ISortOrder) => {
    let orderBy = '';
    let sortOrder: ISortOrder =
    getSortOrderFromStorage() || 
    sortOrderUponRefresh ||
    customViewStates.configureColumnsApplied.sortOrder ||
    DEFAULT_SORT_ORDER_FOR_QUERYING;

    orderBy = `${TICKET_LIST_COLUMN_IDS[sortOrder.orderBy]} ${EnumSortOrderType[sortOrder.orderType]}`;

    return orderBy;
  }

  const _getpaginatedTicketsPromise = async ({
    page,
    customViewStates,
    FiltersDropdownValues,
    sortOrder: sortOrderUponRefresh,
    defaultFilters,
  }: {
    page: number;
    customViewStates: CustomViewStatesType;
    FiltersDropdownValues?: FilterPropsItems[]; // will exist, on hard refreshing or first time loading of tikets_list with custom view id either from search params or from local/session storage
    sortOrder?: ISortOrder; // will exist, on hard refreshing or first time loading of tikets_list
    defaultFilters?: FilterPropsItems[];
  }) => {

    let filterQuery = getFilterQuery(customViewStates, FiltersDropdownValues, defaultFilters, sortOrderUponRefresh);
    const ticketListQuery = `?$expand=Resolution,AffectedUsers($select=PlatformUserId),TicketCollaborators($select=PlatformUserId),TicketLifecycle($expand=Status,Lifecycle($select=Title),Phases($filter=IsCurrent eq true))&${filterQuery}&$top=${TICKET_PAGINATION.ITEM_PER_PAGE}&$count=true&$skip=${(page - 1) * TICKET_PAGINATION.ITEM_PER_PAGE}`;
    return api.getTicketList(ticketListQuery);
  };

  const getpaginatedTickets = async ({ page, FiltersDropdownValues, sortOrder, defaultFilters }: {
    page: number;
    FiltersDropdownValues?: FilterPropsItems[];
    sortOrder?: ISortOrder;
    defaultFilters?: FilterPropsItems[];
  }) => {
    try {
      const paginatedTickets = (
        await _getpaginatedTicketsPromise({
          page,
          customViewStates,
          FiltersDropdownValues,
          sortOrder,
          defaultFilters
        })
      )?.data;

      const tickets = paginatedTickets.value;
      attachSlas(tickets);
      attachData(tickets);

      setListStates((prev) => ({
        ...prev,
        isTicketsFetching: false,
        countTickets: paginatedTickets['@odata.count'],
        tickets: tickets, // data.value = tickets
      }));
    } catch (error) {
      console.error('Error occurred in fetching tickets', error);
    }
  };

  const attachData = async (data: any[], listContext?: ListContextType) => {
    try{
      for (let t of data)
        t = await retrieveTicketInformation(t, state);
      
      if (listContext) {
        listContext.setListStates((prev) => ({ ...prev, tickets: data }));
      } else {
        setListStates(prev => ({ ...prev, tickets: data }));
      }
    } catch (e) {
      console.error(`Error attaching SLAs`, e);
    }
  }
  const attachSlas = async (data: any[], listContext?: ListContextType) => {
    try {
      const ticketIds = data.map(x => x.Id);
      const slas = await api.getTicketSla(`${FILTERS_STRINGS.TICKET_SLA_FILTER}&$filter=TicketId in (${ticketIds.join(',')})`);
      data.forEach(x => { x.TicketSlas = slas.filter(s => s.TicketId === x.Id); });

      if (listContext) {
        listContext.setListStates((prev) => ({
          ...prev,
          tickets: data
        }));
      } else {
        setListStates(prev => ({
          ...prev,
          tickets: data
        }));
      }
    } catch (e) {
      console.error(`Error attaching SLAs`, e);
    }
  };

  const getpaginatedTicketsOnEdit = async (
    page: number,
    listContext?: ListContextType,
    customViewStates?: CustomViewStatesType
  ) => {
    try {

      let paginatedTickets = (await _getpaginatedTicketsPromise({ page, customViewStates }))?.data;

      if (listContext && listContext.listStates.currentPage > 1 && paginatedTickets?.value?.length === 0) {
        const currentPage = listContext.listStates.currentPage;

        // if we update a row which is only row in paginated_table(page) and current page is not first one, then currentPage will be decreased by one
        listContext.setListStates((prev) => ({ ...prev, currentPage: prev.currentPage - 1, }));
        paginatedTickets = (await _getpaginatedTicketsPromise({ page: currentPage - 1, customViewStates }))?.data;
      }

      const tickets = paginatedTickets.value;
      attachSlas(tickets, listContext);

      listContext.setListStates((prev) => ({
        ...prev,
        isTicketsFetching: false,
        countTickets: paginatedTickets['@odata.count'],
        tickets: tickets, // data.value = tickets
      }));

      // clear selected rows
      listContext.listStates.rowsSelection?.setAllSelected(false);
    } catch (error) {
      console.error('Error occurred in fetching tickets');
    }
  };

  const setTicketInterval = () => setInterval(() => {
    const page = stateRef.current.currentPage;
    getpaginatedTickets({ page: page });
  }, 300000);

  return {
    _getpaginatedTicketsPromise,
    getpaginatedTickets,
    getpaginatedTicketsOnEdit,
    moveToPage,
    searchParams,
    getStatusIdByGuid,
    getCurrentUser,
    customViewId,
    defaultViewId,
    isDefaultViewId,
    setTicketInterval,
    getFilterQuery
  };
};

export default ListFunctions;
