import { clone } from "lodash";
import { KBData } from "../../../AppState";
import { kbDataService, KBStateService } from "../../services/kb.service";
import { DateTime } from "luxon";

export const defaultResolution = {Note: "", InternalNote: "", ResolutionCategoryId:null, ResolutionCategory:null, DraftKB:false, AnswerId:null} as ResolutionDetail;

export interface IResolution{
    ticketData:any;
    onToolbarInteraction?:any;
    resolutionNoteConfig:TikitConfiguration;
    reset?:boolean;
    resetEvent?: () => Promise<void>;
    isReadOnly?:boolean;
    disableDraftKB?:boolean;
    isBulkEdit?:boolean;
    isEndUser?:boolean;
    setEnableSaveBtn?: React.Dispatch<React.SetStateAction<boolean>>;
    setUpdatedResolution?:React.Dispatch<React.SetStateAction<ResolutionDetail>>;
    loaded?: () => Promise<void>;
}

export interface TikitConfiguration {
    IsEnabled: boolean;
    RequireCategory: boolean;
    RequireNote: boolean;
    RequireInternalNote: boolean;
    ShowDraftKB: boolean;
    SurveysEnabled: boolean;
}

export const PropertyConfigMap = {
    ResolutionCategoryId:"RequireCategory",
    Note: "RequireNote",
    InternalNote: "RequireInternalNote"
};

export const ResolutionNoteState:TikitConfiguration = {
    IsEnabled: false,
    RequireCategory: true,
    RequireNote: true,
    RequireInternalNote: false,
    ShowDraftKB:false,
    SurveysEnabled: false
  };

export const ValidateResolutionDetails = (currentTicket, resolution:ResolutionDetail, resolutionNoteConfig:TikitConfiguration) => {
    if (currentTicket?.Resolution == undefined) currentTicket.Resolution=defaultResolution;
    
    for(let propName in PropertyConfigMap){
        let configName = PropertyConfigMap[propName];

        if (resolutionNoteConfig[configName] && (resolution[propName]==null || resolution[propName]==="")) return false;

        if (currentTicket.Resolution[propName] !== resolution[propName]) return true;
    }

    if (currentTicket.Resolution?.DraftKB !== resolution.DraftKB) return true;

    return false;
}

export const resolutionDetailsEmpty = () => clone({Note: "", InternalNote: "", ResolutionCategoryId:null, ResolutionCategory:null, DraftKB:false, AnswerId:null});

export const UpdateResolutionForm = (props: IResolution | ConversationProps, updatedResolution:ResolutionDetail, dispatch) => {


    props.ticketData.Resolution = updatedResolution;
    
      dispatch({ type: "set-ticket", data: props.ticketData });
      dispatch({ type: "set-defaut-ticket", data: props.ticketData  });
}

export const CheckIfResolutionIsChanged = (originalResolution, currentResolution) => currentResolution != undefined && 
        ((originalResolution?.Note != currentResolution?.Note) 
        || (originalResolution?.InternalNote != currentResolution?.InternalNote)
        || (originalResolution?.ResolutionCategoryId != currentResolution?.ResolutionCategoryId)
        || (originalResolution?.DraftKB != currentResolution?.DraftKB));

export const AddOrUpdateResolutionToDraftKB = async (resolution, originalTicket, currentState, translation) => {
    let answer = `**${translation('resolution-note.resolution-category')}:** ${resolution?.ResolutionCategory?.Value ?? ""}\n\r**${translation('resolution-note.resolution-note')}:** ${resolution?.Note}\n\r**${translation('resolution-note.resolution-note-internal')}:** ${resolution?.InternalNote}\n\r**Ticket Id:** ${originalTicket.Id}`;
    let newRow: KBData = KBStateService.addOneRow(
        [originalTicket.Title],
        answer,
        []
    );
    newRow.source = "kbnew";
    newRow.status = "Draft";
    newRow.agentOnly = true;
    if (resolution?.AnswerId!=null)
    {
        if (CheckIfResolutionIsChanged(originalTicket.Resolution, resolution)) 
        {
            const {data} = await kbDataService.fetchAnswerByIdAPI(resolution?.AnswerId);
            newRow.questions = data.questions;
            let currentUser = currentState.platformusers.find(x=>x.Id==currentState.currentUserId);
            newRow.id = resolution?.AnswerId;
            newRow.answer = `${data.answer}\n\r\n\r**---- ${translation('resolution-note.draft-KB-updated')} ${DateTime.now().toLocaleString(DateTime.DATETIME_MED)} ----**\n\r\n\r${answer}`;
            newRow.answer = newRow.answer.replace("{0}",currentUser.FullName);
            await kbDataService.updateSingleKB(newRow);
        }
        
        return resolution?.AnswerId;
    }
    else
    {
        await kbDataService.addAPI([newRow]);
        let updatedKb = await GetKb();
        return updatedKb[0].id;
    }
}

export const GetKb = async() =>{
    let kb = await kbDataService.fetchAPI();
    let values = JSON.parse(kb.data.value);
      values = values.sort(function (a, b) {
      return b["id"] - a["id"];
    });
  
    return values;
  }
