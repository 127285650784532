import React, { useCallback, useEffect, useState, useContext, useRef } from 'react';
import { ProviderConsumer as FluentUIThemeConsumer } from '@fluentui/react-northstar';
import { TicketToolbar } from './toolbar/TicketToolbar';
import { TicketBoardView } from './TicketBoardView/TicketBoardView';
import { TicketBoardMobileView } from './TicketBoardView/TicketBoardMobileView';
import {
  filterOptions,
  formatFilter,
  formatGroup,
  formatPlatformUser,
  formatTeam,
  ListInputItemsEnum,
  loadFilters,
  toggleButtonMapper,
  ViewType,
  formatLifecycle
} from './ticketHelper';
import { AccessDenied } from '../shared/components/AccessDenied';
import _ from 'lodash';
import { appState, useSetState } from '../AppState';
import update from 'immutability-helper';
import { platformService } from '../shared/services/platform.service';
import { ConsentState, SignedInConsentState } from '../shared/services/signInState.service';
import { Helmet } from 'react-helmet';
import { checkInTeams } from '../App';
import { BulkEditTicketDialog } from '../shared/components/BulkEditTicketDialog/BulkEditTicketDialog';
import { getCachedFeature } from '../shared/cache/FeatureCache';
import { FILTERS, CONTACT_ADMIN, LICENSE_FEATURE, PRODUCT_LICENSE, SOMETHING_WENT_WRONG, UNAUTHORIZED_ACCESS, customClassNames, FilterDataType } from '../shared/utils/constants';
import { Ticket } from '../shared/interfaces/ticket.interface';
import ListContextProvider, { ListContextType } from './List/ListContextProvider';
import ListFunctions from './List/ListFunctions';
import TicketToolbarSecondary from './toolbar/TicketToolbarSecondary';
import { CustomViewContext } from './toolbar/CustomViewContextProvider';
import CustomViewPopup from './toolbar/CustomViewPopup';
import { PlatformUser } from '../shared/interfaces/platformuser.interface';
import useWindowWidth from './useWindowWidth';
import { graphService } from '../shared/services/graph.service';
import { addUrlSearchParams, getChildFiltersCountFromStorage, getChildFiltersFromStorage, getChildFiltersInputValueFromStorage, getViewIdFromStorage } from './toolbar/CustomViewPopup/heplers/customPopupHelper';
import { useHistory, useLocation } from 'react-router-dom';
import { ListFeatureStates } from './List/List';
import { useTranslation } from 'react-i18next';



interface Props {
  page: string | undefined;
}

export const Tickets = ({ page }: Props) => {
  const {t} = useTranslation();
  const api = new platformService();
  const graphAPI = new graphService();
  const [isSignedIn] = SignedInConsentState.useIsSignedIn();
  const setState = useSetState();
  const history = useHistory();
  const location = useLocation();
  const currentstate = appState();
  const { setCustomViewStates } = useContext(CustomViewContext);
  const [filters, setFilters] = useState<FilterPropsItems[]>(filterOptions);
  const [defaultFilters, setDefaultFilters] = useState<FilterPropsItems[]>(filterOptions);
  const [hasAccess, setAccess] = useState(true);
  const [isCustomViewEnabled, setIsCustomViewEnabled] = useState(false);
  const [isTicketsFetching, setIsTicketsFetching] = useState(true);
  const [isFiltersFetching, setIsFiltersFetching] = useState(true);
  const [bulkEditDialog, setBulkEditDialog] = useState(false);
  const [selectedTickets, setSelectedTickets] = useState<Ticket[]>([]);
  const [isUserLicensed, setIsUserLicensed] = useState<boolean>(false);
  const [listFeatureStates, setListFeatureStates] = useState<ListFeatureStates>();

  const isReloadClickedRef = useRef(false);
  const prevPageRef = useRef(page);
  const windowWidth = useWindowWidth()

  const { getpaginatedTicketsOnEdit } = ListFunctions();

  const setError = (e: any) => {
    setAccess(e.response && e.response.status === 403);
    const errCode = e.response ? e.response.status : 500;
    let errorstate = {
      ...currentstate,
      isLoading: false,
      isError: true,
      errorConfig: {
        fields: {
          title:
            errCode == 401 || errCode == 403
              ? UNAUTHORIZED_ACCESS
              : SOMETHING_WENT_WRONG,
          desc: CONTACT_ADMIN
        }
      }
    };
    if (!_.isEqual(currentstate, errorstate)) {
      setState(errorstate);
    }
  };

  const updateSelectedFilters = (presistedValue: FilterAccordionValues, filterValue: FilterAccordionValues) => {
    for (const key in presistedValue) {
      if (Object.prototype.hasOwnProperty.call(presistedValue, key)) {
        const element = filterValue[key];
        if(element !== 'key' && element !== 'title'){
          filterValue[key] = presistedValue[key];
        }
      }
    }
  }
  const getUpdatedFilters = (newFilters: FilterPropsItems[]) => {
    const persistedDropdownValues: FilterPropsItems[] = getChildFiltersFromStorage();
    if (!persistedDropdownValues) return newFilters;

    // not returning storage value(getChildFiltersFromStorage()) directly, because newFilters can have some more added or deleted filters and storage will not have this update.
    const defaultFilters = JSON.parse(JSON.stringify(newFilters)) as FilterPropsItems[];
    persistedDropdownValues.forEach(persistedFilter => {
      let _filter = defaultFilters[persistedFilter.id];
      if(persistedFilter.FilterDataType==FilterDataType.String){
        _filter.stringValue=persistedFilter.stringValue;
      }
      else {
        persistedFilter.values.forEach(presistedValue => {
          if (presistedValue.selected) {
            let filterValue = _filter.values.find(value => value.key === presistedValue.key);
            // add all updated values from storage except key(which is unique) and title(which can also update and storage can have stale value)
            updateSelectedFilters(presistedValue, filterValue);
          }
        });
      }
    });
    return defaultFilters;
  }

  const prepareFiltersData = results => {
    // Update filters based on Status, Ticket Type, Category, Priority, Users, and Groups data

    // Status
    let updateStatusFilter = update(filters[FILTERS.STATUS.id], { values: { $set: formatFilter(results.ticketStatus) } });
    let newFilters: FilterPropsItems[] = update(filters, { $splice: [[FILTERS.STATUS.id, 1, updateStatusFilter]] });

    // Priority
    let updatePriorityFilter = update(filters[FILTERS.PRIORITY.id], { values: { $set: formatFilter(results.priority) } });
    newFilters = update(newFilters, { $splice: [[FILTERS.PRIORITY.id, 1, updatePriorityFilter]] });

    // Category
    let updateCategoryFilter = update(filters[FILTERS.CATEGORY.id], { values: { $set: formatFilter(results.categories) } });
    newFilters = update(newFilters, { $splice: [[FILTERS.CATEGORY.id, 1, updateCategoryFilter]] });

    // Ticket Type
    let updateTicketTypeFilter = update(filters[FILTERS.TICKET_TYPE.id], { values: { $set: formatFilter(results.ticketTypes) } });
    newFilters = update(newFilters, { $splice: [[FILTERS.TICKET_TYPE.id, 1, updateTicketTypeFilter]] });

    // Team
    let updateTeamFilters = update(filters[FILTERS.TEAM.id], { values: { $set: formatTeam(results.teams) } });
    newFilters = update(newFilters, { $splice: [[FILTERS.TEAM.id, 1, updateTeamFilters]] });

    // Group
    let updateGroupFilters = update(filters[FILTERS.GROUP.id], { values: { $set: formatGroup(results.supportgroups) } });
    newFilters = update(newFilters, { $splice: [[FILTERS.GROUP.id, 1, updateGroupFilters]] });

    // Assignee
    let updateAssigneeFilters = update(filters[FILTERS.ASSIGNEE.id], { values: { $set: formatPlatformUser(results.usersInRole) } });
    newFilters = update(newFilters, { $splice: [[FILTERS.ASSIGNEE.id, 1, updateAssigneeFilters]] });

    // Tags
    let updateTagsFilter = update(filters[FILTERS.TAGS.id], { values: { $set: formatFilter(results.tags) } });
    newFilters = update(newFilters, { $splice: [[FILTERS.TAGS.id, 1, updateTagsFilter]] });

    const uniqueUsers = getUniqueUsers(results.platformusers)
    let updateUsersFilter = update(filters[FILTERS.REQUESTERS.id], { values: { $set: formatPlatformUser(uniqueUsers) } });
    newFilters = update(newFilters, { $splice: [[FILTERS.REQUESTERS.id, 1, updateUsersFilter]] });

    // Affected Users
    let updateAffectedUsersFilter = update(filters[FILTERS.AffectedUsers.id], { values: { $set: formatPlatformUser(uniqueUsers) } });
    newFilters = update(newFilters, { $splice: [[FILTERS.AffectedUsers.id, 1, updateAffectedUsersFilter]] });

    // Collaborators
    let updateCollaboratorsFilters = update(filters[FILTERS.TicketCollaborators.id], { values: { $set: formatPlatformUser(results.usersInRole, true) } });
    newFilters = update(newFilters, { $splice: [[FILTERS.TicketCollaborators.id, 1, updateCollaboratorsFilters]] });

    let updateLifecycleFilters = update(filters[FILTERS.LIFECYCLE.id], { values: { $set: formatLifecycle(results.lifecycles) } });
    newFilters = update(newFilters, { $splice: [[FILTERS.LIFECYCLE.id, 1, updateLifecycleFilters]] });

    // Resolution Category
     let updateResolutionCategoryFilter = update(filters[FILTERS.ResolutionCategory.id], { values: { $set: formatFilter(results.resolutionCategory) } });
     newFilters = update(newFilters, { $splice: [[FILTERS.ResolutionCategory.id, 1, updateResolutionCategoryFilter]] });

    const UpdatedFilters = getUpdatedFilters(newFilters);
    setFilters(UpdatedFilters);
    setDefaultFilters(newFilters);
  };

  const fetchUserData = useCallback(async () => {
    try {
      const result = await api.meAuthenticate();

      let userIdClaim = result.data.Claims.find((claim: any) => claim.startsWith('preferred_username:'));
      let userEmail = userIdClaim?.split('preferred_username: ')[1] ?? '';
    
      const newState = { userImages: {}, currentUserEmail: userEmail };
      setState(prevState => ({ ...prevState, ...newState }));
    } catch (err) {
      console.log(`Error occurred when loading current user:`, err);
      setError(err);
    }
  }, []);

  const fetchCustomViews = async () => {
    try {
      const isEnabled = await getCachedFeature(LICENSE_FEATURE.CustomViews);
      setIsCustomViewEnabled(isEnabled);
      if (isEnabled) {
        const customViewsList = await api.getCustomViewsSafe();
        setCustomViewStates((prev): CustomViewStatesType => ({ ...prev, customViews: customViewsList }));
      }
      else {
        if (location.search) {
          const defaultViewId = getViewIdFromStorage('default') ?? ListInputItemsEnum.All_Active;
          addUrlSearchParams({ history, location, defaultViewId: defaultViewId + '' });
          setState((preState) => ({ ...preState, listFilter: defaultViewId }));
          setCustomViewStates((prev): CustomViewStatesType => ({ ...prev, view: 'none', customViews: [], selectedCustomView: null }));
        }
      }
    } catch (err) {
      console.log('Error while fetching custom views');
    };
  }

  const fetchTicketData = async () => {
    setState(preState => ({ ...preState, tickets: [], closedTickets: [] }));
    try {
      
      const results = await Promise.all([
        ((currentstate?.priority?.length ?? 0) > 0) ? Promise.resolve(currentstate) : loadFilters(api, graphAPI, currentstate, setState),
        getCachedFeature(LICENSE_FEATURE.TicketTypes),
        getCachedFeature(LICENSE_FEATURE.ServiceLevelAgreements),
        getCachedFeature(LICENSE_FEATURE.MultiDepartment),
        getCachedFeature(PRODUCT_LICENSE.AnalystAccess),
        getCachedFeature(LICENSE_FEATURE.TicketTags),
        getCachedFeature(LICENSE_FEATURE.TicketLifecycle),
        getCachedFeature(LICENSE_FEATURE.Approvals)
      ]);
  
      setIsUserLicensed(results[4]);
      setListFeatureStates(prev => ({
        ...prev,
        useTicketType: results[1],
        useTeam: results[3],
        useTicketLifecycle: results[5],
        useSLA: results[2]
      }));
      setIsFiltersFetching(false);
      prepareFiltersData(results[0]);
      await fetchUserData();
    } catch (err) {
      console.log(`Error occurred when loading filters:`, err);
      setError(err);
    } finally {
      setIsTicketsFetching(false);
      setState(state => ({ ...state, isReload: false }));
    }
  }

  const fetchTicketsHandler = () => {
    setSelectedTickets([]);
    setIsTicketsFetching(true);
    fetchTicketData();
    loadCustomViews();
  };

  const loadCustomViews = async () => {
    fetchCustomViews();
    setState((prevState) => ({ ...prevState,
      filterDropdown: { ...prevState.filterDropdown,
        queryValue: getChildFiltersInputValueFromStorage(),
        queryValueApplied: getChildFiltersInputValueFromStorage(),
      },
      filterDropdownCount: getChildFiltersCountFromStorage() || 0
    }));
  }

  useEffect(() => {
    if (isSignedIn == ConsentState.SignedIn && !isReloadClickedRef.current && !currentstate.isReload) {
      fetchTicketsHandler();
    }
    if (isSignedIn == ConsentState.SignedIn && currentstate.isReload) {
      isReloadClickedRef.current = true;
      fetchTicketsHandler();
    }
    if (!currentstate.isReload) {
      isReloadClickedRef.current = false;
    }  
  }, [isSignedIn, currentstate.isReload]);

  // page will change on toggling between "table/board/chart"
  useEffect(() => {
    const currentPage = page || 'table'; // page 'undefined' means its table page(list view)
    if(currentPage !== toggleButtonMapper[ViewType.chart]) localStorage.setItem('lastTicketPage', currentPage);
    const prevPageRefVariable = prevPageRef.current || 'table';
    if (prevPageRefVariable !== currentPage && currentPage !== toggleButtonMapper[ViewType.chart]) {
      fetchCustomViews();
    }
    prevPageRef.current = page;
  }, [page]);

  const containerClass = checkInTeams() ? `` : `height--50`;
  const scrollContainer = (page === toggleButtonMapper[ViewType.board] || page === toggleButtonMapper[ViewType.chart]) ? 'overflow-scroll' : 'overflow-hidden';

  return (
    <>
      <Helmet>
        <title>{t('ticket.toolbar.tickets')} - Tikit</title>
      </Helmet>
      {hasAccess ? (
        <FluentUIThemeConsumer
          render={(globalTheme) => (
            <ListContextProvider>
              <div className={`h-screen ${containerClass}`}>
                {!isTicketsFetching && (
                  <CustomViewPopup defaultFilters={defaultFilters} globalTheme={globalTheme} isCustomViewEnabled={isCustomViewEnabled} listFeatureStates={listFeatureStates} />
                )}
                <TicketToolbar
                  isCustomViewEnabled={isCustomViewEnabled}
                  isFiltersFetching={isFiltersFetching}
                  isUserLicensed={isUserLicensed}
                  globalTheme={globalTheme}
                  page={page}
                  filters={filters}
                  setFilters={setFilters}
                  defaultFilters={defaultFilters}
                  isEditMode={selectedTickets.length > 0}
                  onEditBulkHandler={() => {
                    setBulkEditDialog(true);
                  }}
                />
                <TicketToolbarSecondary defaultFilters={defaultFilters} globalTheme={globalTheme} page={page} isCustomViewEnabled={isCustomViewEnabled} />
                <div className={`${customClassNames.tikitBoardBody} ${scrollContainer} hidden md:block`}>
                  {windowWidth > 767 && (
                    <TicketBoardView
                      page={page}
                      isTicketsFetching={isTicketsFetching}
                      defaultFilters={defaultFilters}
                      onTicketsSelected={(tickets) => {
                        setSelectedTickets(tickets);
                      }}
                    />
                  )}
                </div>
                {windowWidth <= 767 && (
                  <TicketBoardMobileView
                    isTicketsFetching={isTicketsFetching}
                    defaultFilters={defaultFilters}
                  />
                )}
                <BulkEditTicketDialog
                  globalTheme={globalTheme}
                  open={bulkEditDialog}
                  onClose={(value) => {
                    setBulkEditDialog(value);
                  }}
                  tickets={selectedTickets}
                  onSuccessfullyUpdate={(listContext: ListContextType, customViewStates: CustomViewStatesType) => {
                    getpaginatedTicketsOnEdit(
                      listContext.listStates.currentPage,
                      listContext,
                      customViewStates
                    );
                  }}
                  listFeatureStates={listFeatureStates}
                />
              </div>
            </ListContextProvider>
          )}
        />
      ) : (
        <AccessDenied />
      )}
    </>
  );
};


function getUniqueUsers(data: PlatformUser[]) {
  function onlyUnique(value: PlatformUser, index: number, self: PlatformUser[]) {
    return (
      self.findIndex((user) => user.UserName === value.UserName) === index
    );
  }
  return data.filter(onlyUnique);
}
