import React, { useContext, useRef } from 'react';
import { FILTERS_STRINGS, MyWorkListViewType, TICKET_PAGINATION } from '../../../shared/utils/constants';
import { platformService } from '../../../shared/services/platform.service';
import { MyWorkContext } from '../MyWorkContextProvider';
import { EnumSortOrderType } from '../../toolbar/CustomViewContextProvider';
import { DEFAULT_SORT_ORDER_FOR_QUERYING } from '../../List/ListFunctions';
import { getSelectedTabFromStorage, getShowClosedFromStorage, getMyWorkSortOrderFromStorage } from './myWorkListHelper';
import { Route } from '../../../../route-constants';
import { useLocation } from 'react-router-dom';

const api = new platformService();

const useMyWorkListFunctions = () => {
  const { myWorkStates, setMyWorkStates } = useContext(MyWorkContext);
  const location = useLocation();
  const stateRef = useRef(myWorkStates);

  const isMyGroupWork = location.pathname.includes(Route.myGroupWork.pagePath);
  
  const moveToPage = (action: string) => {
    if (action === 'next') {
      setMyWorkStates(prev => ({
        ...prev,
        myWorkList: {
          ...prev.myWorkList,
          currentPage: prev.myWorkList.currentPage + 1
        }
      }));
      getpaginatedMyWorkList({ page: myWorkStates.myWorkList.currentPage + 1, selectedTabNumber: myWorkStates.myWorkList.selectedTabId });
    } else {
      setMyWorkStates(prev => ({
        ...prev,
        myWorkList: {
          ...prev.myWorkList,
          currentPage: prev.myWorkList.currentPage - 1
        }
      }));
      getpaginatedMyWorkList({ page: myWorkStates.myWorkList.currentPage - 1, selectedTabNumber: myWorkStates.myWorkList.selectedTabId });
    }
  };

  const MYWORK_LIST_COLUMNS_QUERY_VALUE: { [key in ISortOrderBy]: string } = {
    0: 'Icon',
    1: 'Id',
    2: 'Title',
    3: 'Assignee',
    4: 'StatusName',
    5: 'RequesterName',
    6: 'TeamName',
    7: 'SupportGroupName',
    8: 'TypeName',
    9: 'CategoryName',
    10: 'PriorityName',
    11: 'LastModified',
    12: 'CreatedDate',
    13: 'DueDate',
    14: 'ResolutionDate',
    15: 'LifecycleName',
    16: 'LifecyclePhaseName',
    17: 'Sla',
    18: 'AffectedUsers',
    19: 'Collaborators',
    20: 'Note',
    21: 'InternalNote',
    22: 'DraftKB',
    23: 'ResolutionCategory'
  };

  const _getpaginatedTicketsPromise = async ({ page, selectedTabNumber, showClosed, sortOrder }: IGetpaginatedMyWorkList) => {
    const skip = page * TICKET_PAGINATION.ITEM_PER_PAGE - TICKET_PAGINATION.ITEM_PER_PAGE;

    const isClosed = getShowClosedFromStorage(isMyGroupWork) ?? showClosed ?? myWorkStates.showClosed;
    const selectedTabId = getSelectedTabFromStorage(isMyGroupWork) ?? selectedTabNumber ?? Number(MyWorkListViewType.All) as MyWorkListDataType;
    sortOrder = getMyWorkSortOrderFromStorage(isMyGroupWork, selectedTabId) || sortOrder || myWorkStates.sortOrder[selectedTabId] || DEFAULT_SORT_ORDER_FOR_QUERYING;
    const orderBy = sortOrder.orderBy;
    const orderType = sortOrder.orderType;

    return api.getMyWorkListData(`(ViewTypeKey=${selectedTabId},IsClosed=${isClosed},IsGroupWork=${isMyGroupWork})?$orderby=${MYWORK_LIST_COLUMNS_QUERY_VALUE[orderBy]} ${EnumSortOrderType[orderType]}&$skip=${skip}&$top=${TICKET_PAGINATION.ITEM_PER_PAGE}`);
  };

  const attachSlas = async (data: any[]) => {
    try {
      const ticketIds = data.filter(x => x.ItemType === MyWorkListViewType.Tickets).map(x => x.Id);
      const slas = await api.getTicketSla(`${FILTERS_STRINGS.TICKET_SLA_FILTER}&$filter=TicketId in (${ticketIds.join(',')})`);

      data.forEach(x => {
        if (x.ItemType === MyWorkListViewType.Tickets) {
          x.TicketSlas = slas.filter(s => s.TicketId === x.Id);
        }
      });
      setMyWorkStates(prev => ({
        ...prev,
        myWorkList: {
          ...prev.myWorkList,
          allData: data
        }
      }));
    } catch (e) {
      console.error(`Error attaching SLAs`, e);
    }
  };

  const getpaginatedMyWorkList = async ({
    page = 1,
    selectedTabNumber,
    showClosed,
    sortOrder,
    hideLoading
  }: IGetpaginatedMyWorkList) => {
    try {
      if (!hideLoading) {
        setMyWorkStates(prev => ({
          ...prev,
          myWorkList: {
            ...prev.myWorkList,
            isFetching: true
          }
        }));
      }

      const data = (await _getpaginatedTicketsPromise({ page, selectedTabNumber, showClosed, sortOrder }))?.data;
      const { CountApprovals = 0, CountTickets = 0, CountTasks = 0 } = data.ItemsCount || {};
      const list = JSON.parse(data.Data);

      attachSlas(list);
      setMyWorkStates(prev => ({
        ...prev,
        myWorkList: {
          ...prev.myWorkList,
          isFetching: false,
          allData: list,
          dataCount: {
              [MyWorkListViewType.All]: CountTickets + CountTasks + CountApprovals,
              [MyWorkListViewType.Tickets]: CountTickets,
              [MyWorkListViewType.Tasks]: CountTasks,
              [MyWorkListViewType.Approvals]: CountApprovals,
          }
        }
      }));
    } catch (error) {
      console.error('Error occurred in fetching tickets', error);
    }
  };

  const setMyWorkListInterval = () => setInterval(() => {
    getpaginatedMyWorkList({ 
      page: stateRef.current.myWorkList.currentPage, 
      selectedTabNumber: stateRef.current.myWorkList.selectedTabId, 
      showClosed: stateRef.current.showClosed, 
      sortOrder: stateRef.current.sortOrder[stateRef.current.myWorkList.selectedTabId],
      hideLoading: true
    });
  }, 300000);

  return {
    moveToPage,
    getpaginatedMyWorkList,
    setMyWorkListInterval
  };
};

export default useMyWorkListFunctions;
