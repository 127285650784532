import React, { useEffect, useState } from 'react';
import { AddIcon, Button, Flex, Loader, ProviderConsumer,Text } from '@fluentui/react-northstar';
import Styles from '../../Automation.module.css';
import {
  AutomationOperators,
  AUTOMATION_CHILD_GROUP,
  DEFAULT_AUTOMATION_FILTER,
  AutomationToggleStyle,
  AUTOMATION_CREATE
} from '../../../shared/utils/constants';
import { AutomationGroup } from '../Shared/AutomationGroup/AutomationGroup';
import { GlobalRules } from './_/GlobalRules';
import { uniqueId, cloneDeep } from 'lodash';
import { Arrow } from '../Do/_/Arrow/Arrow';
import { Toggle } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

interface Props {
  propertiesList: DropdownDataModel[];
  ifGroupData: AutomationGroup;
  updateIfGroupData: (data: AutomationGroup) => void;
  ifChildGroupData: AutomationChildrenGroup[];
  updateIfChildGroupData: (data: AutomationChildrenGroup[]) => void;
  whenData: AutomationWhenData;
}

export const IF_ARROW_HEIGHT = 100;

export const If = ({
  propertiesList,
  ifGroupData,
  updateIfGroupData,
  ifChildGroupData,
  updateIfChildGroupData,
  whenData
}: Props) => {
  const {t} = useTranslation();
  const [globalRule, setGlobalRule] = useState(''); // Here because we have to reset the state when user clicks copy
  const copyGlobalRule = (value: string) => {
    const operator = value.toLowerCase().includes('assignee')
      ? AutomationOperators.AddedByAssignee
      : AutomationOperators.AddedByRequester;

      const operator2 = value.toLowerCase().includes('assignee')
      ? AutomationOperators.AddedByAssignee
      : AutomationOperators.AddedByRequester;

    const groupsClone = { ...ifGroupData, arrowHeight: 50 };
    let childGroupsClone = cloneDeep(ifChildGroupData);

    groupsClone.filters = [];
    groupsClone.filters[0] = {
      key: uniqueId('id'),
      Id: 0,
      evaluationType: '',
      eventActionType: '',
      property: "comments",
      operator,
      operator2,
      value: '',
      value2: '',
      GroupId: 0
    };
    childGroupsClone = [];

    updateIfGroupData(groupsClone);
    updateIfChildGroupData(childGroupsClone);

    setGlobalRule('');
  };

  const addNewGroup = () => {
    const childGroupsClone = cloneDeep(ifChildGroupData);
    childGroupsClone.push(AUTOMATION_CHILD_GROUP);
    updateIfChildGroupData(childGroupsClone);
  };

  const deleteChildGroup = (index: number) => {
    const childGroupsClone = cloneDeep(ifChildGroupData);
    childGroupsClone.splice(index, 1);
    updateIfChildGroupData(childGroupsClone);
  };

  const addNewFilter = (
    groupIndex: number,
    data: AutomationFilter = DEFAULT_AUTOMATION_FILTER
  ) => {
    if (groupIndex === undefined) {
      const groupsClone = {
        ...ifGroupData,
        arrowHeight: ifGroupData.arrowHeight + IF_ARROW_HEIGHT
      };
      groupsClone.filters.push({ ...data });
      updateIfGroupData(groupsClone);
    } else {
      const childGroupsClone = cloneDeep(ifChildGroupData);
      childGroupsClone[groupIndex] = {
        ...childGroupsClone[groupIndex],
        arrowHeight:
          childGroupsClone[groupIndex].arrowHeight <= 0
            ? 50
            : childGroupsClone[groupIndex].arrowHeight + IF_ARROW_HEIGHT
      };
      childGroupsClone[groupIndex].filters.push({
        ...data
      });
      updateIfChildGroupData(childGroupsClone);
    }
  };

  const removeFilter = (groupIndex: number, filterIndex: number) => {
    if (groupIndex === undefined) {
      const groupsClone = {
        ...ifGroupData,
        arrowHeight: ifGroupData.arrowHeight - IF_ARROW_HEIGHT
      };
      groupsClone.filters.splice(filterIndex, 1);
      updateIfGroupData(groupsClone);
    } else {
      const childGroupsClone = cloneDeep(ifChildGroupData);
      childGroupsClone[groupIndex] = {
        ...childGroupsClone[groupIndex],
        arrowHeight: childGroupsClone[groupIndex].arrowHeight - IF_ARROW_HEIGHT
      };
      childGroupsClone[groupIndex].filters.splice(filterIndex, 1);
      updateIfChildGroupData(childGroupsClone);
    }
  };

  const updateGroupFilters = (groupIndex: number, data: AutomationFilter[]) => {
    if (groupIndex === undefined) {
      const groupsClone = { ...ifGroupData };
      groupsClone.filters = [...data];
      updateIfGroupData(groupsClone);
    } else {
      const childGroupsClone = cloneDeep(ifChildGroupData);
      childGroupsClone[groupIndex].filters = [...data];
      updateIfChildGroupData(childGroupsClone);
    }
  };

  const updateGroupType = (groupIndex: number, type: GroupType) => {
    if (groupIndex === undefined) {
      const groupsClone = { ...ifGroupData };
      groupsClone.type = type;
      updateIfGroupData(groupsClone);
    } else {
      const childGroupsClone = cloneDeep(ifChildGroupData);
      childGroupsClone[groupIndex].type = type;
      updateIfChildGroupData(childGroupsClone);
    }
  };

  return (
    <ProviderConsumer 
      render={(globalTheme) => (
        <div className={Styles.WhenSection}>
      <h2 className={Styles.SubHeading2}>
      <Flex style={{clear:'both'}}>
      <Toggle
          checked={!ifGroupData.disabled}
          style={!ifGroupData.disabled ? AutomationToggleStyle.Enabled : AutomationToggleStyle.Disabled}
          onChange={(_ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
            const groupsClone = { ...ifGroupData };
            groupsClone.disabled=!checked;
            updateIfGroupData(groupsClone);
          }}
        />
        <Text content={AUTOMATION_CREATE.IF.TITLE} className="mr-2" />
      </Flex>
        
      </h2>
      

      {!ifGroupData.disabled && 
        <>

      {/* <GlobalRules
        copyGlobalRule={copyGlobalRule}
        globalRule={globalRule}
        updateGlobalRule={setGlobalRule}
      /> */}

      {ifGroupData.loading ? (
        <Loader label={t('common.loading')} />
      ) : (
        <>
          <div className={Styles.Groups}>
            {ifGroupData.arrowHeight > 0 && (
              <div className={Styles.IfParentGroupArrowContainer}>
                <Arrow height={ifGroupData.arrowHeight} />
              </div>
            )}
            <AutomationGroup
              propertiesList={propertiesList}
              groupData={ifGroupData}
              updateGroupFilters={updateGroupFilters}
              updateGroupType={updateGroupType}
              addNewFilter={addNewFilter}
              removeFilter={removeFilter}
              whenData={whenData}
              section="if"
            />
          </div>

          <div>
            {React.Children.toArray(
              ifChildGroupData.map(
                (childGroupData: AutomationChildrenGroup, index: number) => (
                  <div className={Styles.Groups}>
                    {childGroupData.arrowHeight > 0 && (
                      <div className={Styles.IfChildGroupArrowContainer}>
                        <Arrow height={childGroupData.arrowHeight} />
                      </div>
                    )}
                    <AutomationGroup
                      key={childGroupData.key}
                      propertiesList={propertiesList}
                      groupData={childGroupData}
                      groupIndex={index}
                      updateGroupFilters={updateGroupFilters}
                      updateGroupType={updateGroupType}
                      deleteChildGroup={deleteChildGroup}
                      addNewFilter={addNewFilter}
                      removeFilter={removeFilter}
                      whenData={whenData}
                      section="if"
                    />
                  </div>
                )
              )
            )}
          </div>
        </>
      )}
      <Flex styles={{ margin: '1.5rem 0' }}>
        <Button
          icon={<AddIcon styles={{color: globalTheme.siteVariables.colorScheme.brand.foreground}} />}
          text
          primary
          content={<span style={{color: globalTheme.siteVariables.colorScheme.brand.foreground}}>{t('automation.if.add-group')}</span>}
          onClick={addNewGroup}
        />
      </Flex>
        </>
      }
      
    </div>
      )}
    />
  );
};
