import {
  ActivityItem,
  Link,
  mergeStyleSets,
  Panel,
  PanelType,
  Separator,
  Stack
} from '@fluentui/react';
import {
  Text,
  Flex,
  Skeleton,
  Label,
  Chat,
  Box,
  ProviderConsumer,
  Card
} from '@fluentui/react-northstar';
import { FontIcon } from '@fluentui/react/lib/Icon';
import React, { useState } from 'react';
import { platformService } from '../../services/platform.service';
import RelativeDate from '../RelativeDate';
import { ConvertDateToLocale } from '../../common/ConvertDate';
import { UserForm } from '../../../user/UserForm';
import '../../common/StringExtensions';
import { CommonHelper } from '../../common/CommonHelper';
import { Comment } from '../../interfaces/ticket.interface';
import { PlatformUserAvatar } from '../PlatformUserAvatar';
import { Guid } from '../../services/guid.service';
import { UserAvatar } from '../UserAvatar';
import { ACTIVITY_FIELD, ACTIVITY_ACTION, markdownComponents, ACTIVITY_TASK_FILED } from '../../utils/constants';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import MergeIcon from '../../../../svg/merge-activity.svg';
import AddIcon from '../../../../svg/approval-added.svg';
import AcceptIcon from '../../../../svg/approval-accepted.svg';
import DeclinedIcon from '../../../../svg/approval-declined.svg';
import RelateIcon from '../../../../svg/relate-activity.svg';
import UnRelateIcon from '../../../../svg/unrelate-activity.svg';
import RemoveFileAttachmentsIcon from '../../../../svg/attach-delete.svg';
import RemoveUserIcon from '../../../../svg/remove-user.svg';
import { DateTime } from 'luxon';
import { themeNames } from '@fluentui/react-teams';
import ApprovalAddIcon from '../../../../svg/approval-added.svg';
import ApprovalAcceptIcon from '../../../../svg/approval-accepted.svg';
import ApprovalDeclinedIcon from '../../../../svg/approval-declined.svg';
import AddUserIcon from '../../../../svg/add-user.svg';
import LifecyclePhaseIcon from '../../../../svg/lifecycle-phase-icon.svg';
import LifecycleIcon from '../../../../svg/lifecycle.svg';
import AdditionalInfoIcon from '../../../../svg/additional-info-icon.svg';
import { appState } from "../../../AppState";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { PlatformUser } from '../../interfaces/platformuser.interface';
import { getFileIcon, getTruncatedFilename } from '../EditTicketAttachments/NewAttachmentFlow/NewAttachmentFlowContent';
import { ITeamsConfig } from '../../../config/TeamsConfig';
import { getUser } from '../../../AuthConfig';
import { LockSolidIcon } from '@fluentui/react-icons-mdl2';
import remarkBreaks from 'remark-breaks';
import { replaceTokenWithComponent } from '../../utils/helper';

const classNames = mergeStyleSets({
  activityItem: {
    marginTop: 0,
    padding: 0,
    marginLeft: 7,
    marginRight: -10
  },
  fileLink: {
    fontWeight: 600,
    color: 'var(--mgt-theme-brand-foreground1)',
    ':hover, :active, :focus': {
      color: 'var(--mgt-theme-brand-foreground1) !important'
    }
  },
  userLink: {
    paddingTop: '10px',
    color: 'var(--mgt-theme-brand-foreground1)',
    ':hover, :active, :focus': {
      color: 'var(--mgt-theme-brand-foreground1) !important'
    }
  },
  icon: {
    fontSize: 18,
    background: 'var(--mgt-theme-background2)',
    paddingBottom: '10px',
    paddingTop: '5px',
    color: '#8F90A6'
  },
  containerDiv: {
    paddingLeft: 15,
    position: 'relative',
    overflowY: 'scroll',
    maxHeight: 'calc(100vh - 200px) !important',
    zIndex: 2000
  },
  divider: {
    width: '9px',
    top: '25px',
    left: '23px',
    position: 'absolute',
    height: '100vh !important',
    ...{ '::before': { background: 'gray !important' } }
  },
  paddingTop10: {
    paddingTop: '10px'
  },
  lightBold: {
    fontWeight: 600
  },
  overlapLine: {
    position: 'absolute',
    left: 0,
    top: -10,
    height: '100vh',
    background: 'var(--mgt-theme-background2)',
    width: '100%',
    zIndex: 3000
  },
  automations: {
    paddingTop: '10px !important',
    backgroundColor: 'transparent !important',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    fontWeight: 600,
    fontSize: 'unset !important',
    color: 'var(--mgt-theme-default-foreground1) !important',
    height: 'auto !important'
  },
  commentDefault: {
    maxWidth: '100%',
    minHeiht: '16px',
    display: 'flex',
    borderRadius: '4px',
    margin: '5px 0 5px 0',
    flex: 'auto',
    overflow: 'hidden'
  },
  commentExpand: {
    maxWidth: '100%',
    height: '100%',
    display: 'flex',
    borderRadius: '4px',
    margin: '5px 0 5px 0',
    flex: 'auto'
  },
  toggleFlex: {
    position: 'absolute !important',
    right: -20,
    top: 25
  },
  syncButton: {
    minWidth: '0 !important',
    padding: '0 !important'
  },

  moreActivity: {
    marginTop: 20,
    paddingBlock: 10
  },
  activityAction: {
    color: 'var(--mgt-theme-default-foreground2)',
    fontWeight: 600
  },
  activityDefault: {
    color: 'var(--mgt-theme-default-foreground)'
  },
  hint: {
    color: 'var(--mgt-theme-default-foreground2)',
    fontWeight: 500
  },
  commentWithAttachments: {
    background: 'var(--mgt-theme-brand-background1)',
    '.ui-chat__message': {
      background: 'var(--mgt-theme-brand-background1)',
      width: '100%',
      maxWidth: '100%',
      margin: 0
    }
  },
  lifecycleIcons: {
    marginLeft: '19px',
    'svg' : {
      marginTop: '16px'
    },
    'i' : {
      marginTop: '0px',
    }
  }, 
  resolutionNoteContents: {
    fontSize:'larger !important',
    color: 'var(--mgt-theme-default-foreground)'
  },
  resolutionNoteContainer: {
    backgroundColor:'var(--mgt-theme-brand-background1)',
    padding:'10px',
    '.ms-Icon':{backgroundColor:'var(--mgt-theme-brand-background1)'}
  },
  internalResolutionNoteContainer: {
    border: '2px solid #6264A7',
    borderRadius:'4px'
  }
});
export interface ActivityItemProps {
  Id: number;
  TicketId: number;
  ModifierInfo: string;
  Field: string;
  Action: string;
  Value?: string;
  ModifiedDate?: string;
  Grouping?: string;
  ModifiedById?: number;
  RelatedTicketIds?: string;
}

interface TagProps {
  Id: number;
  ModifiedById: number;
  Name: string;
}

interface AUCProps {
  PlatformUserId: number;
  PlatformUser: PlatformUser;
}

export interface AttachementProps {
  Id: number;
  FileName: string;
  DownladUrl: string;
  ContentUrl: string;
  FileExtension: string;
  ModifiedById: number;
  HasComment?: boolean;
}

type UserProps = {
  Id: number;
  Name: string;
  Email: string;
};

const CONFIG = {
  QUERY_SKIP: 0,
  QUERY_TOP: 40,
  INCREMENT_SKIP: 40
};

const Constants = {
  Automations: 'Automations',
  TicketLifecycle: "Lifecycle"
};

interface ActivityStateProps {
  QuerySkip: number;
  Loading: boolean;
  Activities: ActivityItemProps[];
}

const defaultActivityState: ActivityStateProps = {
  QuerySkip: CONFIG.QUERY_SKIP,
  Loading: true,
  Activities: []
};

const api = new platformService();

export const getTicketActivities = async (
  ticketId: number,
  top: number,
  skip: number,
  commentOnly: boolean,
  filter: string
): Promise<ActivityItemProps[]> => {
  try {
    const ticketActivities = await api.getTicketActivities(
      ticketId,
      top,
      skip,
      commentOnly,
      filter
    );
    return Promise.resolve(ticketActivities.data.value);
  } catch (err) {
    return Promise.reject(err);
  }
};

// Utilities

const userInfo = (value: string): UserProps => {
  return CommonHelper.toObject<UserProps>(value);
};

const getRelationIcon = (activityAction: string) => {
  switch (activityAction) {
    case ACTIVITY_ACTION.MERGED:
      return <MergeIcon />;
    case ACTIVITY_ACTION.RELATED:
      return <RelateIcon />;
    case ACTIVITY_ACTION.UNRELATED:
      return <UnRelateIcon />;
    default:
      return <></>;
  }
}

const getTicketCollaboratorsIcon = (activityAction: string) => {
  switch (activityAction) {
    case ACTIVITY_ACTION.ADDED:
      return <AddUserIcon />;
    case ACTIVITY_ACTION.REMOVED:
      return <RemoveUserIcon />;
    default:
      return <></>;
  }
}

const getApprovalsIcon = (activityAction: string) => {
  switch (activityAction) {
    case ACTIVITY_ACTION.ADDED:
      return <AddUserIcon />;
    case ACTIVITY_ACTION.APPROVED:
      return <ApprovalAcceptIcon />;
    case ACTIVITY_ACTION.DECLINED:
      return <ApprovalDeclinedIcon />;
    case ACTIVITY_ACTION.CHANGED:
        return <AcceptIcon />;
    default:
      return <></>;
  }
}
const getTicketTaskIcon = (activityAction: string) => {
  switch (activityAction) {
    case ACTIVITY_ACTION.CREATED:
    case ACTIVITY_ACTION.ADDED:
      return <AddIcon />;
    case ACTIVITY_ACTION.CHANGED:
      return <AcceptIcon />;
    case ACTIVITY_ACTION.REMOVED:
      return <DeclinedIcon />;
    default:
      return <></>;
  }
}

const getFileAttachmentIcon = (activityAction: string) => {
  switch (activityAction) {
    case ACTIVITY_ACTION.ATTACHED:
      return <FontIcon className={classNames.icon} iconName='Attach' />
    case ACTIVITY_ACTION.REMOVED:
      return <RemoveFileAttachmentsIcon height='35' />
    default:
      break;
  }
}

const ticketLifecyclePhaseChange = (phase: TicketLifecyclePhase) => {
  return <>
    <span>
      <b>{phase.Name}</b> {(phase.IsManuallyTransitionedTo) && (<span> from phase <b>{phase.PreviousPhaseName}</b></span>)}
      {(phase.TransitionMessage?.length ?? 0) > 0 && (
        <Flex vAlign="center" gap="gap.smaller">
          <span><AdditionalInfoIcon /></span>{' '}
          <span className={classNames.hint}>{phase.TransitionMessage}</span>
        </Flex>
      )}
    </span>
  </>
}

const ticketLifecycleStatusChange = (lifecycle: TicketLifecycle) => (<span><b>{lifecycle.Status.Value}</b></span>);

const ticketTaskChange = (task: any, activity: ActivityItemProps, isEndUserPage: boolean) => {
  if (task.hasOwnProperty('Field')) {
    switch (task.Field) {
      case ACTIVITY_TASK_FILED.TITLE:
        return <>
          <span>
            Task {task.Field.normalize()} from <b>{task.OldTitle.toLocaleLowerCase()}</b> to{' '}<b>{task.Title.toLocaleLowerCase()}</b>
          </span>
        </>
      case ACTIVITY_TASK_FILED.STATUS_ID:
        const taskDataPhase: TicketLifecyclePhase = (task[ACTIVITY_TASK_FILED.LIFECYCLE_PHASE]?.length ?? 0) > 0 ? JSON.parse(task[ACTIVITY_TASK_FILED.LIFECYCLE_PHASE]) : null;

        let modifierInfo = userInfo(activity.ModifierInfo);
        if (modifierInfo.Name == Constants.TicketLifecycle)
          return <>
            <span>
              {' '} for task <b>{task.Title}</b>
            </span>
            {(taskDataPhase?.TransitionMessage ?? "").length > 0 && (
              <Flex vAlign="center" gap="gap.smaller">
                <AdditionalInfoIcon />{' '}
                <span className={classNames.hint}>{taskDataPhase.TransitionMessage}</span>
              </Flex>
            )}
          </>
        else
          return <>
            <span>
              {' '}<b>{task.Title}</b> {"Status".normalize()} <b>{task.Status.toLocaleLowerCase()}</b>
            </span>
          </>
      case ACTIVITY_TASK_FILED.ASSIGNEE_ID:
        return <>
          <span>
            {' '}<b>{task.Title}</b> {"Assignee".normalize()} <b>{task.Assignee?.toLocaleLowerCase()}</b>
          </span>
        </>
      default:
        return <></>
    }
  } else {
    switch (task.property) {
      case ACTIVITY_TASK_FILED.TITLE:
        return <span dangerouslySetInnerHTML={{ __html: i18next.t('ticket-details.ticket-conversation.tasks.title', { oldTitle: task.originalValue || "", newTitle: task.value }) }} />;
      case ACTIVITY_TASK_FILED.ASSIGNEE_ID:
        return <><span dangerouslySetInnerHTML={{ __html: i18next.t('ticket-details.ticket-conversation.tasks.assignee', { title: task.title || "" }) }} /><UserInfoLink user={{ Id: task.value } as UserProps} isEndUserPage={isEndUserPage} /></>;
      case ACTIVITY_TASK_FILED.STATUS_ID:
        let modifierInfo = userInfo(activity.ModifierInfo);
        if (modifierInfo.Name == Constants.TicketLifecycle)
          return <>
            <span dangerouslySetInnerHTML={{ __html: i18next.t('ticket-details.ticket-conversation.tasks.phase', { title: task.title || "" }) }} />
            {(task.transitionMessage?.length ?? 0) > 0 && (<Flex vAlign="center" gap="gap.smaller"><AdditionalInfoIcon /> <span className={classNames.hint}>{task.transitionMessage}</span></Flex>)}
          </>;

        return <span dangerouslySetInnerHTML={{ __html: i18next.t('ticket-details.ticket-conversation.tasks.status', { title: task.title || "", status: task.value }) }} />;
      default:
        let propertyString = i18next.t(`ticket-details.ticket-conversation.field.${task.property.toLocaleLowerCase()}`);
        return <span dangerouslySetInnerHTML={{ __html: i18next.t('ticket-details.ticket-conversation.tasks.field', { title: task.title || "", property: propertyString, value: task.value }) }} />;
    }
  }
}

const ticketApprovalChange = (approval: any, activity: ActivityItemProps, isEndUserPage: boolean) => {
  switch (approval.property) {
    case ACTIVITY_TASK_FILED.TITLE:
      return <span dangerouslySetInnerHTML={{ __html: i18next.t('ticket-details.ticket-conversation.approvals.title', { oldTitle: approval.originalValue || "", newTitle: approval.value }) }} />;
    default:
      let propertyString = i18next.t(`ticket-details.ticket-conversation.field.${approval.property.toLocaleLowerCase()}`);
      return <span dangerouslySetInnerHTML={{ __html: i18next.t('ticket-details.ticket-conversation.approvals.field', { title: approval.title || "", property: propertyString, value: approval.value }) }} />;
  }
}

const iconMapping = (activity: ActivityItemProps) => {
  if (userInfo(activity.ModifierInfo).Name === Constants.Automations)
    return 'Robot';

  switch (activity.Action) {
    case ACTIVITY_ACTION.COMMENTED:
      return 'CommentAdd';
    case ACTIVITY_ACTION.REMOVED:
      return 'RemoveFilter';
    case ACTIVITY_ACTION.ADDED:
      return 'CircleAddition';
    case ACTIVITY_ACTION.CHANGED:
      return 'FieldChanged';
    case ACTIVITY_ACTION.CREATED:
      return 'Ticket';
    case ACTIVITY_ACTION.RENAMED:
      return 'Rename';
    case ACTIVITY_ACTION.ATTACHED:
      return 'Attach';
    case ACTIVITY_ACTION.ASSIGNED:
      return 'FollowUser';
    default:
      return 'StatusCircleBlock';
  }
};
const descriptionMapping = (activity: ActivityItemProps, isEndUserPage: boolean, t: any) => {
  const _add_remove = (<span>{t(`ticket-details.ticket-conversation.field.${activity.Field.hyphenize()}`)}<b><PropertyResolver activity={activity} /></b></span>);
  const _relate_unrelate = (<span>{t("ticket-details.ticket-conversation.ticket")}: <b>{activity.RelatedTicketIds}</b></span>);
  const _approval_response = (<span>{<b>{activity.Value}</b>}</span>);

  switch (activity.Action) {
    case ACTIVITY_ACTION.CHANGED:
    case ACTIVITY_ACTION.TRANSITIONED:
      return <ChangeResolver activity={activity} isEndUserPage={isEndUserPage} />
    case ACTIVITY_ACTION.ADDED:
      return _add_remove;
    case ACTIVITY_ACTION.CREATED:
      return (activity.Field === ACTIVITY_FIELD.TICKET_TASKS) ? (<span><b>{activity.Value}</b></span>) : (<span>{t('ticket-details.ticket-conversation.created')}<b>{activity.TicketId}</b></span>);
    case ACTIVITY_ACTION.RENAMED:
      return (<span>{t(`ticket-details.ticket-conversation.field.${activity.Field.hyphenize()}`)}<b>{activity.Value}</b></span>);
    case ACTIVITY_ACTION.ASSIGNED:
      return (<span>{t(`ticket-details.ticket-conversation.to`)}{<AssignResolver activity={activity} isEndUserPage={isEndUserPage} />}</span>);
    case ACTIVITY_ACTION.REMOVED:
      return (activity.Field === ACTIVITY_FIELD.TICKET_TASKS)
        ? (
          <span>
            <b>{activity.Value}</b>
          </span>
        )
        : _add_remove;
    case ACTIVITY_ACTION.ATTACHED:
      return (<span><b><PropertyResolver activity={activity} /></b></span>);
    case ACTIVITY_ACTION.MERGED:
      return _relate_unrelate;
    case ACTIVITY_ACTION.RELATED:
      return _relate_unrelate;
    case ACTIVITY_ACTION.UNRELATED:
      return _relate_unrelate;
    case ACTIVITY_ACTION.APPROVED:
    case ACTIVITY_ACTION.DECLINED:
      return _approval_response;
    case ACTIVITY_ACTION.RESET_VOTES_FOR:
      let approvalTitle = "";
      let currentPhase = null;
      try{
        const value = JSON.parse(activity.Value);
        const approval: Approval = value["Approval"];
        currentPhase  = approval["TicketLifecyclePhase"]
      }catch{
        approvalTitle = activity.Value;
      }
      return <>
        <span>
           {' '}approval {<b>{approvalTitle}</b>}
        </span> 
        {(currentPhase?.TransitionMessage ?? "").length > 0 && (
          <Flex vAlign="center" gap="gap.smaller">
            <AdditionalInfoIcon />{' '}
            <span className={classNames.hint}>{currentPhase.TransitionMessage}</span>
          </Flex>
        )}
      </>;
    default:
      return <></>;
  }
};

const ChangeResolver = (props: { activity: ActivityItemProps, isEndUserPage: boolean }) => {
  
  let { activity, isEndUserPage } = props;
  

  if (activity.Field === ACTIVITY_FIELD.CARD_ANSWER) {
    return (<span>{i18next.t('ticket-details.ticket-conversation.field.card-answer')}</span>);
  } else if (activity.Field === ACTIVITY_FIELD.SURVEY) {
    return <><span>{i18next.t('ticket-details.ticket-conversation.field.survey')}<AssignResolver activity={activity} isEndUserPage={isEndUserPage} /></span></>;
  } else if (activity.Field === ACTIVITY_FIELD.TICKET_TASK || activity.Field === ACTIVITY_FIELD.TICKET_TASKS) {
    const value = JSON.parse(activity.Value);
    return ticketTaskChange(value, activity, isEndUserPage);
  } else if (activity.Field === ACTIVITY_FIELD.TICKET_APPROVAL) {
    const value = JSON.parse(activity.Value);
    return ticketApprovalChange(value, activity, isEndUserPage);
  } else if (activity.Field === ACTIVITY_FIELD.LIFECYCLE_PHASE) {
    const value: TicketLifecyclePhase = JSON.parse(activity.Value);
    return ticketLifecyclePhaseChange(value);
  } else if (activity.Field === ACTIVITY_FIELD.LIFECYCLE_STATUS) {
    const value: TicketLifecycle = JSON.parse(activity.Value);
    return ticketLifecycleStatusChange(value);
  } else if (activity.Field === ACTIVITY_FIELD.LIFECYCLE) {
    return (<span>{i18next.t('ticket-details.ticket-conversation.field.lifecycle')}<strong>{activity.Value}</strong></span>);
  
  } else if (isResolutionNoteField(activity)) {
    let txtHeader = i18next.t(`ticket-details.ticket-conversation.field.${activity.Field.hyphenize()}`);
    let iconComponent = replaceTokenWithComponent(txtHeader, "{0}", <LockSolidIcon style={{marginTop:'-6px'}} className='text-private' />);
  

    return <>
      <b>{iconComponent}</b>
      <span className={`${classNames.resolutionNoteContents} ui-box ei av xc xd xe ui-chat__message__content`}>
        <MarkdownCard activity={activity} />  
      </span>
    </>;
  } else {    
    if (activity.Field==ACTIVITY_FIELD.TIKCET_RESOLUTION_CATEGORY) {
      const state = appState();
      let resCat = state.resolutionCategory?.find(x=>x.Id.toString()==activity.Value);
      activity.Value = resCat?.Value ?? activity.Value;
    }
    
    return <span>{i18next.t(`ticket-details.ticket-conversation.field.${activity.Field.hyphenize()}`)}<AssignResolver activity={activity} isEndUserPage={isEndUserPage} /></span>;
  }
}



const isResolutionNoteField = (activity: ActivityItemProps) => ([ACTIVITY_FIELD.TICKET_RESOLUTION_NOTE, ACTIVITY_FIELD.TICKET_RESOLUTIONNOTE_INTERNAL].includes(activity.Field));

const resolutionNoteStyle = (activity: ActivityItemProps) => {
  let style = '';
  if (isResolutionNoteField(activity))
  { 
    style = classNames.resolutionNoteContainer;

    if (activity.Field==ACTIVITY_FIELD.TICKET_RESOLUTIONNOTE_INTERNAL) style += ` ${classNames.internalResolutionNoteContainer}`;
  }

  return style;

}

const PropertyResolver = (props: { activity: ActivityItemProps; }): JSX.Element => {
  let { activity } = props;
  const currentState = appState();
  
  if (activity.Field === ACTIVITY_FIELD.TAG) {
    let tag = CommonHelper.toObject<TagProps>(activity.Value);
    return <>{tag.Name}</>;
  }

  if (activity.Field === ACTIVITY_FIELD.TICKET_COLLABORATORS || activity.Field === ACTIVITY_FIELD.TASK_COLLABORATORS) {
    let ticketCollaborators = CommonHelper.toObject<AUCProps>(activity.Value);
    if (!ticketCollaborators.PlatformUser)
      ticketCollaborators.PlatformUser = currentState.platformusers.find(x => x.Id == ticketCollaborators.PlatformUserId);

    return <UserInfoLink user={{ ...ticketCollaborators.PlatformUser, Name: ticketCollaborators.PlatformUser?.FullName }} />;
  }

  if (activity.Field === ACTIVITY_FIELD.AFFECTED_USERS) {
    let affectedUser = CommonHelper.toObject<AUCProps>(activity.Value);
    if (!affectedUser.PlatformUser)
      affectedUser.PlatformUser = currentState.platformusers.find(x => x.Id == affectedUser.PlatformUserId);

    return <UserInfoLink user={{ ...affectedUser.PlatformUser, Name: affectedUser.PlatformUser?.FullName }} />;
  }

  if (activity.Field === ACTIVITY_FIELD.FILE_ATTACHMENT) {
    let attachment = CommonHelper.toObject<AttachementProps>(activity.Value);
    let isRemoved = activity.Action === ACTIVITY_ACTION.REMOVED ? true : false;

    return (
      <>
        {isRemoved ? (
          <span> {attachment.FileName} </span>
        ) : (
          <Link
            className={`${classNames.userLink} font-bold`}
            target="_blank"
            href={attachment.DownladUrl}
          >
            {attachment.FileName}
          </Link>
        )}
      </>
    )
  }
  return <>{activity.Value}</>;
};

const UserInfoLink: React.FC<{ user: UserProps; isEndUserPage?: boolean }> = (props: { user: UserProps; isEndUserPage?: boolean }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [platformUserId, setPlatformUserId] = useState(0);
  const currentState = appState();

  const setOpen = async () => {
    try {
      let user = currentState.platformusers.filter(x => x.Id == props.user.Id)[0];
      setPlatformUserId(user.Id);
    } catch {
      setIsError(true);
    } finally {
      setIsOpen(true);
    }
  };

  const currentUser = currentState.platformusers.find(x => x.Id == props.user.Id);
  if(!currentUser){
    return <b>{i18next.t('ticket.ticket-board.ticket-card.unassigned')}</b>;
  }

  return (
    <ProviderConsumer render={(globalTheme) => (
      <>
        {!(props.isEndUserPage ?? false) && (<>
          <Link className={`${classNames.userLink} font-bold`} onClick={setOpen}>{currentUser?.FullName ?? currentUser?.UserName}</Link>
          <Panel
            isLightDismiss
            isOpen={isOpen}
            onDismiss={() => setIsOpen(false)}
            closeButtonAriaLabel="Close"
            type={PanelType.large}
            className={globalTheme.siteVariables.theme === themeNames.Default ? "" : "panel--darkmode"}
          >
            {isError ? (
              <div>
                Unable to get <i>{props.user.Name}</i> info. Please contact your
                administrator.
              </div>
            ) : (
              <UserForm {...{ id: platformUserId?.toString(), isUserInfoOnly: true }} />
            )}
          </Panel>
        </>)}
        {(props.isEndUserPage ?? false) && (<span><strong>{currentUser?.FullName ?? currentUser?.UserName}</strong></span>)}
      </>
    )} />
  );
};

const MarkdownCard = (props: { activity: ActivityItemProps; }) => {
  const { activity } = props;
  return <Markdown remarkPlugins={[remarkGfm, remarkBreaks]} components={markdownComponents}>
            {activity.Value=="" ? "Empty" : activity.Value}
        </Markdown>
    
};

const AssignResolver = (props: { activity: ActivityItemProps; isEndUserPage: boolean; }) => {
  const { activity, isEndUserPage } = props;
  const { t } = useTranslation();

  if (activity.Field === ACTIVITY_FIELD.REQUESTER || activity.Field === ACTIVITY_FIELD.ASSIGNEE) {
    let user = userInfo(activity.Value);
    let userInfoShow = !isEndUserPage ? (
      <UserInfoLink user={user} />
    ) : (
      <b>{user?.Name}</b>
    );

    return user.Name === 'Unassigned' ? <b>{t('ticket.ticket-board.ticket-card.unassigned')}</b> : userInfoShow;
  }

  return <b>{fieldFormatting(activity.Field, activity.Value)}</b>;
};

const sepratorOptions = (isHide: boolean = false) => {
  return {
    root: {
      zIndex: 1001,
      ...{
        '::before': {
          backgroundColor: 'var(--mgt-theme-default-foreground2)',
          opacity: isHide ? 0 : 0.1,
          height: 1
        }
      },
      ...{
        '::after': {
          backgroundColor: 'var(--mgt-theme-default-foreground2)',
          opacity: isHide ? 0 : 0.1,
          height: 1
        }
      }
    },
    content: {
      backgroundColor: 'var(--mgt-theme-background2)',
      padding: '5px 5px 5px 0',
      fontWeight: '600'
    }
  };
};

const fieldFormatting = (field: string, value: string) => {
  if (field === 'DueDate' || field === 'ResolutionDate')
    return ConvertDateToLocale(value, DateTime.DATE_FULL);

  return value === 'Unassigned' ? i18next.t('ticket.ticket-board.ticket-card.unassigned') : value;
};

const getActivityIcon = (activity: any) => {

  if (activity.Action == ACTIVITY_ACTION.RESET_VOTES_FOR)
    return <LifecycleIcon height='35' />

  if (activity.Field == ACTIVITY_FIELD.TICKET_TASKS && activity.Action == ACTIVITY_ACTION.CHANGED) {
    const taskData = JSON.parse(activity.Value);
    const taskDataPhase: TicketLifecyclePhase = (taskData[ACTIVITY_TASK_FILED.LIFECYCLE_PHASE]?.length ?? 0) > 0 ? JSON.parse(taskData[ACTIVITY_TASK_FILED.LIFECYCLE_PHASE]) : null;
    if ((taskDataPhase?.LastCompletionDate ?? "").length > 0) {
      return <LifecycleIcon height='35' />
    }
  }

  switch (activity.Field) {
    case ACTIVITY_FIELD.RELATE_TICKET:
      return getRelationIcon(activity.Action);
    case ACTIVITY_FIELD.FILE_ATTACHMENT:
      return getFileAttachmentIcon(activity.Action);
    case ACTIVITY_FIELD.APPROVAL:
      return <ApprovalAddIcon />
    case ACTIVITY_FIELD.TICKET_APPROVAL:
    case ACTIVITY_FIELD.APPROVERS:
      return getApprovalsIcon(activity.Action);
    case ACTIVITY_FIELD.TASK_COLLABORATORS:
    case ACTIVITY_FIELD.TICKET_COLLABORATORS:
      return getTicketCollaboratorsIcon(activity.Action);
    case ACTIVITY_FIELD.AFFECTED_USERS:
      return getTicketAffectedUsersIcon(activity.Action);
    case ACTIVITY_FIELD.TICKET_TASK:
    case ACTIVITY_FIELD.TICKET_TASKS:
      return getTicketTaskIcon(activity.Action);
    case ACTIVITY_FIELD.LIFECYCLE_PHASE:
      return <LifecyclePhaseIcon />
    case ACTIVITY_FIELD.LIFECYCLE_STATUS:
      return <LifecycleIcon height='35' />
    default:
      return <FontIcon className={classNames.icon} iconName={iconMapping(activity)} />;
  }
}

const getActivityActionMessage = (activity: ActivityItemProps, t: any) => {
  switch (activity.Field) {
    case ACTIVITY_FIELD.SURVEY:
    case ACTIVITY_FIELD.LIFECYCLE:
      return "";
    case ACTIVITY_FIELD.TICKET_TASKS:
      let modifierInfo = userInfo(activity.ModifierInfo);
      return (modifierInfo.Name == Constants.TicketLifecycle && activity.Action != ACTIVITY_ACTION.CREATED) ? t(`ticket-details.ticket-conversation.actions.task-reset-state`) : `${t(`ticket-details.ticket-conversation.actions.${activity.Action}`)} ${t(`ticket-details.ticket-conversation.actions.task`)}`;
    case ACTIVITY_FIELD.LIFECYCLE_PHASE:
      return t(`ticket-details.ticket-conversation.actions.lifecycle-transitioned-phase`);
    case ACTIVITY_FIELD.LIFECYCLE_STATUS:
      return t(`ticket-details.ticket-conversation.actions.lifecycle-changed-status`);
    default:
      return t(`ticket-details.ticket-conversation.actions.${activity.Action}`);
  }
}

export const resolveCommentDate = (date: string) => {
  const normalize = (num: number) => Math.floor(Math.abs(num));

  let d = DateTime.fromJSDate(new Date(date));
  let day = d.toLocaleString({ day: 'numeric' });
  let today = DateTime.now().toLocaleString({ day: 'numeric' });

  if (normalize(d.diffNow().as('days')) < 1 && day === today)
    return d.toLocaleString(DateTime.TIME_SIMPLE);

  return ConvertDateToLocale(date, DateTime.DATETIME_MED);
};

// Export Components

export const dateStatusMapping = (d: DateTime): string => {
  const relativeDateString = () => {
    let day = d.toLocaleString({ day: 'numeric' });
    let today = DateTime.now().toLocaleString({ day: 'numeric' });
    let subDay = parseInt(today) - parseInt(day);

    if (normalize(d.diffNow().as('days')) > 1 || subDay > 1)
      return d.setLocale(i18next.language).toLocaleString(DateTime.DATE_HUGE);
    if (normalize(d.diffNow().as('days')) <= 1 && subDay === 1)
      return i18next.t('ticket-details.ticket-conversation.date.yesterday');

    return i18next.t('ticket-details.ticket-conversation.date.today');
  };

  const normalize = (num: number) => Math.floor(Math.abs(num));

  if (relativeDateString() === null) return '';

  return relativeDateString().toString();
};

const getTicketAffectedUsersIcon = (activityAction: string) => {
  switch (activityAction) {
    case ACTIVITY_ACTION.ADDED:
      return <AddUserIcon />
    case ACTIVITY_ACTION.REMOVED:
      return <RemoveUserIcon />
    default:
      return <></>;
  }
}
export const skeletonLoader = (commentWidth: string = '300px') => {
  return {
    comment: {
      contentPosition: 'end',
      gutter: (
        <Skeleton animation="wave">
          <Skeleton.Shape round width="25px" height="25px" />
        </Skeleton>
      ),
      message: {
        content: (
          <Stack style={{ width: '100%' }}>
            <Separator
              alignContent="end"
              style={{ marginRight: -2 }}
              styles={{ ...sepratorOptions(true) }}
            >
              <Box
                style={{
                  background: 'var(--mgt-theme-brand-background1)',
                  height: 50,
                  padding: 10,
                  width: commentWidth
                }}
              >
                <Skeleton animation="wave">
                  <Skeleton.Line width="100px" height="10px"></Skeleton.Line>
                </Skeleton>
                <Skeleton animation="wave">
                  <Skeleton.Line height="20px"></Skeleton.Line>
                </Skeleton>
              </Box>
            </Separator>
          </Stack>
        ),
        style: { width: '100%' }
      },
      style: { overflow: 'hidden' },
      key: `activity-id-${Guid.newGuid()}`
    },
    activity: {
      contentPosition: 'end',
      message: {
        content: (
          <>
            {[1, 2, 3].map(k => {
              return (
                <Stack style={{ width: '100%' }} key={k}>
                  <Separator
                    alignContent="end"
                    style={{ marginRight: -2 }}
                    styles={{ ...sepratorOptions() }}
                  >
                    <Flex gap="gap.small" style={{ paddingLeft: 5 }}>
                      <Flex.Item>
                        <Skeleton animation="wave">
                          <Skeleton.Line
                            width="25px"
                            height="18px"
                          ></Skeleton.Line>
                        </Skeleton>
                      </Flex.Item>
                      <Flex.Item>
                        <Skeleton animation="wave">
                          <Skeleton.Line
                            width="240px"
                            height="18px"
                          ></Skeleton.Line>
                        </Skeleton>
                      </Flex.Item>
                    </Flex>
                    <Flex space="between">
                      <div></div>
                      <Flex>
                        <Flex.Item push></Flex.Item>
                        <Flex.Item>
                          <Skeleton animation="wave">
                            <Skeleton.Line
                              width="50px"
                              height="12px"
                            ></Skeleton.Line>
                          </Skeleton>
                        </Flex.Item>
                      </Flex>
                    </Flex>
                  </Separator>
                </Stack>
              );
            })}
          </>
        ),
        style: { width: '100%' }
      },
      style: { overflow: 'hidden' },
      key: `activity-id-${Guid.newGuid()}`
    }
  };
};

export const useActivity = () => {
  const [activityState, setActivities] = useState<any>(defaultActivityState);
  const getTicketActivitiesById = async (
    entityId: number,
    commentOnly: boolean,
    filter: string = '',
    isTrigger = false
  ) => {

    try {
      let topNumber = 0;
      let skipNumber = 0;
      let skipIncrement = 0;

      if (activityState.Loading || !isTrigger) {
        topNumber = CONFIG.QUERY_TOP;
        skipIncrement = CONFIG.INCREMENT_SKIP;
      }

      if (isTrigger) {
        topNumber = CONFIG.QUERY_TOP;
        skipNumber = activityState.QuerySkip;
        skipIncrement = activityState.QuerySkip + CONFIG.INCREMENT_SKIP;
      }

      let apiResult = await Promise.all([
        api.getTicketActivities(entityId, topNumber, skipNumber, commentOnly, filter)
      ]);
      let result = apiResult[0].data.value;
      let data = (isTrigger) ? [...result, ...activityState.Activities] : result;

      setActivities({ Activities: data, Loading: false, QuerySkip: skipIncrement });

      return {
        activities: data,
        endOfList: result.length < CONFIG.QUERY_TOP
      };

    } catch (error) {
      return defaultActivityState;
    }

  };

  return [activityState, getTicketActivitiesById];
};

const getActivityItem = (activity: ActivityItemProps, position: string, isEndUserPage: boolean) => {
  let displayAsLifecycleAction = false;
  if (activity.Action == ACTIVITY_ACTION.TRANSITIONED || activity.Action == ACTIVITY_ACTION.RESET_VOTES_FOR)
    displayAsLifecycleAction = true;
  else if (activity.Field === ACTIVITY_FIELD.LIFECYCLE && activity.Action === ACTIVITY_ACTION.CHANGED)
    displayAsLifecycleAction = true;
  else if (activity.Field == ACTIVITY_FIELD.TICKET_TASKS) {
    if (activity.Action == ACTIVITY_ACTION.CREATED && !activity.ModifiedById)
      displayAsLifecycleAction = true;
    if (activity.Action == ACTIVITY_ACTION.CHANGED) {
      const taskData = JSON.parse(activity.Value);
      const taskDataPhase: TicketLifecyclePhase = (taskData[ACTIVITY_TASK_FILED.LIFECYCLE_PHASE]?.length ?? 0) > 0 ? JSON.parse(taskData[ACTIVITY_TASK_FILED.LIFECYCLE_PHASE]) : null;
      if ((taskDataPhase?.LastCompletionDate ?? "").length > 0)
        displayAsLifecycleAction = true;
      else if (!taskData.hasOwnProperty(ACTIVITY_TASK_FILED.LIFECYCLE_PHASE) && !activity.ModifiedById)
        displayAsLifecycleAction = true;
    }
  } else if (activity.Action == ACTIVITY_ACTION.ADDED && !activity.ModifiedById && (activity.Field == ACTIVITY_FIELD.APPROVAL || activity.Field == ACTIVITY_FIELD.APPROVERS))
    displayAsLifecycleAction = true;
  
  if (displayAsLifecycleAction) {
    position = "start";
    let displayLifecycleAsUser = true;

    if(activity.Action == ACTIVITY_ACTION.TRANSITIONED){
      const lifecycleData = JSON.parse(activity.Value);
      displayLifecycleAsUser = !lifecycleData.IsManuallyTransitionedTo; 
    }

    if(displayLifecycleAsUser){
      activity.ModifierInfo = `{\"Name\":\"Lifecycle\",\"Id\":0,\"Email\":\"\"}`;
    }
  }

  return {
    contentPosition: position,
    gutter: position === 'start' ? <div className={classNames.lifecycleIcons}>{getActivityIcon(activity)}</div> : <></>,
    message: {
      content: (
        <Flex className={`items-center justify-end`} style={{ width: '100%' }}>
          <TicketActivity
            position={position}
            activityItem={activity}
            isNoIcon={position === 'start' ? true : false}
            isEndUserPage={isEndUserPage}
          />
        </Flex>
      ),
      style: { width: '90%' }
    },
    style: { overflow: 'hidden' },
    key: `activity-id-${activity.Id}`
  };
}

export const chatActivityItem = (activity: ActivityItemProps, position: string, signedInUser: any, isEndUserPage: boolean, teamsConfig: ITeamsConfig, globalTheme: any) => {

  const regexExp = "<tikit-img.*?itemid=[\"'](.+?)[\"'].*?></tikit-img>";

  if (activity.Action.trim() === "") {
    return;
  }

  if (activity.Field === ACTIVITY_FIELD.COMMENTS) {
    let comment = JSON.parse(activity.Value || '{}') as Comment;
    const withEmptyBody = comment.Body.replaceAll(new RegExp("<tikit-comment-empty.*?></tikit-comment-empty>", "gi"), '');
    if (withEmptyBody.length == 0 && (comment.FileAttachments?.length ?? 0) == 0)
      return;
    const modifierInfo = userInfo(activity.ModifierInfo);
    let userInfoShow = comment.CreatedById == null ? (<Label className={classNames.automations}>{Constants.Automations}</Label>)
      : !isEndUserPage ? (<UserInfoLink user={modifierInfo} />)
        : (<b>{modifierInfo?.Name}</b>);

    const replaceTikitImgToMarkDown = (str, attachments) => {
      const urlPattern = new RegExp(regexExp, "gi");
      const matches = str.match(urlPattern) || [];
      let strLength = str.length;
      let resultStr = str;

        matches.forEach(function (match) {
            let itemId = match.match(/itemid=[\"'](.+?)[\"']/i)[1];
            let matchIndex = str.indexOf(match);
            let attachment = attachments.find(x => x.ContentUrl?.includes(itemId));
            
            if (attachment) {
                let link = `${window.__runtimeConfig.platformurl}teams/thumbnail/${teamsConfig.TenantId}/${attachment.DriveItemGroupId}/${attachment.DriveItemId}/${attachment.Id}?size=medium`;
                let formatImg = `[![${attachment.FileName} -tikitimg](${link})](${attachment.DownladUrl})`;
                let i = (strLength === resultStr.length) ? matchIndex : matchIndex + (resultStr.length - strLength);
                resultStr = resultStr.slice(0, i) + formatImg + resultStr.slice(i + match.length);
            }
        });
        return resultStr
                .replace(/\n/g, '&nbsp; \n')
                .replace(/<tikit-comment-empty.*?><\/tikit-comment-empty>/gi, '');
    }

    const filterInlineImages = (str, attachments) => {
      const urlPattern = new RegExp(regexExp, "gi");
      const matches = str.match(urlPattern) || [];
      let filteredAttachments = attachments;
      matches.forEach(function (match) {
          let itemId = match.match(/itemid=[\"'](.+?)[\"']/i)[1];
          filteredAttachments = filteredAttachments.filter(x => !x.ContentUrl?.includes(itemId));
      });
      return filteredAttachments;
    }

    const renderElements = () => {
      let element;
      let attachments = filterInlineImages(comment.Body, comment.FileAttachments);
      for (let i = 0; i < attachments.length; i += 2) {
        let item1 = attachments[i];
        let item2 = attachments[i+1];
        const el = <Flex className='mx-4 my-4' gap='gap.medium'>
          <div>
            <Card aria-roledescription="card avatar" style={{ width: '250px' }}>
              <Card.Header fitted>
                <Flex gap="gap.small">
                  {getFileIcon(item1.FileExtension, item1, globalTheme)}
                  <Flex column>
                    <Link
                      target="_blank"
                      href={item1.DownladUrl}
                      className={classNames.fileLink}
                    >
                      {getTruncatedFilename(item1.FileName)}
                    </Link>
                  </Flex>
                </Flex>
              </Card.Header>
            </Card>
          </div>
          {item2 && (
            <div>
              <Card aria-roledescription="card avatar" style={{ width: '250px' }}>
                <Card.Header fitted>
                  <Flex gap="gap.small">
                    {getFileIcon(item2.FileExtension, item2, globalTheme)}
                    <Flex column>
                      <Link
                        target="_blank"
                        href={item2.DownladUrl}
                        className={classNames.fileLink}
                      >
                        {getTruncatedFilename(item2.FileName)}
                      </Link>
                    </Flex>
                  </Flex>
                </Card.Header>
              </Card>
            </div>
          )}
        </Flex>;
        element = <>{element}{el}</>;
      }
      return element;
    }


    const checkIfUserInMention = (commentBody: string) => {
      const regex = new RegExp(`.*\\[[^\\]]+\\]\\(mention:` + getUser().toLowerCase().replace(/\./g, "\\.") + `\\).*`);
      return regex.test(commentBody);
    }

    const chatMessageStyle = () :React.CSSProperties  => {
      if((comment?.FileAttachments?.length ?? 0) === 0 && position !== 'end') {
        return {minWidth: '170px', marginRight: '8px'}
      } else if((comment?.FileAttachments?.length ?? 0) > 0 && position !== 'end') {
        return {minWidth: '170px', marginLeft: '16px', marginRight: '8px', paddingLeft: '0px'}
      }  else if((comment?.FileAttachments?.length ?? 0) > 0 && position === 'end') {
        return {minWidth: '170px', marginLeft: '16px', paddingLeft: '0px'}
      } else {
        return {minWidth: '170px', marginLeft: '16px'}
      }
    }

    return {

      contentPosition: position,
      gutter:
        position === 'end' && comment.CreatedById != null ?
          <UserAvatar {...{ idOrUpn: signedInUser.Email, avatarProps: { name: signedInUser.FullName, size: 'smaller' } }} /> :
          <PlatformUserAvatar id={comment.CreatedById} />,
      message: {
        className: (comment?.FileAttachments?.length ?? 0) === 0 ? 'w-5/6' : 'not-full',
        content: (
          <Flex className="w-full items-center">
            <Flex column className={`${(comment?.FileAttachments?.length ?? 0) > 0 ? classNames.commentWithAttachments : ''} w-full`}>

              <Flex className={`${position === 'end' ? `items-center justify-end` : `items-center`}`}>
                <Chat.Message
                  style={chatMessageStyle()}
                  content={
                    <Markdown remarkPlugins={[remarkGfm, remarkBreaks]} components={markdownComponents}>
                      {
                        replaceTikitImgToMarkDown(comment.Body, comment.FileAttachments)
                      }
                    </Markdown>
                  }
                  author={userInfoShow}
                  header={
                    <Text
                      size='small'
                      timestamp
                      color='grey'
                      content={
                        <div className='flex items-center'>
                          <span className='leading-5 pr-2'>
                            {resolveCommentDate(comment.CreatedDate)}
                          </span>
                          {!comment.IsPublic &&
                            <LockSolidIcon className='text-private' />
                          }
                        </div>
                      }
                    />
                  }
                  {...(position === 'end' && { mine: true })}
                />
                
                {((comment?.FileAttachments?.length ?? 0) === 0 && position !== 'end' && checkIfUserInMention(comment.Body.toLowerCase())) && (
                  <Text content="@" atMention="me" size='larger'/>
                )}
              </Flex>
              <div>
                <>
                  {(comment?.FileAttachments?.length ?? 0) > 0 && (
                    <>
                      {renderElements()}
                    </>
                  )}
                </>
              </div>
            </Flex>

            {((comment?.FileAttachments?.length ?? 0) > 0 && position !== 'end' && checkIfUserInMention(comment.Body.toLowerCase())) && (
              <Text content="@" atMention="me" size='larger' className='pl-2'/>
            )}
          </Flex>
        )
      },
      key: `activity-id-${activity.Id}`,
    };
  } else {
    return getActivityItem(activity, position, isEndUserPage);
  }
};

export const TicketActivity: React.FC<{
  activityItem: ActivityItemProps;
  position: string;
  isNoIcon: boolean;
  isEndUserPage?: boolean;
}> = (props: any): JSX.Element => {
  const { t } = useTranslation();
  let { activityItem, position, isNoIcon, isEndUserPage } = props;

  const ActivityItemMapping: React.FC<{ activity: ActivityItemProps }> = (activityProps: any): JSX.Element => {
    let { activity } = activityProps;
    let modifierInfo = userInfo(activity.ModifierInfo);
    let userInfoShow = !isEndUserPage ? (<UserInfoLink user={modifierInfo} />) : (<b>{modifierInfo?.Name}</b>);

    let modifierElem = modifierInfo.Name === Constants.Automations ? (<Label className={classNames.automations}>{Constants.Automations}</Label>) : (userInfoShow);
    if (modifierInfo.Name === Constants.TicketLifecycle)
      modifierElem = (<Label className={classNames.automations}>{t(`lifecycle.lifecycle`)}</Label>);

    if (activity.Field === ACTIVITY_FIELD.LIFECYCLE && activity.Action === ACTIVITY_ACTION.CHANGED){
      modifierElem = (<></>);
    }
      

    let item = {
      key: activity.Id,
      activityDescription: [
        modifierElem,
        <span className={`${classNames.paddingTop10} ${classNames.activityAction}`}>
          {' '}{getActivityActionMessage(activity, t)}{' '}
        </span>
        ,
        <span key={3} className={isResolutionNoteField(activity) ? '' : `${classNames.paddingTop10} ${classNames.activityAction}`}>
          {descriptionMapping(activity, isEndUserPage, t)}
        </span>
      ],
      timeStamp: (
        <RelativeDate key={4} date={activity.ModifiedDate} isNotShowTime={true} />
      ),
      ...(!isNoIcon && {
        activityIcon: getActivityIcon(activity)
      })
    };

    return (<ActivityItem {...item} key={item.key} className={`${classNames.activityItem} ${resolutionNoteStyle(activity)}`} />);
  };

  return (
    <Stack style={{ width: '100%', zIndex: 1 }}>
      <Separator alignContent={position} styles={{ ...sepratorOptions() }}>
        <span className={`text-left ${classNames.activityDefault}`}>
          <ActivityItemMapping activity={activityItem} />
        </span>
      </Separator>
    </Stack>
  );
};
