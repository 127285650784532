import React, { useContext, useEffect, useRef, useState } from 'react';
import { AddIcon, Dropdown, ThemePrepared } from '@fluentui/react-northstar';
import AppCSS from '../../App.module.css';
import ToolbarCSS from './Toolbar.module.css';
import './TableMenu.css';
import { listInputItems } from '../ticketHelper';
import { appState, useSetState } from '../../AppState';
import useComponentVisible from './useComponentVisible';
import { CustomViewContext, DEFAULT_SORT_ORDER } from './CustomViewContextProvider';
import { addUrlSearchParams, setChildFiltersInStorage, setConfiguredColumnsKeyOrderInStorage, setSelectedCustomView, setSortOrderInStorage } from './CustomViewPopup/heplers/customPopupHelper';
import { useHistory, useLocation } from 'react-router-dom';
import useSelectedViewName from './CustomViewPopup/useSelectedViewName';
import { themeNames } from '@fluentui/react-teams';
import { ADMINISTRATORS, ANALYSTS } from '../../shared/utils/constants';
import { ThemeColorScheme } from '../../shared/common/TeamsTheme';
import { clearFilterStates } from '../../shared/components/Filter';
import { useTranslation } from 'react-i18next';

const DropdownTitle = ({ children }) => {
  return (
    <div className='py-1' role='none'>
      <div
        className={`block px-4 py-2 text-sm cursor-default ${ToolbarCSS.parentDropdownTitle}`}
        role='menuitem'
        tabIndex={-1}
        id='menu-item-6'
      >
        {children}
      </div>
    </div>
  );
};
interface PropsI {
  defaultFilters: FilterPropsItems[];
  globalTheme: ThemePrepared<any>;
  isCustomViewEnabled: boolean;
}

export const TableMenu = ({ defaultFilters, globalTheme, isCustomViewEnabled }: PropsI) => {
  const location = useLocation();
  const history = useHistory();
  const currentState = appState();
  const {t} = useTranslation();

  const setState = useSetState();
  const viewName = useSelectedViewName();

  const { customViewStates, setCustomViewStates } = useContext(CustomViewContext);
  const [showDropdown, setShowDropdown] = useState<number | boolean>(false);
  const [hoverState, setHoverState] = useState<boolean>(false);

  const isMounted = useRef(false);
  const { parentRef, ref } = useComponentVisible(setShowDropdown, isMounted.current);

  const isDarkMode = globalTheme.siteVariables.theme === themeNames.Dark;
  const sortedViews = [...(customViewStates?.customViews ?? [])].sort((a, b) => a.Name.localeCompare(b.Name));

  const onClickDefaultView = (id: number) => {
    addUrlSearchParams({ history, location, defaultViewId: id + '' });

    const page = localStorage.getItem('lastTicketPage');

    // Set this to a non-existent view, then set it to the right one via setTimeout to force a refresh
    setState(s => ({ ...s, listFilter: -100, isReload: (page === 'board') }));
    setTimeout(() => setState(s => ({ ...s, listFilter: id, isReload: false })), 0);

    clearFilterStates(setState, defaultFilters);

    setCustomViewStates((prev): CustomViewStatesType => ({
      ...prev,
      view: 'none',
      selectedCustomView: null,
      configureColumnsApplied: {
        prevSortOrder: null,
        sortOrder: null
      },
      configurableColumnsKeyOrder: []
    }));
    setShowDropdown(false);
    
    setConfiguredColumnsKeyOrderInStorage();
    setSortOrderInStorage();
    setChildFiltersInStorage()
  };

  const onClickCustomView = (customView: CreateCustomView) => () => {
    setSelectedCustomView({
      defaultFilters,
      customView,
      setCustomViewStates,
      history,
      location,
    });

    setState((s) => ({
      ...s,
      listFilter: Math.random(),
    }));
    clearFilterStates(setState, defaultFilters);
    
    setShowDropdown(false);

    setConfiguredColumnsKeyOrderInStorage();
    setSortOrderInStorage();
    setChildFiltersInStorage()
  };

  const onClickAddNewView = () => {
    setCustomViewStates((prev): CustomViewStatesType => ({
      ...prev,
      showCustomViewModal: !prev.showCustomViewModal,
      view: 'add',
      columns: {
        columnsKeyOrder: [],
        selectedColumns: [],
        sortOrder: DEFAULT_SORT_ORDER,
      }
    }));
  };

  useEffect(() => {
    isMounted.current = true;
  }, []);

  return (
    <>
      <div className='relative inline-block text-left' style={{ backgroundColor: '--mgt-theme-background', ...ThemeColorScheme(globalTheme.siteVariables) }}>
        <div className='relative'>
          <div
            className='absolute top-0 right-0 h-full w-full z-10 cursor-pointer bg-transparent'
            onMouseEnter={() => setHoverState(true)} onMouseLeave={() => setHoverState(false)}
            id='parent'
            onClick={() => setShowDropdown(!showDropdown)}
            ref={parentRef}
          ></div>
          <Dropdown
            items={[]}
            triggerButton={<span>{viewName}</span>}
            fluid
            className={`${AppCSS.dropdownItem} ${ToolbarCSS.hideItems} ${hoverState && ToolbarCSS.filterHoverColor} dropdown-btn`}
          />
        </div>

        {showDropdown && (
          <div
            className={`origin-top-left right-0 md:origin-top-right md:left-0 w-60 absolute rounded-md ring-1 ring-black ring-opacity-5 divide-y focus:outline-none max-h-3/4 overflow-y-scroll 
            ${ToolbarCSS.secondaryToolbarBg} ${isDarkMode && 'divide-gray-500'}`}
            role='menu'
            aria-orientation='vertical'
            aria-labelledby='menu-button'
            tabIndex={-1}
            id='child'
            ref={ref}
          >
            {isCustomViewEnabled && <div className={``}><DropdownTitle>{t('ticket.ticket-list.configure-column.system-default')}</DropdownTitle></div>}
            <div className='py-1' role='none'>
              {Object.entries(listInputItems).map(([key, item]) => (
                <div
                  key={key}
                  className={`cursor-pointer block px-4 py-2 text-sm ${ToolbarCSS['custom-view-item']}`}
                  role='menuitem'
                  tabIndex={-1}
                  id='child'
                  onClick={() => onClickDefaultView(Number(key))}
                >
                  {item}
                </div>
              ))}
            </div>
            {isCustomViewEnabled && <div className={`divide-y ${isDarkMode && 'divide-gray-500'}`}>
              <DropdownTitle>{t('ticket.ticket-list.configure-column.custom-views')}</DropdownTitle>
              {(currentState?.userRoles?.roles?.includes(ADMINISTRATORS) || currentState?.userRoles?.roles?.includes(ANALYSTS)) && <div role='none'>
                <div
                  className={`hidden md:flex justify-between items-center cursor-pointer px-4 py-2 text-sm ${ToolbarCSS['custom-view-add-btn']}`}
                  style={{ color: globalTheme.siteVariables.colorScheme.brand.foreground }}
                  role='menuitem'
                  tabIndex={-1}
                  id='child'
                  onClick={onClickAddNewView}
                >
                  {t('ticket.ticket-list.configure-column.add-new-view')}
                  <AddIcon styles={{ color: globalTheme.siteVariables.colorScheme.brand.foreground }} />
                </div>
              </div>}
              <div role='none'>
                {sortedViews?.map((customView) => (
                  <div
                    className={`flex justify-between items-center cursor-pointer px-4 py-2 text-sm ${ToolbarCSS['custom-view-item']}`}
                    style={{ color: globalTheme.siteVariables.colorScheme.brand.foreground }}
                    role='menuitem'
                    id='child'
                    tabIndex={-1}
                    key={customView.Id}
                    onClick={onClickCustomView(customView)}
                  >
                    {customView.Name}
                  </div>
                ))}
              </div>
            </div>}
          </div>
        )}
      </div>
    </>
  );
};
