import React from 'react';
import _ from 'lodash';
import { platformService } from '../shared/services/platform.service';
import { EnumList } from '../enum-list/EnumList';
import { PRIORITY } from '../shared/utils/constants';
import { useTranslation } from 'react-i18next';

export const PriorityList = () => {
  const {t} = useTranslation();
  const api = new platformService();
  
  const updatePriority = async (param:any) => {
    await api.updatePriority(param);
  }

  const createPriority = async (param:any) => {
    return await api.createPriority(param as PriorityDetail);
  }

  

  const getList = async(param?:string) => {
    return await api.getPriority(param ?? `?v=${new Date().getTime()}`);
  }

  return (
    <EnumList
      typeName={'ServiceDesk.Core.Models.Pickers.Ticket.Priority'}
      updateData={updatePriority}
      createData={createPriority}
      getList={getList}
      entityName={"Priority"}
      title={PRIORITY.PRIORITY}
      pageTitle={t('priority.title')}
      successMgs={t('priority.changes-are-saved')}
      featchingListMsg={PRIORITY.GETTING_ALL_PRIORITIES}
      updateMsg={PRIORITY.UPDATING_PRIORITY}
      userRoleHasAccess={true}
    />
  );
};
