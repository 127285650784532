import React from 'react';
import _ from 'lodash';
import { platformService } from '../shared/services/platform.service';
import { TICKET_TYPE } from '../shared/utils/constants';
import { useTranslation } from 'react-i18next';
import { EnumList } from '../enum-list/EnumList';

export const TicketTypeList = () => {
  const {t} = useTranslation();
  const api = new platformService();

  const update = async (param:any) => {
    await api.updateTicketType(param);
  }

  const create = async (param:any) => {
    return await api.createTicketType(param as PriorityDetail);
  }


  const getList = async(param?:string) => {
    return await api.getTicketTypes(param ?? `?v=${new Date().getTime()}`);
  }

  return (
    <EnumList
      typeName={'ServiceDesk.Core.Models.Pickers.Ticket.TicketType'}
      updateData={update}
      createData={create}
      getList={getList}
      entityName={"TicketType"}
      title={TICKET_TYPE.TICKET_TYPE}
      pageTitle={t('ticket-types.title')}
      successMgs={t('status.changes-are-saved')}
      featchingListMsg={TICKET_TYPE.GETTING_ALL_TICKET_TYPES}
      updateMsg={TICKET_TYPE.UPDATING_TICKET_TYPE}
      userRoleHasAccess={true}
    />
  );
};
