import { TransitionRuleGroup, LifecycleTransition, TransitionAction, LifecyclePhase } from "./typings";
import {  i18n } from '../../../components/shared/utils/helper';
const { t } = i18n();

export const DEFAULT_LIFECYCLE_INFO_DATA = {
    Id: 0,
    Title: '',
    Description: '',
    SetStatusResolved: true,
    ResolutionNote: '',
    ResolutionNoteInternal: '',
    ResolutionCategoryId: 0,
    ResolutionCategory: null
};

export enum TransitionActionTypeString
{
    MoveToNextPhase = "MoveToNextPhase",
    MoveToPreviousPhase = "MoveToPreviousPhase",
    MoveToPhaseX = "MoveToPhaseX",
    FailLifecycle = "FailLifecycle"
}

export enum LogicOperatorTypeString {
    And = "And",
    Or = "Or"
}

export const TransitionRulePropertyTypeString_Label =
{
  Task: t('lifecycle.task'),
  Approval: t('lifecycle.approval'),
  PowerAutomateTask: t('lifecycle.power-automate-task')
}

export enum TransitionRulePropertyTypeString
{
    Task = "Task",
    Approval = "Approval",
    PowerAutomateTask = "PowerAutomateTask"
}

export enum TicketApprovalStateString {
    Approved = "1",
    Rejected = "2",
    Pending = "3"
}

export enum TicketPowerAutomateStatusString {
  Failed = "1",
  Cancelled = "2",
  Succeeded = "3"
}

export const LIFECYCLE_ACTION_TYPE_LABELS =
{
    MoveToNextPhase: t('lifecycle.move-next'),
    MoveToPreviousPhase: t('lifecycle.move-prev'),
    MoveToPhaseX: t('lifecycle.move-phase'),
    FailLifecycle: t('lifecycle.move-fail')
}

export const LIFECYCLE_ACTION_DROPDOWN = [
    { key: 'MoveToNextPhase', label: LIFECYCLE_ACTION_TYPE_LABELS.MoveToNextPhase, header: LIFECYCLE_ACTION_TYPE_LABELS.MoveToNextPhase },
    { key: 'MoveToPreviousPhase', label: LIFECYCLE_ACTION_TYPE_LABELS.MoveToPreviousPhase, header: LIFECYCLE_ACTION_TYPE_LABELS.MoveToPreviousPhase },
    { key: 'MoveToPhaseX', label: LIFECYCLE_ACTION_TYPE_LABELS.MoveToPhaseX, header: LIFECYCLE_ACTION_TYPE_LABELS.MoveToPhaseX },
    { key: 'FailLifecycle', label: LIFECYCLE_ACTION_TYPE_LABELS.FailLifecycle, header: LIFECYCLE_ACTION_TYPE_LABELS.FailLifecycle }
];

export const createDropdownDataModel = (options: any[]) => {
    var items:DropdownDataModel[] = [];
    options.forEach((data: any) => {
        items.push({
        Id: data.Id,
        key: data.Guid,
        label: data.Name,
        header: data.Name
        });
    });
    return items;
};

export const LifecycleOdataTypes = {
    Task: "#ServiceDesk.Core.Models.TicketFlow.LifecycleTask",
    Approval: "#ServiceDesk.Core.Models.TicketFlow.LifecycleApproval",
    Phase: "#ServiceDesk.Core.Models.TicketFlow.LifecyclePhase",
    PowerAutomateTask: "#ServiceDesk.Core.Models.TicketFlow.LifecyclePowerAutomateTask",
}

export const INITIAL_LIFECYCLETRANSITION_DATA = {
    Id: 0,
    Title: '',
    Message: '',
    UseDefaultLogic: true,
    IsExpand: false,
    ArrowHeight: {
      height: 0,
      isUp: false
    },
    TransitionAction: {
      Id: 0,
      ActionType: TransitionActionTypeString.MoveToNextPhase,
      TransitionItem: null,
      TransitionItemId: 0
    } as TransitionAction,
    TransitionActionId: 0,
    TransitionRule: {
      Id: 0,
      TransitionRuleGroups: [
        {
          Id: 0,
          LogicOperatorType: LogicOperatorTypeString.And,
          TransitionRuleId: 0,
          TransitionRuleCriterias: [
            {
              Id: 0,
              TransitionItem: null,
              TransitionItemId: 0,
              TransitionRuleGroupId: 0
            }
          ]
        } as TransitionRuleGroup
      ],
      LogicOperatorType: LogicOperatorTypeString.And
    },
    TransitionRuleId: 0
  } as LifecycleTransition;

export const INITIAL_LIFECYCLEPHASE_DATA = {
    Order: 0,
    Id: 0,
    Name: '',
    Guid: '',
    LifecycleId: 0,
    IsExpand: true,
    Tasks: [],
    Approvals: [],
    PowerAutomateTasks: [],
    Transitions: [INITIAL_LIFECYCLETRANSITION_DATA]
  } as LifecyclePhase

  