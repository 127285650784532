import React, { useEffect, useReducer, useState } from 'react';
import { FormCheckbox, Loader, ProviderConsumer as FluentUIThemeConsumer } from "@fluentui/react-northstar";
import { NestedDropdown } from '../NestedDropdown/NestedDropdown';
import { useTranslation } from 'react-i18next';
import { LabelElement } from '../TicketForm';
import { appState } from '../../../AppState';
import { clone } from "lodash";
import {IResolution, ValidateResolutionDetails, resolutionDetailsEmpty, defaultResolution} from './ResolutionHelper';
import { ADMINISTRATORS, ANALYSTS, KNOWLEDGEAGENT, STATUS_GUID } from '../../utils/constants';
import './style.css';
import SlateEditor, { markdownToSlate, slateToMd } from '../Wysiwyg/SlateEditor';
import { themeNames } from '@fluentui/react-teams';
import { getStatusIdByGuid } from '../../../tikit/ticketHelper';
import { platformService } from '../../services/platform.service';


export const ResolutionDetails = (props: IResolution) => {
    const state = appState();
    const api = new platformService();
    const { t } = useTranslation();
    const currentState = appState();

    const [isResolutionRole, setIsResolutionRole] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isReadOnly, setIsReadOnly] =  useState(false);


    const actionEnums = {
        AssignResolution: 'AssignResolution',
        UpdateResolution: 'UpdateResolution'
    }
    const entityTicketReducer = (resolutionState : ResolutionDetail, action) => {
        switch (action.action) {
        case "AssignResolution":
            resolutionState = action.data;
            return {
                ...resolutionState
            };
          case actionEnums.UpdateResolution:
            resolutionState[action.property] = action.data;
            return {
              ...resolutionState
            };
          default:
            return {
              ...resolutionState
            };
        }
      
      }

    const [resolutionState, dispatch] = useReducer(entityTicketReducer, defaultResolution);

    useEffect(()=>{
        if (props.setEnableSaveBtn) props.setEnableSaveBtn(ValidateResolutionDetails(props.ticketData,resolutionState, props.resolutionNoteConfig));
        if (props.setUpdatedResolution) props.setUpdatedResolution(resolutionState);
    },[resolutionState]);
    
    

    useEffect(()=>{
        loadData();
    },[props.ticketData.Resolution]);

    useEffect(()=>{
        if (props.reset){
            loadData();
            if (props.resetEvent) props.resetEvent()

        }
    },[props.reset]);


    const loadData = async () => {
        setIsLoaded(false);
        let resolution=null;
        if (props.ticketData?.Id){
            resolution = (await api.getTicket(props.ticketData?.Id,`?$select=ResolutionId&$expand=Resolution`))?.data?.Resolution;
        
            if (resolution){
                dispatch({ action: actionEnums.AssignResolution, data: resolution});
            }
            else dispatch({ action: actionEnums.AssignResolution, data: clone(resolutionDetailsEmpty())});
        }
        else dispatch({ action: actionEnums.AssignResolution, data: clone(resolutionDetailsEmpty())});
        
        
        let currentSelectedId = resolution?.ResolutionCategoryId ?? 0;
        
        if (props.ticketData.Resolution==undefined){
            props.ticketData.Resolution = resolutionDetailsEmpty();
        }

        let selectedCat = clone(state.resolutionCategory.find(x=>x.Id==currentSelectedId));
        
        dispatch({ action: actionEnums.UpdateResolution, property:"ResolutionCategory", data: selectedCat ?? null});


        if (props.resolutionNoteConfig.RequireCategory && currentSelectedId==null) props.ticketData.Resolution.ResolutionCategory=null;
        setIsResolutionRole(currentState.userRoles.roles.some(x=>[ADMINISTRATORS,ANALYSTS,KNOWLEDGEAGENT].includes(x)));

        if (props.isReadOnly==undefined){
            const resolvedStatusId = getStatusIdByGuid(currentState.ticketStatus, STATUS_GUID.RESOLVED);
            setIsReadOnly(props.ticketData?.StatusId != resolvedStatusId);
        }
        else setIsReadOnly(props.isReadOnly);

        setIsLoaded(true);
        if (props.loaded) props.loaded();
        
    }

    return (<FluentUIThemeConsumer
            render={globalTheme => (
                <div className='resolution-container'>
                    {!isLoaded && 
                        <Loader />
                    }
                    {isLoaded && 
                        <>
                        <LabelElement required={props.resolutionNoteConfig.RequireCategory} label={t('resolution-note.resolution-category')}>
                            <NestedDropdown
                                disabled={isReadOnly || props.isEndUser}
                                tabIndex={0}
                                showClearBtn={true}
                                items={state.resolutionCategory.map(item => ({
                                    id: item.Id,
                                    parentId: item.ParentId,
                                    position: item.Position,
                                    value: item.Value,
                                    ...item
                                }))}
                                dataItem={{
                                    id: resolutionState?.ResolutionCategory?.Id,
                                    parentId: resolutionState?.ResolutionCategory?.ParentId,
                                    position: resolutionState?.ResolutionCategory?.Position,
                                    value: resolutionState?.ResolutionCategory?.Value,
                                    ...resolutionState?.ResolutionCategory
                                }}
                                defaultValue={resolutionState?.ResolutionCategory?.Value}
                                onChange={value => {
                                    let _clone = clone(state.resolutionCategory.find(x=>x.Id==value?.id));
                                    dispatch({ action: actionEnums.UpdateResolution, property:"ResolutionCategoryId", data: _clone?.Id ?? null});
                                    dispatch({ action: actionEnums.UpdateResolution, property:"ResolutionCategory", data: _clone ?? null});
                                }}
                            />
                        </LabelElement>
                        <LabelElement required={props.resolutionNoteConfig.RequireNote} className='sub-container' label={t('resolution-note.resolution-note')}>
                            <SlateEditor
                                disabled={isReadOnly || props.isEndUser}
                                onChange={(data)=>{
                                    let mk = slateToMd(data);
                                    dispatch({ action: actionEnums.UpdateResolution, property:"Note", data: mk});
                                }}
                                slateValue={markdownToSlate(resolutionState?.Note)}
                                isFocus={true}
                                noImage={true}
                                minHeight='10rem'
                                className={globalTheme.siteVariables.theme===themeNames.Dark ? 'slate-resolution-note-dark' : 'slate-resolution-note'}
                            />
                        </LabelElement>
                        {!props.isEndUser && 
                            <>
                                <LabelElement required={props.resolutionNoteConfig.RequireInternalNote} className='sub-container' label={t('resolution-note.resolution-note-internal')}>
                                    <SlateEditor
                                        disabled={isReadOnly}
                                        onChange={(data)=>{
                                            let mk = slateToMd(data);
                                            dispatch({ action: actionEnums.UpdateResolution, property:"InternalNote", data: mk});
                                        }}
                                        slateValue={markdownToSlate(resolutionState?.InternalNote)}
                                        isFocus={true}
                                        noImage={true}
                                        minHeight='10rem'
                                        className={globalTheme.siteVariables.theme===themeNames.Dark ? 'slate-resolution-note-dark' : 'slate-resolution-note'}
                                    />
                                </LabelElement>
                                <FormCheckbox
                                    disabled={props.disableDraftKB || isReadOnly || props.ticketData?.Resolution?.AnswerId!=null}
                                    hidden={!props.resolutionNoteConfig.ShowDraftKB || !isResolutionRole}
                                    label={props.isBulkEdit ? t('resolution-note.draft-kb-bulk-edit') : t('resolution-note.draft-kb')}
                                    checked={resolutionState.DraftKB}
                                    onChange={(_, checked) => {
                                        dispatch({ action: actionEnums.UpdateResolution, property:"DraftKB", data: checked.checked});
                                    }}
                                />
                            </>
                        }
                        
                        </>
                    }
                    
                </div>
            )} 
        />);
}