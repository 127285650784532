import React, { useEffect, useState } from 'react';
import { Button, Flex, ProviderConsumer as FluentUIThemeConsumer } from "@fluentui/react-northstar";
import { useTranslation } from 'react-i18next';
import { appState } from '../../../AppState';
import { platformService } from '../../services/platform.service';
import { clone } from "lodash";
import { IResolution, AddOrUpdateResolutionToDraftKB } from './ResolutionHelper';
import { ADMINISTRATORS, ANALYSTS, KNOWLEDGEAGENT } from '../../utils/constants';
import { ResolutionDetails } from './ResolutionDetails';
import { themeNames } from '@fluentui/react-teams';

interface EditResolutionDetails{
    resolutionProp: IResolution;
    dispatch?: any;
}

export const ResolutionNote = (props: EditResolutionDetails) => {
    const state = appState();
    const api = new platformService();
    const { t } = useTranslation();
    const currentState = appState();

    const [isResolutionRole, setIsResolutionRole] = useState(false);
    const [isResetResolution, setIsResetResolution] = useState(false);
    const [enableSaveBtn, setEnableSaveBtn] = useState(false);
    const [updatedResolution, setUpdatedResolution] = useState<ResolutionDetail>();
    const [isResolutionDetailsLoaded,setIsResolutionDetailsLoaded] = useState(false);
    

    props.resolutionProp.ticketData.ResolutionCategory = clone(state.resolutionCategory.find(x=>x.Id==props.resolutionProp.ticketData?.ResolutionCategoryId));

    useEffect(()=>{
        setIsResolutionDetailsLoaded(false);
        setIsResolutionRole(currentState.userRoles.roles.some(x=>[ADMINISTRATORS,ANALYSTS,KNOWLEDGEAGENT].includes(x)));
    },[]);

    useEffect(()=>{
        if (isResetResolution) setIsResetResolution(false);
    },[isResetResolution]);


    const save = async () => {
        props.resolutionProp.onToolbarInteraction({ event: 'saveTicket' });
        setEnableSaveBtn(false);
        
        if (isResolutionRole && updatedResolution?.DraftKB) {
            let kbId = await AddOrUpdateResolutionToDraftKB(updatedResolution, props.resolutionProp.ticketData, currentState, t);
            updatedResolution.AnswerId=kbId;
        }

        if (updatedResolution.hasOwnProperty('ResolutionCategory')) {
            delete updatedResolution["ResolutionCategory"];
        }

        await api.updateTicketResolutionNote(props.resolutionProp.ticketData.ResolutionId, updatedResolution);
        props.dispatch({type:'set-resolution', data: updatedResolution});
    }

    const reset = () => {
        setIsResetResolution(true);
    }


    return (<FluentUIThemeConsumer
            render={globalTheme => (
                <div className='resolution-note-activity' style={{background : globalTheme.siteVariables.theme===themeNames.Dark ? "var(--mgt-theme-background)" : "white"}}>
                    <ResolutionDetails
                        isEndUser={props.resolutionProp.isEndUser}
                        ticketData={props.resolutionProp.ticketData}
                        resolutionNoteConfig={props.resolutionProp.resolutionNoteConfig}
                        reset={isResetResolution}
                        resetEvent={async()=>{
                            setIsResetResolution(false);
                        }}
                        setEnableSaveBtn={setEnableSaveBtn}
                        setUpdatedResolution={setUpdatedResolution}
                        loaded={async ()=>{
                            setIsResolutionDetailsLoaded(true);
                        }}
                     />
                    {isResolutionDetailsLoaded &&
                        <Flex gap="gap.small">
                            <Flex.Item push>
                                <Button
                                disabled={!enableSaveBtn}
                                content={t('ticket-details.apply-template.dialog.button.cancel')}
                                onClick={()=>{reset()}}
                                />
                            </Flex.Item>
                            <Flex.Item>
                                <Button
                                disabled={!enableSaveBtn}
                                content={'Save'}
                                primary
                                onClick={async () => { await save(); }}
                                />
                            </Flex.Item>
                        </Flex>
                    }
                    
                </div>
            )} 
        />);
}