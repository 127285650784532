import React, { useEffect, useState } from 'react';
import Styles from './OperatorField.module.css';

import { Dropdown, DropdownProps, FormLabel, ProviderConsumer } from '@fluentui/react-northstar';
import { AutomationEvaluationTypes, AUTOMATION_OPERATORS } from '../../../../../../shared/utils/constants';
import { useTranslation } from 'react-i18next';

interface Props {
  propertyValue: string;
  operatorValue: string;
  updateOperator: (operator: string) => void;
  whenData: AutomationWhenData;
  disabled:boolean;
  label:string;
  evaluationType:string;
}

export const OperatorField = ({
  propertyValue,
  operatorValue,
  updateOperator,
  whenData,
  disabled,
  evaluationType,
  label
}: Props) => {
  const [automationOperatorList, setAutomationOperatorList] = useState<
    DropdownDataModel[]
  >([]);
  const { t } = useTranslation();

  const setAutomationOperatorData = (propertyValue: string) => {
    if (propertyValue.toLowerCase().includes('comment')) {
      setAutomationOperatorList(AUTOMATION_OPERATORS['comment']);
    } else if (propertyValue.toLowerCase().includes('date')) {
      setAutomationOperatorList(AUTOMATION_OPERATORS['date']);
    } 
    else
    {
      switch(propertyValue)
      {
        case 'title':
        case 'activephase':
        case 'ticketlifecyclephase.name':
        case 'phases.name':
        case 'note':
        case 'internalnote':
          setAutomationOperatorList(AUTOMATION_OPERATORS['stringNoEmpty']);
          break;
        case 'additionaldetails':
          setAutomationOperatorList(AUTOMATION_OPERATORS['request']);
          break;
        case 'tags':
          setAutomationOperatorList(AUTOMATION_OPERATORS['tag']);
          break;
        case 'approvers':
          setAutomationOperatorList(AUTOMATION_OPERATORS['approver']);
          break;
        case 'taskcollaborators':
        case 'ticketcollaborators':
        case 'affectedusers':
          setAutomationOperatorList(AUTOMATION_OPERATORS['AUC']);
          break;
          case 'affecteduserscount':
          case 'collaboratorscount':
            setAutomationOperatorList(AUTOMATION_OPERATORS['AUCCount']);
            break;
        case "status":
        case "approvalstate":
        case 'isrequiredbyall':
        case 'servicelevelagreement':
        case 'draftkb':
          setAutomationOperatorList(AUTOMATION_OPERATORS['equal_notequal']);
          break;
        case 'relatedtickets':
          setAutomationOperatorList(AUTOMATION_OPERATORS['relatedTickets']);
          break;
        case 'template':
          let operatorType = '';
          switch(evaluationType)
          {
            case AutomationEvaluationTypes.None:
              operatorType = 'templateFull';
              break;
            case AutomationEvaluationTypes.Applied:
              operatorType = 'templateChanged';
              break;
            default:
              operatorType = 'templateChangedNoneBy';
              break;
          }
          setAutomationOperatorList(AUTOMATION_OPERATORS[operatorType]);
          break;
        default:
          setAutomationOperatorList(AUTOMATION_OPERATORS['basic']);
          break;
      }
    }
    
  };

  useEffect(() => {
    setAutomationOperatorData(propertyValue);
  }, [propertyValue,evaluationType]);

  const automationOperatorChange = (e, p: DropdownProps) => {
    const data = p.value as DropdownDataModel;
    updateOperator(data.value);
  };

  return (
    <ProviderConsumer render={globalTheme =>
      <div className={Styles.GroupInput}>
        <FormLabel content={t('automation.automation-operators.title')} styles={{ fontSize: '12px' }} />
        <Dropdown
          disabled={disabled}
          inverted
          fluid
          items={automationOperatorList}
          placeholder={t('automation.automation-operators.placeholder')}
          style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
          value={automationOperatorList.filter(x => x.value == operatorValue)}
          onChange={automationOperatorChange}
        />
      </div>
    } />
  );
};
