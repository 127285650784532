import { toast } from 'react-toastify';
import { AppStateValue } from '../../../../AppState';
import { PlatformUser } from '../../../../shared/interfaces/platformuser.interface';
import { platformService } from '../../../../shared/services/platform.service';
import {
  DASHBOARD_TABLE_COLUMNS,
  FILTERS,
  SELECTED_COLUMNS,
  STORAGE_CONSTANTS,
} from '../../../../shared/utils/constants';
import { getOrderedColumnsFromViewColumns } from '../../../../shared/utils/helper';
import { DEFAULT_SORT_ORDER } from '../../CustomViewContextProvider';
import { clearFilterStates } from '../../../../shared/components/Filter';
import i18next from 'i18next';

const api = new platformService();

interface AddNewViewParamsI {
  selectedRequesters: PeoplePickerUserType[];
  customViewStates: CustomViewStatesType;
  setCustomViewStates: React.Dispatch<React.SetStateAction<CustomViewStatesType>>;
  defaultFilters: FilterPropsItems[];
  setAppState: React.Dispatch<React.SetStateAction<AppStateValue>>;
  setInvalidRequesterMsg: React.Dispatch<React.SetStateAction<string>>;
  setInvalidAffectedUserMsg: React.Dispatch<React.SetStateAction<string>>;
  history: any;
  location: any;
  selectedAffectedUsers: PeoplePickerUserType[];

}
interface IDeleteCustomViewParams {
  customViewStates: CustomViewStatesType;
  setCustomViewStates: React.Dispatch<React.SetStateAction<CustomViewStatesType>>;
  setAppState: React.Dispatch<React.SetStateAction<AppStateValue>>;
}
interface IEditCustomViewParams {
  selectedRequesters: PeoplePickerUserType[];
  customViewStates: CustomViewStatesType;
  setCustomViewStates: React.Dispatch<React.SetStateAction<CustomViewStatesType>>;
  setAppState: React.Dispatch<React.SetStateAction<AppStateValue>>;
  defaultFilters: FilterPropsItems[];
  setInvalidRequesterMsg: React.Dispatch<React.SetStateAction<string>>;
  setInvalidAffectedUserMsg: React.Dispatch<React.SetStateAction<string>>;
  selectedAffectedUsers: PeoplePickerUserType[];
}

interface ISetSelectedCustomView {
  defaultFilters: FilterPropsItems[];
  customView: CreateCustomView;
  setCustomViewStates: React.Dispatch<React.SetStateAction<CustomViewStatesType>>;
  history: any;
  location: any;
  isAddedNewView?: boolean;
  sortOrderUponRefresh?: ISortOrder;
}

export interface IRequesterPayload {
  GivenName: string;
  FamilyName: string;
  Email: string;
  UserName: string;
  AadObjectId: string;
  Id: number;
}
 export interface IPlatformUserPayload {
  GivenName: string;
  FamilyName: string;
  Email: string;
  UserName: string;
  AadObjectId: string;
  Id: number;
}

export interface IAddOrUpdateCustomViewResponse {
  CustomViewId: number;
  CdmUsers: PlatformUser[];
}

const prepareCustomPopupCreatePayload = (
  name: string,
  filters: FilterPropsItems[],
  columns: configureColumeType
): CreateCustomView => ({
  Name: name,
  ViewFilters: filters
    .filter((childFilter) => (childFilter.values.some((value) => value.selected) || childFilter.stringValue!=null) && childFilter.id != FILTERS.REQUESTERS.id && childFilter.id != FILTERS.AffectedUsers.id)
    .map((filter) => ({
      FilterKey: filter.id,
      FilterValues: filter.values
        .filter((value) => value.selected)
        .map((item) => item.key)
        .join(),
        DateValue: filter.values
        .filter((value) => value.selected && value.date)
        .map((item) => item.date)
        .join(),
        StringValue: filter.stringValue,
    })),
  ViewColumns: columns.columnsKeyOrder.map((orderedKey, index) => {
    return {
      ColumnKey: Number(orderedKey),
      Order: index + 1,
    };
  }),
  OrderBy: columns.sortOrder?.orderBy || DEFAULT_SORT_ORDER?.orderBy,
  OrderType: columns.sortOrder?.orderType || DEFAULT_SORT_ORDER?.orderType,
});

export const getCustomViewDropdownFilters = (
  deepCopiedDefaultFilters: FilterPropsItems[],
  ViewFilters: ViewFilter[]
) => {

  const requesterViewFilter = ViewFilters.find(
    (ViewFilter) => ViewFilter.FilterKey === FILTERS.REQUESTERS.id
  );
  const modifiedDateViewFilter = ViewFilters.find(
    (ViewFilter) => ViewFilter.FilterKey === FILTERS.MODIFIED_DATE.id
  );

  const createdDateViewFilter = ViewFilters.find(
    (ViewFilter) => ViewFilter.FilterKey === FILTERS.CREATED_DATE.id
  );
  //modified date processing
  const dateValuesFromCopiedDefaultFilters = deepCopiedDefaultFilters.find(filter => filter.id === FILTERS.MODIFIED_DATE.id);
  const dateListId = deepCopiedDefaultFilters.findIndex(filter => filter.id === FILTERS.MODIFIED_DATE.id);
  // deleting default modified date values, that exist on child filters
  if (dateListId !== -1) {
    deepCopiedDefaultFilters.splice(dateListId, 1)
  }

  //re-mapping the list to include the date from view filters object to selected filters
  if (modifiedDateViewFilter || dateValuesFromCopiedDefaultFilters) {
    if (modifiedDateViewFilter){
      const viewFilterModifiedDate = ViewFilters.find(filter => filter.FilterKey === FILTERS.MODIFIED_DATE.id);
      const newValues = dateValuesFromCopiedDefaultFilters.values.map((item) => {
        if (item.key == parseInt(viewFilterModifiedDate?.FilterValues)) {
          item.date = viewFilterModifiedDate?.DateValue;
          item.selected = true;
        }
        return item;
      });
  
      deepCopiedDefaultFilters.push({
        id: FILTERS.MODIFIED_DATE.id,
        title: FILTERS.MODIFIED_DATE.title,
        key: 'ModifiedDate',
        sortOrder:FILTERS.MODIFIED_DATE.sortOrder,
        values: [...newValues],
      });
    }
    else {
      deepCopiedDefaultFilters.push({
        id: FILTERS.MODIFIED_DATE.id,
        title: FILTERS.MODIFIED_DATE.title,
        key: 'ModifiedDate',
        sortOrder:FILTERS.MODIFIED_DATE.sortOrder,
        values: [...dateValuesFromCopiedDefaultFilters.values],
      });
    }
  }

  //created date processing
  
  const createdDateValuesFromCopiedDefaultFilters = deepCopiedDefaultFilters.find(filter => filter.id === FILTERS.CREATED_DATE.id);
  const createdDateListId = deepCopiedDefaultFilters.findIndex(filter => filter.id === FILTERS.CREATED_DATE.id)
  // deleting default modified date values, that exist on child filters
  if (createdDateListId !== -1) {
    deepCopiedDefaultFilters.splice(createdDateListId, 1)
  }

  //re-mapping the list to include the date from view filters object to selected filters
  if (createdDateViewFilter || createdDateValuesFromCopiedDefaultFilters) {
    if (createdDateViewFilter) {
      const viewFilterCreatedDate = ViewFilters.find(filter => filter.FilterKey === FILTERS.CREATED_DATE.id);
      const newValues = createdDateValuesFromCopiedDefaultFilters.values.map((item) => {
        if (item.key == parseInt(viewFilterCreatedDate?.FilterValues)) {
          item.date = viewFilterCreatedDate?.DateValue;
          item.selected = true;
        }
        return item;
      });

      deepCopiedDefaultFilters.push({
        id: FILTERS.CREATED_DATE.id,
        title: FILTERS.CREATED_DATE.title,
        sortOrder:FILTERS.CREATED_DATE.sortOrder,
        key: 'CreatedDate',
        values: [...newValues],
      });
    }
    else {
      deepCopiedDefaultFilters.push({
        id: FILTERS.CREATED_DATE.id,
        title: FILTERS.CREATED_DATE.title,
        sortOrder:FILTERS.CREATED_DATE.sortOrder,
        key: 'CreatedDate',
        values: [...createdDateValuesFromCopiedDefaultFilters.values],
      });
    }
  }

  ViewFilters.filter(filter => filter.StringValue)
  .forEach(item=>{

    let _filter = deepCopiedDefaultFilters.find(i=>i.id==item.FilterKey);
    _filter.stringValue=item.StringValue;
  });

  const affectedUserViewFilter = ViewFilters.find(
    (ViewFilter) => ViewFilter.FilterKey === FILTERS.AffectedUsers.id
  );

  // deleting default requests(users), that exist on child filters
  const usersListId = deepCopiedDefaultFilters.findIndex(filter => filter.id === FILTERS.REQUESTERS.id)
  if (usersListId !== -1) {
    deepCopiedDefaultFilters.splice(usersListId, 1)
  }

  if (requesterViewFilter) {
    const values = requesterViewFilter.FilterValues.split(',').map((title) => ({ key: parseInt(title,10), title, selected: true }));

    deepCopiedDefaultFilters.push({
      id: FILTERS.REQUESTERS.id,
      title: FILTERS.REQUESTERS.title,
      sortOrder:FILTERS.REQUESTERS.sortOrder,
      key: 'Requester',
      values: [...values],
    });
  }

  // deleting default affectedusers(cdmusers), that exist on child filters
  const affectedUserListId = deepCopiedDefaultFilters.findIndex(filter => filter.id === FILTERS.AffectedUsers.id)
  if (affectedUserListId !== -1) {
    deepCopiedDefaultFilters.splice(affectedUserListId, 1)
  }
  if (affectedUserViewFilter) {
    const values = affectedUserViewFilter.FilterValues.split(',').map(
      (title) => ({ key: parseInt(title,10), title, selected: true }));

    deepCopiedDefaultFilters.push({
      id: FILTERS.AffectedUsers.id,
      title: FILTERS.AffectedUsers.title,
      sortOrder:FILTERS.AffectedUsers.sortOrder,
      key: FILTERS.AffectedUsers.title,
      values: [...values],
    });
  }

  const selectedFilters = deepCopiedDefaultFilters.reduce((acc, filter) => {
    const matchedViewFilter = ViewFilters.find((viewFilter) => viewFilter.FilterKey === filter.id);

    let filterValues = [] as FilterAccordionValues[];
    if (matchedViewFilter) {
      const { FilterValues } = matchedViewFilter;
      filterValues = filter.values.reduce((acc, value) => {
        if (FilterValues.split(',').includes(value.key.toString())) {
          return [...acc, { ...value, selected: true }];
        }
        return [...acc, value];
      }, []);
    }

    acc = !matchedViewFilter ? [...acc, filter] : [...acc, { ...filter, values: [...filterValues] }];
    return acc;
  }, [] as FilterPropsItems[]);

  return selectedFilters;
};

const getSelectedColumns = (ViewColumns: ViewColumn[]) => {
  const selectedColumns = ViewColumns.reduce((acc, selectedColumn) => {
    const label = Object.keys(DASHBOARD_TABLE_COLUMNS).find(
      (key) => DASHBOARD_TABLE_COLUMNS[key][0] === selectedColumn.ColumnKey
    );
    acc = [
      ...acc,
      {
        id: selectedColumn.ColumnKey,
        label: label,
      },
    ];
    return acc;
  }, [] as FEViewColumn[]);

  const sortedSelectedColumns = [...selectedColumns].sort((a, b) => a.id - b.id);
  return sortedSelectedColumns;
};

export const getColumnsSelectedValues = (ViewColumns: ViewColumn[]): configureColumeType => {
  const selectedColumns = getSelectedColumns(ViewColumns);
  const orderedColumns = getOrderedColumnsFromViewColumns(ViewColumns);
  return {
    columnsKeyOrder: orderedColumns,
    selectedColumns,
  };
};

interface IAddUrlSearchParams {
  history: any;
  location: any;
  customViewId?: string;
  defaultViewId?: string;
}
export const addUrlSearchParams = ({
  history,
  location,
  customViewId,
  defaultViewId
}: IAddUrlSearchParams) => {
  const params = new URLSearchParams({ id: customViewId });
  history.replace({
    pathname: location.pathname,
    search: customViewId ? params.toString() : '',
  });
  setViewIdsInStorage({ customViewId, defaultViewId })
};

export const setSelectedCustomView = ({
  defaultFilters,
  customView,
  setCustomViewStates,
  history,
  location,
  isAddedNewView,
  sortOrderUponRefresh
}: ISetSelectedCustomView) => {
  const FiltersDropdownValues = getCustomViewDropdownFilters(JSON.parse(JSON.stringify(defaultFilters)), customView.ViewFilters);
  const ColumnsSelectedValues = getColumnsSelectedValues(customView.ViewColumns);
  addUrlSearchParams({ history, location, customViewId: customView.Id + '' });
  const { Id, Name, ViewFilters, ViewColumns, OrderBy, OrderType } = customView;
  const sortOrder = OrderBy && OrderType ? { orderBy: OrderBy, orderType: OrderType } : DEFAULT_SORT_ORDER;

  setCustomViewStates((prev): CustomViewStatesType => ({
    ...prev,
    view: 'edit',
    columns: {
      columnsKeyOrder: [],
      selectedColumns: [],
      sortOrder: DEFAULT_SORT_ORDER
    },
    configurableColumnsKeyOrder: [],
    configureColumnsApplied: {
      prevSortOrder: sortOrderUponRefresh || sortOrder,
      sortOrder: sortOrderUponRefresh || sortOrder
    },
    selectedCustomView: {
      Id,
      Name,
      ViewFilters,
      ViewColumns,
      FiltersDropdownValues,
      ColumnsSelectedValues,
      OrderBy: OrderBy || DEFAULT_SORT_ORDER?.orderBy,
      OrderType: OrderType || DEFAULT_SORT_ORDER?.orderType,
    },
    ...(isAddedNewView && {
      showCustomViewModal: false,
      viewName: '',
      filters: JSON.parse(JSON.stringify(defaultFilters)),
      customViews: [...prev.customViews, { ...customView }],
    })
  }));
};

export const getViewOnAddNewView = (
  selectedCustomView: SelectedCustomViewType,
  isSaveView: boolean,
): ViewType => {
  if (selectedCustomView) {
    return 'edit'
  } else if (isSaveView) {
    return 'save'
  } else {
    return 'none'
  }
};
export const addNewView = async ({
  selectedRequesters,
  customViewStates,
  setCustomViewStates,
  defaultFilters,
  setAppState,
  setInvalidRequesterMsg,
  setInvalidAffectedUserMsg,
  history,
  location,
  selectedAffectedUsers
}: AddNewViewParamsI) => {
  const payload = prepareCustomPopupCreatePayload(
    customViewStates.viewName,
    customViewStates.filters,
    customViewStates.columns
  );

  let requesters = [] as IRequesterPayload[];
  if (selectedRequesters.length > 0) {
    if (inValidRequesters(selectedRequesters, setInvalidRequesterMsg)) {
      return;
    }
    
    requesters = await setRequesters(selectedRequesters, setAppState);
    // push new requesters values
    payload.ViewFilters.push(setRequesterViewFilter(requesters));
  }
  let affectedUsers = [] as IPlatformUserPayload[];
  if (selectedAffectedUsers.length > 0) {
    if (inValidAffectedUsers(selectedAffectedUsers, setInvalidAffectedUserMsg)) {
      return;
    }
    affectedUsers = [...(await setAffectedUsers(selectedAffectedUsers, setAppState))];
    // push new requesters values
    payload.ViewFilters.push(setAffectedUserViewFilter(affectedUsers));
  }

  const body = {
    CustomView: payload,
    SelectedRequesters: [...requesters, ...affectedUsers],
  };

  const result = (await api.addOrUpdateCustomView(body)) as IAddOrUpdateCustomViewResponse;

  if (!result) {
    toast.error(i18next.t('ticket.custom-view.error-creating'));
    return;
  }

  toast.success(i18next.t('ticket.custom-view.create-success', { viewName: customViewStates.viewName }));

  payload.Id = result.CustomViewId;

  setSelectedCustomView({
    defaultFilters,
    customView: payload,
    history,
    location,
    setCustomViewStates,
    isAddedNewView: true,
  });
  setConfiguredColumnsKeyOrderInStorage();
  setSortOrderInStorage();
  setChildFiltersInStorage(defaultFilters);

  setAppState((prev) => ({
    ...prev,
    listFilter: Math.random(), // call get paginated tickets
  }));
  clearFilterStates(setAppState, defaultFilters);

};

export const deleteCustomView = async ({
  customViewStates,
  setCustomViewStates,
  setAppState,
}: IDeleteCustomViewParams) => {
  const viewName = customViewStates.selectedCustomView?.Name;

  await api.deleteCustomView(customViewStates.selectedCustomView?.Id).catch((err) => {
    return toast.error(i18next.t('ticket.custom-view.error-delete'));
  });

  const configurableColumnsKeyOrder = [];
  setCustomViewStates((prev): CustomViewStatesType => ({
    ...prev,
    view: 'none',
    customViews: [
      ...prev.customViews.filter(
        (customView) => customView.Id !== prev.selectedCustomView?.Id
      ),
    ],
    configurableColumnsKeyOrder,
    selectedCustomView: undefined,
    configureColumnsApplied: {
      prevSortOrder: DEFAULT_SORT_ORDER,
      sortOrder: DEFAULT_SORT_ORDER
    }
  }));
  setSortOrderInStorage()
  setConfiguredColumnsKeyOrderInStorage();
  setChildFiltersInStorage();

  setAppState((prev) => ({
    ...prev,
    listFilter: 0,
  }));

  toast.success(i18next.t('ticket.custom-view.delete-success', { viewName: viewName }));
};

export const editCustomViewFunc = async ({
  selectedRequesters,
  customViewStates,
  setCustomViewStates,
  setAppState,
  defaultFilters,
  setInvalidRequesterMsg,
  setInvalidAffectedUserMsg,
  selectedAffectedUsers
}: IEditCustomViewParams) => {
  const { filters } = customViewStates;

  // Find requester index from filters
  const filtersRequesterIdx = filters.findIndex(
    (filter) => filter.id === FILTERS.REQUESTERS.id
  );
  // delete requester entry, if it already exists in filters
  if (filtersRequesterIdx !== -1) {
    filters.splice(filtersRequesterIdx, 1);
  }

  // Find affected user index from filters
  const filtersAffectedUserIdx = filters.findIndex(
    (filter) => filter.id === FILTERS.AffectedUsers.id
  );
  // delete affected user entry, if it already exists in filters
  if (filtersAffectedUserIdx !== -1) {
    filters.splice(filtersAffectedUserIdx, 1);
  }

  const payload = prepareCustomPopupCreatePayload(
    customViewStates.viewName,
    filters, // customViewStates.filters
    customViewStates.columns
  );

  let requesters = [] as IRequesterPayload[];
  // if any requester is selected, add new one to filters
  if (selectedRequesters.length > 0) {
    if (inValidRequesters(selectedRequesters, setInvalidRequesterMsg)) {
      return;
    }

    requesters = await setRequesters(selectedRequesters, setAppState);

    // push new requesters values
    payload.ViewFilters.push(setRequesterViewFilter(requesters));

    const lastIdx = payload.ViewFilters.length - 1;
    const requesterViewFilter = payload.ViewFilters[lastIdx]; // will get last value of array
    const values = requesterViewFilter.FilterValues.split(',').map((title) => ({ key: parseInt(title, 10), title, selected: true }));

    // push new requesters values
    filters.push({
      id: FILTERS.REQUESTERS.id,
      title: FILTERS.REQUESTERS.title,
      sortOrder:FILTERS.REQUESTERS.sortOrder,
      key: 'Requester',
      values: [...values],
    });
  }
  /* REQUESTER FUNCTIONALITY ENDS HERE */

  /* Affected User Functionality Start */
  let affectedUsers = [] as IPlatformUserPayload[];
  // if any affected user is selected, add new one to filters
  if (selectedAffectedUsers.length > 0) {
    if (inValidAffectedUsers(selectedAffectedUsers, setInvalidAffectedUserMsg)) {
      return;
    }

    affectedUsers = [...(await setAffectedUsers(selectedAffectedUsers, setAppState))];

    // push new affected users values
    payload.ViewFilters.push(setAffectedUserViewFilter(affectedUsers));

    const lastIdx = payload.ViewFilters.length - 1;
    const requesterViewFilter = payload.ViewFilters[lastIdx]; // will get last value of array
    const values = requesterViewFilter.FilterValues.split(',').map(
      (title) => ({ key: parseInt(title, 10), title, selected: true })
    );

    // push new affected users values
    filters.push({
      id: FILTERS.AffectedUsers.id,
      title: FILTERS.AffectedUsers.title,
      sortOrder:FILTERS.AffectedUsers.sortOrder,
      key: FILTERS.AffectedUsers.title,
      values: [...values],
    });
  }
  /* Affected User Functionality End */

  payload.Id = customViewStates.selectedCustomView?.Id;
  const body = {
    CustomView: payload,
    SelectedRequesters: [...requesters, ...affectedUsers]
  };

  const result = await api.addOrUpdateCustomView(body) as IAddOrUpdateCustomViewResponse

  if (!result) {
    toast.error(i18next.t('ticket.custom-view.error-update'));
    return;
  }

  const sortOrder = payload.OrderBy && payload.OrderType ? {
    orderBy: payload.OrderBy,
    orderType: payload.OrderType,
  } : DEFAULT_SORT_ORDER;

  // update list of custom view. update edit custom view in state
  setCustomViewStates((prev): CustomViewStatesType => {

    return {
      ...prev,
      showCustomViewModal: false,
      view: 'edit',
      viewName: '',
      filters: JSON.parse(JSON.stringify(defaultFilters)),
      columns: {
        columnsKeyOrder: [],
        selectedColumns: [],
        sortOrder: DEFAULT_SORT_ORDER
      },
      configureColumnsApplied: {
        prevSortOrder: sortOrder,
        sortOrder: sortOrder,
      },
      selectedCustomView: {
        ...prev.selectedCustomView,
        Name: prev.viewName,
        ColumnsSelectedValues: { ...prev.columns },
        FiltersDropdownValues: [...customViewStates.filters],
        ViewColumns: [...payload.ViewColumns],
        ViewFilters: [...payload.ViewFilters],
        OrderBy: payload.OrderBy,
        OrderType: payload.OrderType,
      },
      customViews: [
        ...prev.customViews.map((customView) =>
          customView.Id === prev.selectedCustomView?.Id
            ? {
              ...customView,
              Name: customViewStates.viewName,
              ViewFilters: [...payload.ViewFilters],
              ViewColumns: [...payload.ViewColumns],
              FiltersDropdownValues: [...customViewStates.filters],
              OrderBy: payload.OrderBy,
              OrderType: payload.OrderType,
            }
            : customView
        ),
      ],
    };
  });

  // update user state here.
  setAppState((prev) => ({
    ...prev,
    listFilter: Math.random(),
    users: [...prev.platformusers, ...result.CdmUsers],
  }));

  const viewName = customViewStates.selectedCustomView?.Name;
  toast.success(i18next.t('ticket.custom-view.edit-success', { viewName: viewName }));
};

function setRequesterViewFilter(requesters: IRequesterPayload[]) {
  return {
    FilterKey: FILTERS.REQUESTERS.id,
    FilterValues: requesters.map((requester) => requester.Id).join(','),
  };
}

function setAffectedUserViewFilter(affectedUsers: IPlatformUserPayload[]) {
  return {
    FilterKey: FILTERS.AffectedUsers.id,
    FilterValues: affectedUsers
      .map((affectedUser) => affectedUser.Id)
      .join(','),
  };
}

const setRequesters = async (selectedRequesters: PeoplePickerUserType[], setAppState) => {
  let requesters = [] as IRequesterPayload[];
  for (const req of selectedRequesters) {
    const user = await api.getOrCreatePlatformUser({ 
      GivenName: req.givenName,
      FamilyName: req.surname,
      Email: req.mail ?? req.userPrincipalName,
      UserName: req.userPrincipalName,
      AadObjectId: req.id
    });

    // Update user state to ensure new users get added
    setAppState(prev => {
      const pus = prev.platformusers;
      const matched = pus.find(x => x.Id === user.Id);
      if (!matched)
        pus.push(user);

      return { ...prev, platofmusers: pus };
    });

    requesters.push(user);
  }

  return requesters;
}

const setAffectedUsers = async(selectedAffectedUsers: PeoplePickerUserType[], setAppState) => {
  let affectedUsers = [] as IPlatformUserPayload[];
  
  for (const affectedUser of selectedAffectedUsers) {
    const user = await api.getOrCreatePlatformUser({ 
      GivenName: affectedUser.givenName,
      FamilyName: affectedUser.surname,
      Email: affectedUser.mail ?? affectedUser.userPrincipalName,
      UserName: affectedUser.userPrincipalName
    });

    // Update user state to ensure new users get added
    setAppState(prev => {
      const pus = prev.platformusers;
      const matched = pus.find(x => x.Id === user.Id);
      if (!matched)
        pus.push(user);

      return { ...prev, platofmusers: pus };
    });
    
    affectedUsers.push(user);
  };


  return affectedUsers;
}

export function inValidRequesters(
  selectedRequesters: PeoplePickerUserType[],
  setInvalidRequesterMsg: React.Dispatch<React.SetStateAction<string>>
) {
  const invalidRequester = selectedRequesters.find((requester) => !requester.userPrincipalName);
  if (invalidRequester) {
    setInvalidRequesterMsg(`"${invalidRequester.displayName}" is invalid requester. Please unselect it first`)
    return true;
  }
  setInvalidRequesterMsg('')
  return false;
}

export function inValidAffectedUsers(
  selectedAffectedUsers: PeoplePickerUserType[],
  setInvalidAffectedUserMsg: React.Dispatch<React.SetStateAction<string>>
) {
  const invalidRequester = selectedAffectedUsers.find((requester) => !requester.userPrincipalName);
  if (invalidRequester) {
    setInvalidAffectedUserMsg(`"${invalidRequester.displayName}" is invalid affected user. Please unselect it first`)
    return true;
  }
  setInvalidAffectedUserMsg('')
  return false;
}

export const selectedColumnsFromConfigurableColumnsKeyOrder = (
  configurableColumnsKeyOrder: (string | number)[]
) => {
  return SELECTED_COLUMNS.filter((column) =>
    configurableColumnsKeyOrder.includes(column.id)
  );
};

export const setlocalStorageItem = (key: string, value: any) => {
  value ? localStorage.setItem(key, JSON.stringify(value)) : localStorage.removeItem(key);
}
export const getLocalStorageItem = (key: string) => {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : null;
}

export const setSortOrderInStorage = (sortOrder?: ISortOrder) => {
  setlocalStorageItem(STORAGE_CONSTANTS.SORT_ORDER, sortOrder);
};

export const getSortOrderFromStorage = (): ISortOrder => {
  return getLocalStorageItem(STORAGE_CONSTANTS.SORT_ORDER);
}

export const setConfiguredColumnsKeyOrderInStorage = (
  columnsKeyOrder?: columnsKeyOrderType
) => {
  setlocalStorageItem(STORAGE_CONSTANTS.CONFIGURED_COLUMNS, columnsKeyOrder);
};
export const getConfiguredColumnsKeyOrderFromStorage = (): columnsKeyOrderType | [] => {
  return getLocalStorageItem(STORAGE_CONSTANTS.CONFIGURED_COLUMNS) || [];
}

export const checkIfDefaultFilter = (num: number | undefined) => {
  if (num === undefined) return num;
  return num % 1 === 0;
}

export const setViewIdsInStorage = ({
  customViewId = '',
  defaultViewId = '',
}: {
  customViewId?: number | string;
  defaultViewId?: number | string;
}) => {
  localStorage.setItem(
    STORAGE_CONSTANTS.VIEW_ID.CUSTOM_VIEW_ID,
    customViewId + ''
  );
  localStorage.setItem(
    STORAGE_CONSTANTS.VIEW_ID.DEFAULT_VIEW_ID,
    defaultViewId + ''
  );
};

export const getViewIdFromStorage = (view: 'custom' | 'default') => {
  let id: string;
  if (view === 'custom') {
    id = localStorage.getItem(STORAGE_CONSTANTS.VIEW_ID.CUSTOM_VIEW_ID);
  } else if (view === 'default') {
    id = localStorage.getItem(STORAGE_CONSTANTS.VIEW_ID.DEFAULT_VIEW_ID);
  }

  return id ? Number(id) : undefined;
};

export const setChildFiltersInStorage = (
  childFilters?: FilterPropsItems[],
  noOfSelectedFilters?: number,
  filterInputValue?: string,
) => {
  setlocalStorageItem(STORAGE_CONSTANTS.CHILD_FILTER.DROPDOWN_VALUES, childFilters);
  setlocalStorageItem(STORAGE_CONSTANTS.CHILD_FILTER.DROPDOWN_COUNT, noOfSelectedFilters);
  setlocalStorageItem(STORAGE_CONSTANTS.CHILD_FILTER.DROPDOWN_INPUT_VALUE, filterInputValue);
};
export const getChildFiltersFromStorage = (): FilterPropsItems[] | undefined => {
  const childFilters = getLocalStorageItem(STORAGE_CONSTANTS.CHILD_FILTER.DROPDOWN_VALUES) as (FilterPropsItems[] | null);
  return childFilters || undefined;
};
export const getChildFiltersCountFromStorage = (): number => {
  const childFiltersCount = getLocalStorageItem(STORAGE_CONSTANTS.CHILD_FILTER.DROPDOWN_COUNT);
  return childFiltersCount || 0;
};
export const getChildFiltersInputValueFromStorage = (): string => {
  const inputValue = getLocalStorageItem(STORAGE_CONSTANTS.CHILD_FILTER.DROPDOWN_INPUT_VALUE);
  return inputValue || '';
};

export const isChildFilterSelectedExceptQueryValue = (
  filterDropdownCount: number,
  queryValue: string
) => {
  return !!(
    filterDropdownCount > 1 ||
    (filterDropdownCount === 1 && !queryValue)
  ); // queryValue is not available in custom_view filters but available in child_filters
};
// Check If ConfigureColumns Or ChildFilters are Selected
export const isSaveView = (
  isDefaultViewId: boolean,
  configurableColumnsKeyOrder: (string | number)[],
  filterDropdownCount: number,
  queryValue: string
): boolean => {
  return !!(
    isDefaultViewId &&
    (configurableColumnsKeyOrder.length ||
      isChildFilterSelectedExceptQueryValue(filterDropdownCount, queryValue))
  );
};
