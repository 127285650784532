import {
    Popup,
    Input,
    Text,
    ChevronDownIcon,
    Dropdown,
    ProviderConsumer as FluentUIThemeConsumer,
    Button,
    Label,
    Datepicker,
    Flex,
    pxToRem
} from '@fluentui/react-northstar';
import update from 'immutability-helper';
import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import AppCSS from '../../App.module.css';
import FilterCSS from './Filter.module.css';
import MgtCss from '../../mgt.module.css';
import './Filter.css';
import ToolbarCSS from '../../tikit/toolbar/Toolbar.module.css';
import {
  ConvertDateToLocale,
    IsDateFuture,
    IsDateLate,
    IsDateNextWeek,
    IsDateThisWeek,
    IsDateToday,
    IsDateTomorrow
} from '../common/ConvertDate';
import {
  appState,
  AppStateContext,
  AppStateValue,
  FilterDropdownProps,
  useSetState
} from '../../AppState';
import { Ticket } from '../interfaces/ticket.interface';
import { PlatformUser } from '../interfaces/platformuser.interface';
import { TableFilterOptions, getStatusIdByGuid } from '../../tikit/ticketHelper';
import { FilterDataType, FILTERS, LICENSE_FEATURE, STATUS_GUID, TICKET_PAGINATION } from '../utils/constants';
import { CheckFeature } from './CheckFeature';
import { ThemeColorScheme } from '../common/TeamsTheme';
import { NestedFilter } from './NestedFilter/NestedFilter';
import { ListContext } from '../../tikit/List/ListContextProvider';
import { setChildFiltersInStorage } from '../../tikit/toolbar/CustomViewPopup/heplers/customPopupHelper';
import { PeoplePicker, PersonType, UserType } from '@microsoft/mgt-react';
import { useTranslation } from 'react-i18next';
import { platformService } from '../services/platform.service';
import { DateTime } from 'luxon';
import { DatepickerLocalizations } from '../../App';

export interface FilterPropsItems {
  id: number;
  title: string;
  key: string;
  values: FilterAccordionValues[];
  stringValue?: string;
  FilterDataType?: 'boolean' | 'string' | 'any';
  sortOrder:number;
}

interface FilterProps {
items: FilterPropsItems[];
open?: boolean;
onChange?: (interaction: any) => void;
page?: string;
defaultFilters?: FilterPropsItems[];
}

export interface FilterAccordionValues {
  key: number;
  title: string;
  selected: boolean;
  position?: number | null;
  parentId?: number | null;
  aadObjectId?: string | null;
  upn?: string | null;
  date?: string | null;
}

export interface FilterAccordionProps {
id: number | string;
title: string;
values: FilterAccordionValues[];
onInteraction?: (interaction: any) => void;
selected?: FilterPropsItems[];
isCollapsed: boolean;
stringValue?: string;
FilterDataType?: 'boolean' | 'string' | 'any';
}

interface TreeItemProps {
id: string;
value: string;
onSelectCallback: () => void;
isSelected?: boolean;
paddingLeft: string;
}

export const FilterAccordion = (props: FilterAccordionProps) => {
  const api = new platformService();
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [selected, setSelected] = useState<FilterAccordionValues[]>([]);
  const [filterId, setFilterId] = useState(null);
  const [searchDropdowns, setSearchDropdowns] = useState({ [FILTERS.ASSIGNEE.title]: '', [FILTERS.REQUESTERS.title]: '', [FILTERS.AffectedUsers.title]: '', [FILTERS.TicketCollaborators.title]: '' });
  const isMounted = useRef({ [FILTERS.ASSIGNEE.title]: false, [FILTERS.REQUESTERS.title]: false, [FILTERS.AffectedUsers.title]: false, [FILTERS.TicketCollaborators.title]: false });
  const searchFieldSelected = useRef({ [FILTERS.ASSIGNEE.title]: false, [FILTERS.REQUESTERS.title]: false, [FILTERS.AffectedUsers.title]: false, [FILTERS.TicketCollaborators.title]: false, });
  const { t } = useTranslation();
  const currentState = appState();

const isSearchFieldActive = [FILTERS.ASSIGNEE.title, FILTERS.REQUESTERS.title, FILTERS.AffectedUsers.title, FILTERS.TicketCollaborators.title].includes(props.title);
  const isModifiedDate = [FILTERS.MODIFIED_DATE.title].includes(props.title);
  const isCreatedDate = [FILTERS.CREATED_DATE.title].includes(props.title);
  let createdDate = props?.selected?.find(filter => filter.id === FILTERS.CREATED_DATE.id)?.values?.find(value => value.selected)?.date;
  let modifiedDate = props?.selected?.find(filter => filter.id === FILTERS.MODIFIED_DATE.id)?.values?.find(value => value.selected)?.date;
  const [selectedModifiedDate, setSelectedModifiedDate] = useState(modifiedDate ? new Date(modifiedDate) : null);
  const [selectedCreatedDate, setSelectedCreatedDate] = useState(createdDate ? new Date(createdDate) : null);
  const isRequesterField = [FILTERS.REQUESTERS.title].includes(props.title);
  const isAffectedUsersField = [FILTERS.AffectedUsers.title].includes(props.title);

  function getSelectedFilterValues() {
    if (isSearchFieldActive) {
      return props.selected.find(filter => filter.id === props.id)?.values
    }
    return selected;
  }

// getSelectedFilterValues
const pushSelected = async (id: number) => {
  const selectedFilterValues = getSelectedFilterValues();

  const findFilterValue = selectedFilterValues.findIndex(obj => obj.key === id);
  let getCurrentValues = selectedFilterValues;
  if (findFilterValue >= 0) {
    let updateValues = update(getCurrentValues[findFilterValue], {
      selected: { $set: !getCurrentValues[findFilterValue].selected }
    });
    getCurrentValues = update(getCurrentValues, {
      $splice: [[findFilterValue, 1, updateValues]]
    });
  } else {
    const { data } = await api.getPlatformUser(id);
    getCurrentValues = update(getCurrentValues, { $push: [{ key: data.Id, title: data.FullName, aadObjectId: data.AadObjectId, upn: data.UserName, selected: true }] });
  }

  if (isSearchFieldActive) {
    const currentDropdownValues = getSearchedValues(getCurrentValues, searchDropdowns[props.title]);
    setSelected(currentDropdownValues);
  } else {
    setSelected(getCurrentValues);
  }

  if (props.onInteraction) {
    props.onInteraction({
      event: 'setFilter',
      data: { id: filterId, values: getCurrentValues }
    });
  }
};

const countSelected = () => {
  const selectedFilterValues = getSelectedFilterValues();
  return selectedFilterValues.filter(s => s.selected).length;
};

const setAccordionStateCookie = (isCollapsed: boolean) => {
  if (props.onInteraction) {
    props.onInteraction({
      event: 'setAccordion',
      data: { id: props.id, isCollapsed: isCollapsed }
    });
  }
};

const onChangeSearchDropdown = function(e: any) {
  const value: string = e.target.value;
  setSearchDropdowns({ ...searchDropdowns, [props.title]: value });
  let findDefaultSelectedValues = props.selected.find((s) => s.id === props.id);
  setFilterId(findDefaultSelectedValues.id);
  const dropdownValues = getSearchedValues(findDefaultSelectedValues.values, value);
  setSelected(dropdownValues);
}

useEffect(() => {
  let findDefaultSelectedValues = props.selected.find(s => s.id === props.id);
  if (isRequesterField) {
    const picker: any = document.getElementById('mgt-filter-requesters');
    if (picker) 
      picker.selectedPeople = findDefaultSelectedValues.values.filter(s => s.selected === true).map(item => ({ displayName: item.title, id: item.aadObjectId, userPrincipalName: item.upn }));
  }

  if (isAffectedUsersField) {
    const picker: any = document.getElementById('mgt-filter-affectedusers');
    if (picker) 
      picker.selectedPeople = findDefaultSelectedValues.values.filter(s => s.selected === true).map(item => ({ displayName: item.title, id: item.aadObjectId, userPrincipalName: item.upn }));
  }
}, []);

useEffect(() => {
  setIsHidden(props.isCollapsed);
  let findDefaultSelectedValues = props.selected.find(s => s.id === props.id);
  setFilterId(findDefaultSelectedValues.id);
  if (findDefaultSelectedValues) {
    if (!isSearchFieldActive) {
      setSelected(findDefaultSelectedValues.values);
    }
    if (isSearchFieldActive) {
      if (!isMounted.current[findDefaultSelectedValues.title]) {
        const dropdownValues = getSearchedValues(findDefaultSelectedValues.values, '');
        setSelected(dropdownValues);
        isMounted.current[findDefaultSelectedValues.title] = true;
      }
      if (searchFieldSelected.current[findDefaultSelectedValues.title]) {
        const dropdownValues = getSearchedValues(findDefaultSelectedValues.values, '');
        setSelected(dropdownValues);
        searchFieldSelected.current[findDefaultSelectedValues.title] = false;
      }
      if (!searchFieldSelected.current[findDefaultSelectedValues.title] && countSelected() == 0) {
        const dropdownValues = getSearchedValues(findDefaultSelectedValues.values, searchDropdowns[FILTERS.ASSIGNEE.title]);
        setSelected(dropdownValues);
      }

      if (props.id === 10) {
        let selectedRequesters = findDefaultSelectedValues.values.filter(s => s.selected === true).map(item => ({
          displayName: item.title,
          id: item.aadObjectId,
          userPrincipalName: item.upn
        }));
        setselectedRequester(selectedRequesters)
      }
      if (props.id === 14) {
        let selectedAffectedUsers = findDefaultSelectedValues.values.filter(s => s.selected === true).map(item => ({
          displayName: item.title,
          id: item.aadObjectId,
          userPrincipalName: item.upn
        }));
        setselectedAffectedUser(selectedAffectedUsers)
      }
    }
  }
}, [props.selected, props.isCollapsed]);

const [selectedRequester, setselectedRequester] = useState<any[]>([]);
const [selectedAffectedUser, setselectedAffectedUser] = useState<any[]>([]);
const setRequesterFilter = (e: any) => {
  //reset selected values
  let items = selected.filter((r: FilterAccordionValues) => r.selected === true);
  items.forEach(el => { pushSelected(el.key); });

  // Clear Out Selected
  if (e.detail.length === 0)
    items.forEach(el => { pushSelected(el.key); });
  else if (e.detail.length < items.length) {
    items.forEach(el => {
      if (el.selected) {
        let item = e.detail.find((r: any) => r.displayName == el.title || (el.aadObjectId && r.id == el.aadObjectId) || r.userPrincipalName == el.upn);
        if (!item)
          pushSelected(el.key);
      }
    });
  } else {
    e.detail.forEach(async el => {
      let item = selected.find((r: FilterAccordionValues) => r.title == el.displayName || (r.aadObjectId && r.aadObjectId == el.id) || r.upn == el.userPrincipalName);
      
      // If we cannot find the user, we need to add it to add the key correctly.
      if (!item) {
        const user = currentState.platformusers.find(x => x.UserName == el.userPrincipalName || x.AadObjectId == el.id) ??
                     (await api.getOrCreatePlatformUser({ Email: el.scoredEmailAddresses[0]?.address, GivenName: el.givenName, FamilyName: el.familyName, UserName: el.userPrincipalName, AadObjectId: el.id }));
        pushSelected(user.Id);
      } else if (item.selected != true) 
        pushSelected(item.key);
    });
  }
};

  const customFormatter = date => {
    return ConvertDateToLocale(date, DateTime.DATE_SHORT);
  };
  
  const limitSelectionOnModifiedDateToOneOperator = (item: any) => {
    var modifiedDateOperatorIsAlreadySelected = props?.selected?.find(x => x.id == FILTERS.MODIFIED_DATE.id).values?.find(x => x.selected);
    if (modifiedDateOperatorIsAlreadySelected == null || modifiedDateOperatorIsAlreadySelected == undefined) {
      setSelectedModifiedDate(null);
      pushSelected(item.key);
      return;
    }
    else {
      //unsetting previously selected value and attaching selected date with newly selected operator
      let updatedList = selected.map(listValue => {
        if (listValue.selected && listValue.key == modifiedDateOperatorIsAlreadySelected.key && listValue.title == modifiedDateOperatorIsAlreadySelected.title) {
          listValue.selected = false;
          listValue.date = null;
        }
        if (item.key == listValue.key && item.key != modifiedDateOperatorIsAlreadySelected.key){
          listValue.date = selectedModifiedDate?.toString();
        }
        return listValue;
      });

      updatedList = JSON.parse(JSON.stringify(updatedList))
      setSelected(updatedList);
      
      //pushing the newly selected value
      if (item.key != modifiedDateOperatorIsAlreadySelected?.key) {
          pushSelected(item.key);
      }
      else {
        setSelectedModifiedDate(null);
      }
      return;
    }
  }

  const limitSelectionOnCreatedDateToOneOperator = (item: any) => {
    var createdDateOperatorIsAlreadySelected = props?.selected?.find(x => x.id == FILTERS.CREATED_DATE.id).values?.find(x => x.selected);
    if (createdDateOperatorIsAlreadySelected == null || createdDateOperatorIsAlreadySelected == undefined) {
      setSelectedCreatedDate(null);
      pushSelected(item.key);
      return;
    }
    else {
      //unsetting previously selected value and attaching selected date with newly selected operator
      let updatedList = selected.map(listValue => {
        if (listValue.selected && listValue.key == createdDateOperatorIsAlreadySelected.key && listValue.title == createdDateOperatorIsAlreadySelected.title) {
          listValue.selected = false;
          listValue.date = null;
        }
        if (item.key == listValue.key && item.key != createdDateOperatorIsAlreadySelected.key){
          listValue.date = selectedCreatedDate?.toString();
        }
        return listValue;
      });

      updatedList = JSON.parse(JSON.stringify(updatedList))
      setSelected(updatedList);
      
      //pushing the newly selected value
      if (item.key != createdDateOperatorIsAlreadySelected?.key) {
          pushSelected(item.key);
      }
      else {
        setSelectedCreatedDate(null);
      }
      return;
    }
  }

  const onDateChange = (p: any, setDate: React.Dispatch<React.SetStateAction<Date>>) => {
    let selectedDate = p.value === null ? null : p.value.toISOString();
    let selectedOperator = selected?.find(val => val.selected);
    if (selectedOperator) {
      let updatedList = selected.map(listValue => {
        if (listValue.selected) {
          listValue.date = selectedDate;
        }
        return listValue;
      });
      
      updatedList = JSON.parse(JSON.stringify(updatedList))
      setSelected(updatedList);
      if (props.onInteraction) {
        props.onInteraction({
          event: 'setFilter',
          data: { id: filterId, values: updatedList }
        });
      }
    }

    setDate(new Date(selectedDate));
  };

  const getAfterNameDisplay = () => {
    switch(props?.FilterDataType)
    {
      case FilterDataType.Boolean:
        let selectedBool = getSelectedFilterValues().find(i=>i.selected);
        return selectedBool ? '(1)' : "(0)";
      case FilterDataType.String:
        return `(${props?.stringValue ? "1" : "0"})`;
      default:
        return `(${countSelected()})`;
    }
  }

  return (
    <FluentUIThemeConsumer
      render={globalTheme => (
          <li key={props.id} style={{ borderTop: `2px solid ${globalTheme.siteVariables.colorScheme.default.background}`, ...ThemeColorScheme(globalTheme.siteVariables) }} >
            <div
              onClick={() => {
                if (isSearchFieldActive) {
                  searchFieldSelected.current[props.title] = true;
                }
                setAccordionStateCookie(!isHidden);
                setIsHidden(!isHidden);
              }}
            >
              <div style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background2 }} className={`flex justify-between px-3 py-2`} >
                <Text content={
                  `${props.title} ${getAfterNameDisplay()}`
                  } weight="semibold" />
                <ChevronDownIcon className={`transition ease-linear duration-300 ${isHidden ? 'transform rotate-0' : 'transform rotate-180'}`} />
              </div>
            </div>

          <ul className={`${isHidden && 'hidden'} ${isSearchFieldActive && FilterCSS.scrollList}`} style={{ borderTop: `1px solid ${globalTheme.siteVariables.colorScheme.default.border3}` }}>
            {(isSearchFieldActive && !isRequesterField && !isAffectedUsersField) &&
              <Input
                fluid
                style={{
                  backgroundColor: globalTheme.siteVariables.colorScheme.default.background1,
                  padding: '2px 0px',
                  marginTop: "2px",
                }}
                onChange={onChangeSearchDropdown}
                inverted
                placeholder={t('ticket.ticket-list.configure-column.filter-by-assignee')}
              />
            }
            {isRequesterField ? <PeoplePicker id='mgt-filter-requesters' userType={UserType.user} type={PersonType.person} selectionChanged={setRequesterFilter}  /> :
             isAffectedUsersField ? <PeoplePicker id='mgt-filter-affectedusers' userType={UserType.user} type={PersonType.person} selectionChanged={setRequesterFilter} /> : 
              <>
                {props.FilterDataType == FilterDataType.String &&
                  <Input
                  fluid
                  className={ToolbarCSS.filterSearch}
                  style={{
                    backgroundColor: globalTheme.siteVariables.colorScheme.default.background1,
                    padding: '2px 0px', marginTop: "2px",
                  }}
                  value={props?.stringValue}
                  onChange={(_,e)=>{
                    if (props.onInteraction) {
                      props.onInteraction({
                        event: 'setFilter',
                        data: { id: props.id, stringValue: e.value, values:[] }
                      });
                    }
                  }}
                  inverted
                  placeholder={`${t('ticket.child-filter.title')}...`}
                />
                }
                
                {props.FilterDataType != FilterDataType.String &&
                  <NestedFilter filterItems={selected} onClick={(item: any) => {
                    
                    if (props?.title == FILTERS.MODIFIED_DATE.title) {
                      limitSelectionOnModifiedDateToOneOperator(item);
                    }
                    else if (props?.title == FILTERS.CREATED_DATE.title) {
                      limitSelectionOnCreatedDateToOneOperator(item);
                    }
                    else {

                      if (props?.FilterDataType==FilterDataType.Boolean) {
                        selected.find(i=>i.key != item.key).selected=false;
                      }

                      pushSelected(item.key);
                    }
                  }} />
                }
                
                <div className={`${selected?.find(val => val.selected) ? '' : 'disabledDatePicker'}`}>
                  {isModifiedDate && (<Datepicker
                    {...DatepickerLocalizations}
                    disabled={selected?.find(val => val.selected) ? false : true}
                    firstDayOfWeek={0}
                    formatMonthDayYear={customFormatter}
                    className={`${MgtCss.fluidDate} ${MgtCss.inverted}`}
                    onDateChange={(_, p) => { onDateChange(p, setSelectedModifiedDate) }}
                    selectedDate={selected?.find(val => val.selected) ? selectedModifiedDate : null}
                />)}
                </div>

                <div className={`${selected?.find(val => val.selected) ? '' : 'disabledDatePicker'}`}>
                  {isCreatedDate && (<Datepicker
                    {...DatepickerLocalizations}
                    disabled={selected?.find(val => val.selected) ? false : true}
                    firstDayOfWeek={0}
                    formatMonthDayYear={customFormatter}
                    className={`${MgtCss.fluidDate} ${MgtCss.inverted}`}
                    onDateChange={(_, p) => { onDateChange(p, setSelectedCreatedDate) }}
                    selectedDate={selected?.find(val => val.selected) ? selectedCreatedDate : null}
                />)}
                </div>
              </>
            }
            </ul>
          </li>
        )}
    />
  );
};

export function getSearchedValues(values: FilterAccordionValues[], value: string) {
const selectedValues = [];
const searchedValues = values.filter(
  (selectedValue) => {
    if (selectedValue.selected) {
      selectedValues.push(selectedValue);
      return false;
    }
    return selectedValue.title.toLowerCase().includes(value.toLowerCase());
  }
);
return [...selectedValues, ...searchedValues];
}

interface GetSelectedValuesProps {
filterState: FilterDropdownProps;
filterIndex: number;
}
const getSelectedValues = ({ filterState, filterIndex }: GetSelectedValuesProps) => {
  return filterState.selectedValues.filter(o => o.id === filterIndex )?.[0]?.values?.filter(o => o.selected) ?? []
}
const getKeysOfSelectedFilter = (selectedFilter: FilterAccordionValues[]) => {
  return selectedFilter.map(o => o.key) ?? [];
}

const filterTicketsByLifecycle = (tickets: Ticket[], selectedlifeCycles: FilterAccordionValues[]) => {
  if ((selectedlifeCycles?.length ?? 0) == 0) return tickets;
  const filterLifecycleIds: number[] = getKeysOfSelectedFilter(selectedlifeCycles);
  const filteredTickets = tickets?.filter(ticket =>
    (filterLifecycleIds?.includes(0) && ticket.TicketLifecycle == null) || filterLifecycleIds?.includes(ticket.TicketLifecycle?.LifecycleId)
  );
  return [...(filteredTickets ?? [])];
}

const filterTicketsByTags = (tickets: Ticket[], selectedTags: FilterAccordionValues[]) => {
  if ((selectedTags?.length ?? 0) == 0) return tickets;
  const filterTagIds: number[] = getKeysOfSelectedFilter(selectedTags);
  const filteredTickets = tickets?.filter(ticket => ticket.Tags?.some((tag: any) => filterTagIds.includes(tag.Id)));
  return [...(filteredTickets ?? [])];
}

const getFilteredTicketsBySelectedFilters = (filteredTickets: Ticket[], selectedFilters: [FilterAccordionValues[], string, boolean][]): Ticket[] => {
  selectedFilters.forEach(([selectedFilter, filterKeyid, includeNullValue]) => {
    if (selectedFilter.length <= 0) { return };

      let selectedFilterIds: number[] = getKeysOfSelectedFilter(selectedFilter);
      if (includeNullValue) {
        const filteredticketsBySelectedFilterId = filteredTickets?.filter(
          (ticket) => (selectedFilterIds?.includes(0) && ticket[filterKeyid] === null) || selectedFilterIds?.includes(ticket[filterKeyid])
        );
        filteredTickets = [...(filteredticketsBySelectedFilterId ?? [])];
      } else {
        filteredTickets = [...(filteredTickets?.filter((ticket) => selectedFilterIds?.includes(ticket[filterKeyid])) ?? [])];
      }
  });

  return filteredTickets
};
const filterTicketsByUserList = (filteredTickets: Ticket[], selectedFilters: [FilterAccordionValues[], string][]): Ticket[] => {
  selectedFilters.forEach(([selectedFilter, filterKeyId]) => {
    if (selectedFilter.length <= 0) { return; }
    const selectedFilterIds: number[] = getKeysOfSelectedFilter(selectedFilter);
    const filteredValues = selectedFilterIds.filter(x => x != null);

    filteredTickets = filteredTickets.filter(t => t[filterKeyId].some(x => filteredValues.includes(x.Id)) || (selectedFilterIds.includes(0) && t[filterKeyId].length == 0));
  });

  return filteredTickets;
};

const getFilteredTicketsBySelectedDateFilters = (
filteredTickets: Ticket[], 
selectedFilters: [FilterAccordionValues[], string][]
): Ticket[] => {
selectedFilters.forEach(([selectedFilter, fieldName]) => {
  if (selectedFilter.length > 0) {
    let selectedFilterKeys: number[] = getKeysOfSelectedFilter(selectedFilter);
    filteredTickets = filterByDate(filteredTickets, selectedFilterKeys, fieldName);
  }
});

return filteredTickets;
}

const getFilteredTicketsByDateValue = (filteredTickets: Ticket[], selectedFilters: [FilterAccordionValues[], string][]): Ticket[] => {
  selectedFilters.forEach(([selectedFilter, fieldName]) => {
    if (selectedFilter.length > 0) {
      filteredTickets = filterByDateValue(filteredTickets, selectedFilter, fieldName);
    }
  });
 return filteredTickets;
}

export const customizedFilter = (tickets: Ticket[], filterState: FilterDropdownProps, filtersApplyCount: number) => {
  let filteredTickets: Ticket[] = tickets;

  if (filterState?.selectedValues && filterState.selectedValues?.length > 0 && filtersApplyCount) {
    let [selectedDueDate, selectedStatus, selectedPriorities, selectedCategories, 
      selectedAssignee, selectedTeam, selectedGroup, selectedTicketTypes, 
      selectedTags, selectedResolution, selectedRequester, selectedlifeCycles, selectedCreatedDate, selectedModifiedDate, selectedAffectedUser, selectedCollaborator] 
      = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(filterIndex => getSelectedValues({ filterState, filterIndex }));

    if (filterState.queryValue !== '') {
      filteredTickets = tickets?.filter(t => t.Title?.toLowerCase()?.includes(filterState.queryValue?.toLowerCase()) || t.Title?.toLowerCase()?.includes('temp_ticket')) || [];
    }

    filteredTickets = getFilteredTicketsBySelectedDateFilters(filteredTickets, [
      [selectedDueDate, 'DueDate'],
      [selectedResolution, 'ResolutionDate'],
    ])

    filteredTickets = getFilteredTicketsByDateValue(filteredTickets, [
      [selectedCreatedDate, 'CreatedDate'],
      [selectedModifiedDate, 'ModifiedDate']
    ])

    filteredTickets = getFilteredTicketsBySelectedFilters(filteredTickets, [
      [selectedStatus, 'StatusId', false], // false means, not contain null filter
      [selectedPriorities, 'PriorityId', false],
      [selectedCategories, 'CategoryId', false],
      [selectedTicketTypes, 'TicketTypeId', false],
      [selectedAssignee, 'AssigneeId', true], // true means, contain null filter
      [selectedGroup, 'SupportGroupId', true],
      [selectedTeam, 'TeamId', true],
      [selectedRequester, 'RequesterId', true]
    ]);

    filteredTickets = filterTicketsByTags(filteredTickets, selectedTags);
    filteredTickets = filterTicketsByLifecycle(filteredTickets, selectedlifeCycles);
    filteredTickets = filterTicketsByUserList(filteredTickets, [
      [selectedAffectedUser, 'AffectedUsers'],
      [selectedCollaborator, 'TicketCollaborators']
    ]);
  }

  return filteredTickets;
};

const filterByDate = (tickets: Ticket[], selectedValues: number[], field: string) => {
return [...(tickets?.filter((ticket: Ticket) =>
  (selectedValues?.includes(0) && IsDateLate(ticket[field])) ||
  (selectedValues?.includes(1) && IsDateToday(ticket[field])) ||
  (selectedValues?.includes(2) && IsDateTomorrow(ticket[field])) ||
  (selectedValues?.includes(3) && IsDateThisWeek(ticket[field])) ||
  (selectedValues?.includes(4) && IsDateNextWeek(ticket[field])) ||
  (selectedValues?.includes(5) && IsDateFuture(ticket[field])) ||
  (selectedValues?.includes(6) && ticket[field] === null)
) ?? [])];
}

const extractDate = (date: string) => {
  return new Date(new Date(date).toDateString())
}

const filterByDateValue = (tickets: Ticket[], selectedFilter: FilterAccordionValues[], field: string) => {
  const date = selectedFilter.length > 0 ? selectedFilter[0].date : null;
  const operator = selectedFilter.length > 0 ? selectedFilter[0].key : null;
  
  if (date == null) return [];
  switch (operator) {
    case 0:
        return [...(tickets?.filter((ticket: Ticket) => extractDate(ticket[field]) < extractDate(date)) ?? [])];
    case 1:
        return [...(tickets?.filter((ticket: Ticket) => extractDate(ticket[field]) <= extractDate(date)) ?? [])];
    case 2:
        return [...(tickets?.filter((ticket: Ticket) => extractDate(ticket[field]) > extractDate(date)) ?? [])];
    case 3:
        return [...(tickets?.filter((ticket: Ticket) => extractDate(ticket[field]) >= extractDate(date)) ?? [])];
  }
};

const paginatedTickets = (currentPage, ticketCountRef, filteredTickets, setTicketCount) => {
  if (ticketCountRef.current !== filteredTickets?.length) {
    setTicketCount(filteredTickets?.length);
  }
  ticketCountRef.current = filteredTickets?.length;

  const ITEM_PER_PAGE = TICKET_PAGINATION.ITEM_PER_PAGE;
  return filteredTickets.slice(currentPage * ITEM_PER_PAGE - ITEM_PER_PAGE, ITEM_PER_PAGE * currentPage);
};

export const filterListTickets = (state: AppStateValue, currentPage: number, setTicketCount: React.Dispatch<React.SetStateAction<number>>, ticketCountRef: React.MutableRefObject<number>) => {
  let currentUserId = state.platformusers.find((user: PlatformUser) => {
    return (user.UserName?.toLowerCase() === state.currentUserEmail?.toLowerCase());
  })?.Id;

  let tickets: Ticket[], notClosedTickets = state.tickets;

  if (state.listFilter === TableFilterOptions.AllActive) {
    let filteredTickets = notClosedTickets;
    tickets = paginatedTickets(currentPage, ticketCountRef, filteredTickets, setTicketCount);
  } else if (state.listFilter === TableFilterOptions.MyWork) {
    let filteredTickets = notClosedTickets.filter((ticket: Ticket) => ticket.AssigneeId === currentUserId || ticket?.TicketCollaborators?.some((collaborator: any) => collaborator.Id === currentUserId));
    tickets = paginatedTickets(currentPage, ticketCountRef, filteredTickets, setTicketCount);
  } else if (state.listFilter === TableFilterOptions.Unassigned) {
    let filteredTickets = notClosedTickets.filter((ticket: Ticket) => ticket.AssigneeId === null);
    tickets = paginatedTickets(currentPage, ticketCountRef, filteredTickets, setTicketCount);
  } else if (state.listFilter === TableFilterOptions.Closed) {
    const filteredTickets = state.closedTickets.filter((ticket: Ticket) => ticket.StatusId === getStatusIdByGuid(state.ticketStatus, STATUS_GUID.CLOSED));
    tickets = paginatedTickets(currentPage, ticketCountRef, filteredTickets, setTicketCount);
  } else if (state.listFilter === TableFilterOptions.Deflected) {
    const filteredTickets = state.closedTickets.filter((ticket: Ticket) => ticket.StatusId === getStatusIdByGuid(state.ticketStatus, STATUS_GUID.DEFLECTED));
    tickets = paginatedTickets(currentPage, ticketCountRef, filteredTickets, setTicketCount);
  }
  return customizedFilter(tickets, state.filterDropdown, state.filtersApplyCount);
};

export const Filter = (props: FilterProps) => {
  props.page = props.page ?? 'table';
  const setState = useSetState();
  const filterState = appState();
  const { listStates } = useContext(ListContext);

  const isMounted = useRef<boolean>();
  const {t} = useTranslation();

  const getFilterCount = () => {
    let count = 0;
    filterState.filterDropdown.selectedValues.forEach((sv) => {
      if (sv.FilterDataType==FilterDataType.String){
        count = count + (sv.stringValue ? 1 : 0);
      }
      else {
        count = count + sv.values.filter((subfilter) => subfilter.selected).length;
      }
    });
    return !filterState.filterDropdown.queryValue ? count : count + 1;
  };

const actions = (action) => {
  if (action.event === 'setFilter') {
    const findFilterValue =
      filterState.filterDropdown.selectedValues.findIndex(
        (obj) => obj.id === action.data.id
      );
    let getCurrentValues = filterState.filterDropdown.selectedValues;
    if (findFilterValue >= 0) {
      let current = getCurrentValues[findFilterValue];
      let updateValues:FilterPropsItems;
      
      if (current?.FilterDataType==FilterDataType.String){
        updateValues = update(current, {
          values: { $set: action.data.values },
          stringValue: { $set: action.data.stringValue }
        });
      }
      else{
        updateValues = update(current, {
          values: { $set: action.data.values },
        });
      }
      
        let newData = update(getCurrentValues, {
          $splice: [[findFilterValue, 1, updateValues]],
        });
        getCurrentValues = newData;
      }

          setState(state => {
              return {
                  ...state,
                  filterDropdown: {
                      ...state.filterDropdown,
                      selectedValues: getCurrentValues
                  },
                  filterDropdownCount: getFilterCount()
              };
          });

          if (props.onChange) {
              props.onChange({
                  event: 'getData',
                  data: { filterValues: getCurrentValues }
              });
          }
      } else if (action.event === 'setAccordion') {
          let booleanToString = action.data.isCollapsed ? 'collapsed' : 'not';
          sessionStorage.setItem(`accordion-${action.data.id}`, booleanToString);
          let tempCollapseList = filterState.filterDropdown.collapseList;
          tempCollapseList[action.data.id] = action.data.isCollapsed;
          setState(state => {
              return {
                  ...state,
                  filterDropdown: {
                      ...state.filterDropdown,
                      collapseList: tempCollapseList,
                      filterDropdownCount: getFilterCount()
                  }
              };
          });
      }
  };

const queryChange = (query: string) => {
  if (query !== '') {
    setState((state) => {
      return {
        ...state,
        filterDropdown: {
          ...state.filterDropdown,
          isQueryEmpty: false,
        },
        filterDropdownCount: getFilterCount(),
      };
    });
  } else {
    setState((state) => {
      return {
        ...state,
        filterDropdown: {
          ...state.filterDropdown,
          isQueryEmpty: true,
        },
        filterDropdownCount: getFilterCount(),
      };
    });
  }
  if (props.onChange) {
    props.onChange({ event: 'queryChange', data: { query: query } });
  }
};

  const clearSelected = useCallback(() => {
      setState(state => {
          return {
              ...state,
              filterDropdown: {
                  ...state.filterDropdown,
                  isQueryEmpty: true,
                  queryValue: ''
              },
              filterDropdownCount: 0
          };
      });
      if (props.onChange) {
          props.onChange({ event: 'clearData' });
          setState(state => {
              return {
                  ...state,
                  filterDropdown: {
                      ...state.filterDropdown,
                      selectedValues: props.items
                  },
                  filterDropdownCount: getFilterCount()
              };
          });
      }

    applyFilters(props.defaultFilters, true);

    let picker: any = document.getElementById('mgt-filter-requesters');
    if (picker) picker.selectedPeople = [];
    picker = document.getElementById('mgt-filter-affectedusers');
    if (picker) picker.selectedPeople = [];
  }, [setState, props.onChange, props.items, getFilterCount]);

  useEffect(() => {
  if (isMounted.current) clearSelected();
  isMounted.current = true;
}, []);

useEffect(() => {
      setState((state) => {
          return {
              ...state,
              filterDropdown: {
                  ...state.filterDropdown,
                  selectedValues: props.items,
              },
              filterDropdownCount: getFilterCount(),
          };
      });
      let collapseOptions = [];
      props.items.forEach((item) => {
          let accordionCookie = sessionStorage.getItem(`accordion-${item.id}`);

          if (accordionCookie) {
              if (accordionCookie === `collapsed`) {
                  collapseOptions.push(true);
              } else {
                  collapseOptions.push(false);
              }
          } else {
              sessionStorage.setItem(`accordion-${item.id}`, `collapsed`);
              collapseOptions.push(true);
          }
      });

      setState(state => {
          return {
              ...state,
              filterDropdown: {
                  ...state.filterDropdown,
                  collapseList: collapseOptions
              },
              filterDropdownCount: getFilterCount()
          };
      });
  }, [props.items]);

  const applyFilters = (dropdownValues: FilterPropsItems[], isClearSelected?: boolean) => {
    setState((state) => ({
      ...state,
      filtersApplyCount: state.filtersApplyCount + 1,
      filterDropdown: {
        ...state.filterDropdown,
        queryValueApplied: state.filterDropdown?.queryValue
      }
    }));

    const filtersCount = isClearSelected ? 0 : getFilterCount();
    const filterInputValue = isClearSelected ? '' : filterState.filterDropdown?.queryValue;
    setChildFiltersInStorage(
      dropdownValues,
      filtersCount,
      filterInputValue
    );
  };

  const getLicenseFeatureName = (i: number) => {
    let featureName = LICENSE_FEATURE.ValidSubscription

    if(i === 5) featureName = LICENSE_FEATURE.MultiDepartment 
    else if (i === 7) featureName = LICENSE_FEATURE.TicketTypes
    else if (i === 8) featureName = LICENSE_FEATURE.TicketTags
    else if (i === 11) featureName = LICENSE_FEATURE.TicketLifecycle

    return featureName
  }
  const checkIfDateExists = (id: number) => (filterState.filterDropdown.selectedValues.find(x => x.id === id)?.values.find(v => v.selected) ?? { date: new Date() }).date ? false : true;
  
return <FluentUIThemeConsumer render={(globalTheme) => (
  <AppStateContext.Consumer>
    {(state) => {
      const filterDropdownValues = state.filterDropdown.selectedValues;
      return (
        <Popup
          trapFocus
          trigger={
            <Dropdown
              items={[]}
              triggerButton={
                <span>{`${t('ticket.child-filter.title')} ${
                  getFilterCount() > 0 ? `(${getFilterCount()})` : ``
                }`}</span>
              }
              fluid
              className={`${AppCSS.dropdownItem} h-12 ${FilterCSS.hideItems} dropdown-btn`}
            />
          }
          position='below'
          align='end'
          onOpenChange={(e, t) => {
            props.onChange({
              event: 'isOpen',
              data: { isOpen: !state.filterDropdown.isOpen },
            });
            setState((_state) => {
              return {
                ..._state,
                filterDropdown: {
                  ..._state.filterDropdown,
                  isOpen: !_state.filterDropdown.isOpen,
                },
                filterDropdownCount: getFilterCount(),
              };
            });
          }}
          open={state.filterDropdown.isOpen}
          content={
            <div className={FilterCSS.filterPopup}>
              <div className={`p-2`}>
                <div className='flex justify-between items-center pb-2'>
                  <Text
                    content={t('ticket.child-filter.title')}
                    weight={'semibold'}
                    size={'large'}
                  />
                  <Text
                    content={t('ticket.child-filter.clear-btn')}
                    color='brand'
                    className={`cursor-pointer`}
                    onClick={clearSelected}
                  />
                </div>
                <Input
                  fluid
                  style={{
                    backgroundColor:
                      globalTheme.siteVariables.colorScheme.default
                        .background2,
                  }}
                  value={state.filterDropdown.queryValue}
                  onChange={(_e, p) => {
                    queryChange(p.value);
                    setState((_state) => {
                      return {
                        ..._state,
                        filterDropdown: {
                          ..._state.filterDropdown,
                          queryValue: p.value,
                        },
                        filterDropdownCount: getFilterCount()
                      };
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter"){
                      applyFilters(filterState.filterDropdown.selectedValues)
                    }
                  }}
                  inverted
                  placeholder={t('ticket.child-filter.filter-request')}
                />
              </div>
              <ul className={FilterCSS.filtersList}>
                {filterDropdownValues.sort((a,b)=> a.sortOrder - b.sortOrder).map((f, i) => {
                  return (
                    <>
                    {f.id!=FILTERS.Title.id &&
                      <CheckFeature featureName={getLicenseFeatureName(i)} key={f.id}>
                        <FilterAccordion
                          {...f}
                          onInteraction={actions}
                          selected={filterDropdownValues}
                          isCollapsed={state.filterDropdown.collapseList[i]}
                        />
                      </CheckFeature>
                    }
                    </>
                )})}
              </ul>
              <div className='flex justify-center'>
                <Button
                  content={t('ticket.child-filter.apply-filters')}
                  primary
                  className='mt-2 mb-2 w-full'
                  onClick={() => applyFilters(filterState.filterDropdown.selectedValues)}
                  disabled={(props.page === 'board' ? false : listStates.isTicketsFetching) || checkIfDateExists(FILTERS.CREATED_DATE.id) || checkIfDateExists(FILTERS.MODIFIED_DATE.id)}
                />
              </div>
              </div>
            }
          />
      );
    }}
  </AppStateContext.Consumer>
 
)} 
/>
};


export const clearFilterStates = (
  setState: React.Dispatch<React.SetStateAction<AppStateValue>>,
  defaultFilters: FilterPropsItems[]
) => {
  setState((prev) => ({
    ...prev,
    filterDropdown: {
      ...prev.filterDropdown,
      isQueryEmpty: true,
      queryValue: '',
      selectedValues: defaultFilters,
    },
    filterDropdownCount: 0,
  }));
};
