import React from 'react';
import { platformService } from '../shared/services/platform.service';
import { useTranslation } from 'react-i18next';
import { EnumList } from '../enum-list/EnumList';
import { CATEGORY } from '../shared/utils/constants';

export const ResolutionCategoryList = () => {
  const {t} = useTranslation();
  const api = new platformService();
  

  const updateCategory = async (param:any) => {
    await api.updateResolutionCategory(param);
  }

  const getList = async(param?:any) => {
    return await api.getResolutionCategories(param ?? `?v=${new Date().getTime()}`);
  }

  const createData = async (param:any) => {
    return await api.createResolutionCategory(param as CategoryDetail);
  }


  return (
    <EnumList
    typeName={'ServiceDesk.Core.Models.Pickers.Ticket.ResolutionCategory'}
    updateData={updateCategory}
    createData={createData}
    getList={getList}
    entityName={"ResolutionCategory"}
    title={t('resolution-note.resolution-category')}
    successMgs={t('category.changes-are-saved')}
    pageTitle={t('category.title')}
    featchingListMsg={CATEGORY.GETTING_ALL_CATEGORIES}
    updateMsg={CATEGORY.UPDATING_CATEGORY}
    userRoleHasAccess={true}
     />
  );
};
