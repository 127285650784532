import React, { createContext, useState, useContext } from 'react';
import { Ticket, Comment } from './shared/interfaces/ticket.interface';
import {
  PlatformUser,
  UserImage,
} from './shared/interfaces/platformuser.interface';
import { TicketProps } from './shared/interfaces/ticketproperties.interface';
import { ISupportGroup, ITeam } from './shared/interfaces/supportGroup.interface';
import { FilterPropsItems } from './shared/components/Filter';
import { LooseObject } from './shared/components/TicketForm';
import { themeNames } from '@fluentui/react-teams';
import { Lifecycle } from './lifecycle/utils/typings';
import { MyWorkListViewType } from './shared/utils/constants';
import { FileProps } from './shared/components/EditTicketAttachments/EditTicketAttachments';
import { FileRejection } from 'react-dropzone';
import { ITeamsConfig } from './config/TeamsConfig';

export interface FilterDropdownProps {
  selectedValues: FilterPropsItems[];
  isOpen: boolean;
  isQueryEmpty: boolean;
  queryValue: string;
  queryValueApplied: string;
  collapseList: any[];
}

export interface KBContext {
  isContextOnly: boolean;
  prompts: any[];
}

export interface KBData {
  answer: string;
  context?: KBContext;
  id: number;
  questions: string[];
  source: string;
  metadata?: any[];
  status: string;
  modifiedById: number;
  createdById: number;
  lastUpdatedDateTime: string;
  agentOnly: boolean;
}
export interface SynonymData {
  alterations: string[];
}
export interface ChatStudioData {
  query: string;
  numberOfResponses: number;
  includeOpenAiResults: boolean;
  threshold: number;
  agentOnly: boolean;
  includeDraft: boolean;
}

export interface LoadingKB {
  message: string;
  isLoading: boolean;
}

export interface ToUpdateKB {
  id: number;
  answer?: string;
  question?: { index: number; content: string };
  removedQuestionsIndex?: number[];
  type: 'answer' | 'question' | 'remove_question';
}

export interface RequestProps {
  isToggleClosed: boolean;
}

export interface AppStateValue {
  theme: themeNames;
  isInTeams: boolean;
  tickets?: Ticket[];
  closedTickets?: Ticket[];
  platformusers?: PlatformUser[];
  boardFilter: number | undefined;
  activeFilter: number | undefined;
  listFilter: number | undefined;
  menu: number;
  ticketStatus?: TicketProps[];
  categories?: TicketProps[];
  ticketTypes?: TicketProps[];
  priority?: TicketProps[];
  source?: TicketProps[];
  supportgroups?: ISupportGroup[];
  teams?: ITeam[];
  defaultTeam?: ITeam;
  defaultSupportGroup?: ISupportGroup | null;
  comments?: Comment[];
  isLoading: boolean;
  isError: boolean;
  errorConfig?: any | undefined;
  listChartFilterDate: number | undefined;
  editTicketId?: number | null;
  usersInRole?: any[];
  userImages?: UserImage;
  isSidenavCollapsed: boolean;
  isSidenavChanged: boolean;
  isSettingsSidenavCollapsed?: boolean;
  currentUserEmail?: string;
  currentUserId?: number;
  filterDropdown?: FilterDropdownProps;
  filterDropdownCount?: number;
  knowledgeBase: KBData[];
  deletedKB: number[];
  editedKB: ToUpdateKB[];
  loadingKB: LoadingKB;
  lastComments?: LooseObject[];
  userRoles: {
    roles: string[];
    isLoading: boolean;
    isError: boolean;
    isUnauthorized: boolean;
  };
  isReload?: boolean;
  request: RequestProps;
  isLoadingSla: boolean;
  tableTicketsList?: Ticket[];
  tableCurrentPage?: number;
  tableTicketTotalCount?: number;
  activeTickets?: Ticket[];
  myWorkTickets?: Ticket[];
  deflectedTickets?: Ticket[];
  unassignedTickets?: Ticket[];
  isAddingTicket: boolean;
  ticketsSla: TicketSla[];
  filtersApplyCount: number;
  disableApplyFilterBtn: boolean;
  isFilterApplied: boolean;
  ticketApprovals?: any[];
  completedTicketApprovals?: any[];
  lifecycleApprovals?: any[];
  taskStatus?: TaskStatus[];
  ticketTask?: TicketTask[];
  hasActiveTasks?: boolean;
  hasPendingApprovals?: boolean;
  lifecycles?: Lifecycle[];
  tags?: any[];
  lifecyclePhaseMenu?: number | undefined;
  lifecycleMenuOpen?: boolean;
  me?: any;
  isRouteFromMyWork: boolean;
  selectedTaskOrApproval?: {
    type: MyWorkListViewType.Approvals | MyWorkListViewType.Tasks;
    id: number;
  };
  isLoadingTemplates?: boolean;
  reloadBoardOnAddTicket?: boolean;
  uploadingState?: UploadingFilesProps;
  teamsConfig?: ITeamsConfig | null;
  lifecycleStatus?: CommonEnum[];
  resolutionCategory?: TicketProps[];
  resolution?:ResolutionDetail;
}

export interface UploadingFilesProps {
  isUploading: boolean;
  hasStashed: boolean;
  acceptedFiles: File[];
  rejectedFiles: FileRejection[];
  filesOnDrive: FileProps[];
  uploadedFiles: FileProps[];
}

export interface AppStateKBValue {
  knowledgeBase: KBData[];
  synonyms: SynonymData[];
  deletedKB: number[];
  editedKB: ToUpdateKB[];
  loadingKB: LoadingKB;
  users: PlatformUser[];
}

export const defaultStateValue: AppStateValue = {
  theme: themeNames.Default,
  isInTeams: false,
  filterDropdown: {
    selectedValues: [],
    isOpen: false,
    isQueryEmpty: true,
    queryValue: '',
    queryValueApplied: '',
    collapseList: [],
  },
  tickets: [],
  closedTickets: [],
  platformusers: [],
  boardFilter: 0,
  activeFilter: 0,
  listFilter: undefined,
  menu: 0,
  ticketStatus: [],
  categories: [],
  ticketTypes: [],
  priority: [],
  supportgroups: [],
  teams: [],
  comments: [],
  isLoading: true,
  isError: false,
  errorConfig: {},
  listChartFilterDate: 0,
  editTicketId: null,
  usersInRole: [],
  isSidenavCollapsed: false,
  isSidenavChanged: false,
  isSettingsSidenavCollapsed: true,
  filterDropdownCount: 0,
  knowledgeBase: [],
  deletedKB: [],
  editedKB: [],
  loadingKB: {
    message: '',
    isLoading: false,
  },
  userRoles: {
    roles: [],
    isLoading: true,
    isError: false,
    isUnauthorized: false
  },
  isReload: true,
  request: {
    isToggleClosed: false,
  },
  isLoadingSla: false,
  tableTicketsList: [],
  tableCurrentPage: 1,
  tableTicketTotalCount: 0,
  activeTickets: [],
  myWorkTickets: [],
  deflectedTickets: [],
  unassignedTickets: [],
  isAddingTicket: false,
  ticketsSla: [],
  tags: [],
  filtersApplyCount: 0,
  disableApplyFilterBtn: false,
  isFilterApplied: false,
  lifecycleMenuOpen: false,
  isRouteFromMyWork: false,
  uploadingState: {
    isUploading: false,
    acceptedFiles: [],
    filesOnDrive: [],
    hasStashed: false,
    rejectedFiles: [],
    uploadedFiles: [],
  },
  teamsConfig: null,
  resolutionCategory:[]
};

export const AppStateContext = createContext(defaultStateValue);

export const AppSetStateContext = createContext<
  React.Dispatch<React.SetStateAction<AppStateValue>> | undefined
>(undefined);

export const useSetState = () => {
  const setState = useContext(AppSetStateContext);
  if (!setState) {
    throw new Error(
      'useSetState was called outside of the AppSetStateContext provider'
    );
  }
  return setState;
};

export const appState = () => {
  const state = useContext(AppStateContext);
  if (!state) {
    throw new Error(
      'useSetState was called outside of the AppStateContext provider'
    );
  }
  return state;
};

interface Props {
  children: any;
}

const AppStateProvider: React.FC<Props> = ({ children }) => {
  const [state, setState] = useState(defaultStateValue);
  return (
    <AppStateContext.Provider value={state}>
      <AppSetStateContext.Provider value={setState}>
        {children}
      </AppSetStateContext.Provider>
    </AppStateContext.Provider>
  );
};

export default AppStateProvider;
